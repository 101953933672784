import { lazy, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import * as api from "../../api";
import "./style.css";
import { Note } from "../../models/Notes/Note";
import moment from "moment";
import { isNullOrUndefined } from "@microsoft/applicationinsights-core-js";
import { woChronologyTabState } from "../../ducks/redux/actions/userChoices";
import { useDispatch, useSelector } from "react-redux";
import YellowAlert from "../YellowAlert";
const Button = lazy(() => import("../../Button/Button"));

interface NotesCardProps extends RouteComponentProps<any> {
  workOrderSerialNumber: string;
  workOrderSequenceNumber: string;
}

const NotesCard: React.FC<NotesCardProps> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModeActive, setIsCreateModeActive] = useState(false);
  const [notesData, setNotesData] = useState<Note[]>([]);
  const [titleMessage, setTitleMessage] = useState("");
  const [dropDown, setDropDown] = useState("none");
  const [addNote, setaddNote] = useState(false);
  const [noteType, setNoteType] = useState("0");
  const [notesDescription, setNotesDescription] = useState("");
  const [disableSubmitNote, setDisableSubmitNote] = useState(true);
  const [isError,setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const dispatch = useDispatch();
  const [showNoteWarning, setShowNoteWarning] = useState("");
  const format = /^(?!.*--)[^><&*!%]*$/;
  const isP360Maintainence = useSelector(
    (state: any) => state.userChoicesReducer?.isP360RepairsMaintainenceValue
  );
  const navigateToNoteDetails = () => {
    dispatch(woChronologyTabState("Notes"));
    props.history.push("/WorkOrderChronology");
  };

  const getTitleMsg = (count: any) => {
    let message = t("Number_of_Notes");
    setTitleMessage(message.replace("{0}", count));
  };

  const dropDownFunction = () => {
    if (dropDown === "none") setDropDown("block");
    else setDropDown("none");
  };

  const showWarningDetails = () => {
    return (
      <div
        data-testid="alert-warning"
        className="error mb-8 sm:mx-1"
        role="alert"
        placeholder="alert-message"
      >
        <div className="w-full px-2 py-2 bg-yellow flex items-center">
          <div className="px-4 pr-8">
            <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
          </div>
          <div className="flex items-center">
            <p
              data-testid="locatioAlert_0"
              className="w-full text-left my-2 font-AvantGardeGothic-Md"
            >
              {" "}
              {t(showError)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const addNoteFunction = () => {
    if(!isP360Maintainence){
    setaddNote(true);
    const getNotesCardRef = document.getElementById('notes-card');
    getNotesCardRef?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
  }
  };

  const changeNoteType = (e: { target: { value: string } }) => {
    const noteType = e.target.value,
      noteTypeInt = parseInt(noteType);
    setDisableSubmitNote(isNullOrUndefined(notesDescription) || notesDescription == "" || isNaN(noteTypeInt) || noteTypeInt < 1 || noteTypeInt > 3);
    setNoteType(noteType);
  };

  const getNotesCreationPayload = () => {
    const notesCreationPayload = {
      noteTypeId: noteType,
      text: notesDescription,
    };
    return notesCreationPayload;
  };

  const onNotesFocusChange = (e: any) => { 
    const trimmedNoteInput = e.target.value.trim(),
      noteTypeInt = parseInt(noteType);

    setNotesDescription(trimmedNoteInput);
    setDisableSubmitNote(isNullOrUndefined(trimmedNoteInput) || trimmedNoteInput == "" || isNaN(noteTypeInt) || noteTypeInt < 1 || noteTypeInt > 3);
    return trimmedNoteInput;
  }

  const onNotesInputChange = (e: any) => {
    const notesInputRaw = e.target.value,
    noteRegexMatch = !isNullOrUndefined(notesInputRaw) && notesInputRaw !== "" ? notesInputRaw.match(/[^><*&!%]+/gm) : null;
    let noteInput = !isNullOrUndefined(noteRegexMatch) ? noteRegexMatch[0] ?? "" : "";
    const noteTypeInt = parseInt(noteType);

    if (format.test(notesInputRaw)) {
      setShowNoteWarning("");
    } else {
      setShowNoteWarning( t("WO_Note_Error"));
      e.target.value = noteInput.replace(/[&*%!<>]/g,'');
    noteInput=e.target.value;

    }
    
    setNotesDescription(noteInput);
    setDisableSubmitNote(isNullOrUndefined(noteInput) || noteInput == "" || isNaN(noteTypeInt) || noteTypeInt < 1 || noteTypeInt > 3)

    return noteInput;
  };

  const notesCreationHandler = async () => {
    try {
      const createdNotesPayload = getNotesCreationPayload();
      const createNote = await api.postNoteForWorkorder(
        props.workOrderSerialNumber,
        props.workOrderSequenceNumber,
        createdNotesPayload
      );
      setaddNote(false);
    } catch(e){
      setaddNote(true);
      setIsError(true);
      setShowError("Notes_Creation_Failure");
      console.error(e);
    }finally{
      setDropDown("none");
      setDisableSubmitNote(true);
      setNoteType("0");
      setNotesDescription("");
    }
  };

  useEffect(()=>{

  },[isError,disableSubmitNote]);

  useEffect(() => {
    if(!isNullOrUndefined(props.workOrderSequenceNumber) && !isNullOrUndefined(props.workOrderSerialNumber)) {
      setIsLoading(true);
      (async () => {
        try {
          const notes = await api.getRepairNotes(
            props.workOrderSerialNumber.concat("/",props.workOrderSequenceNumber)
          );
          setNotesData(notes);
          getTitleMsg(notes?.length);
          setIsLoading(false);
        }catch(e){
          console.error(e);
        }
      })();
    }
  }, [props.workOrderSequenceNumber, props.workOrderSerialNumber, addNote]);

  return (
    <div id="notes-card" className="notes-card">
      <div className="w-full">
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={50}
            timeout={5000}
          />
        ) : (
          <>
            <div className="relative pb-16  py-2 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4 max-height-580 overflow-auto">
              <div className="px-4 py-2 border-b border-grey">
                <div className="flex flex-row d-flex bd-highlight items-center mt-2">
                  <i className="fas fa-sticky-note text-teal text-2xl mr-6 bd-highlight aa"></i>
                  <h2 className="my-0 text-purple-100 bd-highlight">
                    {t("Notes_Card_Title")}
                  </h2>

                  {!addNote &&     <div className="menu-nav ms-auto bd-highlight">
                    <div className="dropdown-container">
                      <div
                        className="three-dots"
                        data-bs-toggle="dropdown"
                        data-testid="dropFunction"
                        onClick={dropDownFunction}
                        onKeyDown={dropDownFunction}
                      ></div>
                      <div
                        className="dropdown px-2 dropdown-margin py-2"
                        style={{ display: dropDown }}
                      >
                        <a
                          onClick={addNoteFunction}
                          onKeyDown={addNoteFunction}
                          data-testid="callHandler"
                          className="text-black text-base no-underline global-txt"
                        >
                          <div className={"py-2 cursor-pointer text-black text-base no-underline dropdown-item " + (isP360Maintainence ? 'text-opacity-25 pe-none' : '')}>
                            {t("Add_Note")}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>}

            
                </div>
              </div>
              <div>
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md px-4">
                  <strong>{titleMessage}</strong>
                </p>
              </div>  
              {notesData?.length > 0 && !addNote ? (
               <div className=" max-height-790 overflow-auto">
                  {notesData?.slice(-2).map((note, key) => {
                    return (
                      <div className="px-4 py-2">
                        <div className="border border-grey bg-white rounded-lg mb-2">
                          <div className="bg-peach px-4 py-2 rounded-t-lg global-txt">
                            <div className="flex flex-row justify-between items-center">
                              <p className="my-0 text-lg">
                                <strong>{note?.type?.toLocaleUpperCase()}{t("note")}</strong>
                              </p>
                              <p className="my-0 text-lg">
                                <strong>{note?.author}</strong>
                              </p>
                            </div>
                          </div>
                          <div className="px-4 grid grid-cols-1 mt-3 global-txt">
                            <div className="mb-2">
                              <strong>{t("date")}</strong>
                              <br />{" "}
                              {moment(note?.creationDate).format(
                                "DD-MM-YYYY, h:mm:ss A"
                              )}
                            </div>
                            <div className="mb-2 div-word-break">
                              <strong>{t("Note")}</strong>
                              <br />
                              {note?.text}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  </div>
              ) : (
                <div></div>
              )}
              {!isError ? addNote && (
                <div className="notes-container">
                  <span className="add-note">{t("Add_note")}</span>
                  <label className="w-full global-txt">
                    <TextField
                      aria-invalid="true"
                      inputProps={{ maxLength: 3900 }}
                      margin="normal"
                      value={notesDescription}
                      onChange={(e) => onNotesInputChange(e)}
                      onBlur={e => onNotesFocusChange(e)}
                      className="textarea-notes global-txt pb-4 border-grey"
                      data-testid="Repair_Notes_Placeholder"
                      id="outlined-textarea"
                      variant="outlined"
                      placeholder={t("Add_note_placeholder_text")}
                      InputProps={{
                        inputComponent: "textarea",
                        style: { padding: 0, height: 200 },
                      }}
                    />
                  </label>
                  { (showNoteWarning !== "") && <YellowAlert messageText={t(showNoteWarning)} {...props}/>} 
                  <div className="w-full flex justify-between mb-2">
                  <label className="w-option"style={{display:"block"}}>
                      <select
                        id="dropDownLand"
                        className="block w-full rounded-lg border border-grey mx-1 sm:mx-0 md:mt-2"
                        value={noteType}
                        onChange={changeNoteType}
                        data-testid="dropDownLocation"
                      >
                        <option value="0">{t("select_note_type")} </option>
                        <option value="1">{t("CCIM_note")} </option>
                        <option value="2">{t("Chase_note")} </option>
                        <option value="3">{t("Other_note")} </option>
                      </select>
                    </label>
                    
                      <Button
                        onClick={notesCreationHandler}
                        className="button-class mr-3"
                        data-testid="btn_notes-creation"
                        disabled={disableSubmitNote}
                      >
                        {t("Submit-notes")}
                        <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
                      </Button>
                  </div>
                </div>
              ): showWarningDetails()}
              <div className="w-full px-4 py-4 flex justify-between absolute bottom-0 global-txt">
                <div>
                  <a
                    href=""
                    data-testId="ViewAllWorkOrderChronology"
                    className="text-teal no-underline hover:underline"
                    onClick={navigateToNoteDetails}
                  >
                    {t("ViewAll_Notes")} &gt;
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotesCard;
