import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom';
import { LoginButton, LoginWrapper } from './components'
import { useDispatch } from "react-redux";
import { loginStatus, userChosenBackButton } from '../../ducks/redux/actions/userChoices';
const urlParams=new URLSearchParams(window.location.search);


interface LoginProps extends RouteComponentProps<any> {
 
}

const Login: React.FC<LoginProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
 const RedirectToIDAMLoginPage=()=> {
  const urlParams=new URLSearchParams(window.location.search);
  const adLoginUrl: string = (urlParams.has('redirectUrl') ? `${process.env.REACT_APP_CSS_AD_LOGIN_URL ?? ''}${urlParams.get('redirectUrl')}` : process.env.REACT_APP_CSS_AD_LOGIN_URL ?? '') || '';
  dispatch(userChosenBackButton(false));
  window.location.href = adLoginUrl;
 }
   
  useEffect(() => {
   if(localStorage.getItem('userName'))
   {  
     props.history.push('/LandingView');
   }
   else {
    dispatch(loginStatus(false));   
   }
  });
  

  return (
    <LoginWrapper>
    <LoginButton data-bdd="LoginButton" onClick={RedirectToIDAMLoginPage}>{t('login_button')}</LoginButton>
  </LoginWrapper>
   
  )
 }


export default Login