import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import staticSortableFields from "../../models/SortableFields";
import * as api from "../../api";
import InfiniteScroll from "react-infinite-scroll-component";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { MobilePagination, Pagination, Paginationbox, WebPagination } from "./component";
import CustomerResponse, { CustomerData } from "../../models/Customer/CustomerResponse";
import { addressToString, formatDateWithFromCalendar, isNullOrUndefined } from "../../utils/formatting";
import LoadingSpinner from "../../storybook/LoadingSpinner";
import { PremisesAddress } from "../../models/shared/Address/PremisesAddress";
import CustomerResultDetails from "../CustomerResultDetails";
import { useDispatch, useSelector } from "react-redux";
import { customerList, customersData, userChosenCustomerID } from "../../ducks/redux/actions/userChoices";
import { SearchType } from "../../models/shared/Enum";
import { getCustomerId } from "../../ducks/redux/actions/getAddress";
import { CustomerStatus } from "../../models/Customer/CustomerFilter";

interface CustomerResultProps extends RouteComponentProps<any> {
    searchText?: string;
    searchTextCount?: number;
    tenancyType?: any;
    customerStatus?: any;
    handleSort?: any;
    sortValue?: any;
    currentPage?: any;
    resultsPerPage?: any;
    skip?: any;
    setShowFilter?: boolean;
}

const CustomerResultComponent: React.FC<CustomerResultProps> = (props) => {
    const customerValue = useSelector(
        (state: any) => state?.userChoicesReducer?.customerList
    );
    const IsSearch = useSelector(
        (state: any) => state?.userChoicesReducer?.IsSearch
    );
    const array: CustomerData[] = [];
    const [customers, setCustomerList] = useState(array);
    const { t } = useTranslation();
    const [totalCount, setTotalCount] = useState(0);
    const [defaultSortOrder, setDefaultSortOrder] = useState(isNullOrUndefined(props.sortValue) ? "search.score() desc" : props.sortValue);
    const [isLoading, setIsLoading] = useState(false);
    const searchTextCountValue = !props.searchTextCount ? 0 : props.searchTextCount;
    const searchTextValue = !props.searchText ? '' : props.searchText;
    const [currentPage, setcurrentPage] = useState(isNullOrUndefined(props.currentPage) ? 0 : props.currentPage);
    const [resultsPerPage, setresultsPerPage] = useState(isNullOrUndefined(props.resultsPerPage) ? 10 : props.resultsPerPage);
    const [skip, setskip] = useState(isNullOrUndefined(props.skip) ? 0 : props.skip);
    const dispatch = useDispatch();
    const defaultFilter= CustomerStatus.filter(function(item){
        return item.key=='Active'
        })[0].value;
   
    let index = 0;

    useEffect(() => {
        (async () => {
            if (!checkSearchCriteria()) {
                setIsLoading(false);
                return;
            }
            if (!props.setShowFilter && IsSearch) {
                setIsLoading(true);
                const queryParams = {
                    searchText: props.searchText,
                    limit: resultsPerPage,
                    skip: skip,
                    customerType: isNullOrUndefined(props.customerStatus) ? defaultFilter : props.customerStatus,
                    orderBy: defaultSortOrder,
                    tenancyType: isNullOrUndefined(props.tenancyType) ? '' : props.tenancyType,
                    uprn: ""
                };

                getCustomerData(queryParams);
            }
            else {
                [customerValue].map((items) => {
                    const data = new CustomerResponse(items, props.customerStatus, props.tenancyType);
                    setCustomerList(data?.customerData);
                    setTotalCount(data?.totalCount);
                    setIsLoading(false);
                });
            }
        })();
    }, [searchTextValue]);

    const getCustomerData = (queryParams: any) => {
        (async () => {
            try {
                dispatch(customersData(null));
                const customerSearchApi = await api.getCustomerData(queryParams);
                dispatch(customerList(customerSearchApi));
                [customerSearchApi].map((items) => {
                    const data = new CustomerResponse(items, props.customerStatus, props.tenancyType);
                    setCustomerList(data?.customerData);
                    setTotalCount(data?.totalCount);
                    setIsLoading(false);
                });
            } catch (e) {
                setIsLoading(false);
                console.error(e);
                props.history.push("/GenericError");
            }
        })();
    }

    const updateCustomerData = (searchText: any, limit: any, skip: any, customerType: any, orderBy: any) => {
        (async () => {
            const queryParams = {
                searchText: searchText,
                limit: limit,
                skip: skip,
                customerType: isNullOrUndefined(props.customerStatus) ? defaultFilter : props.customerStatus,
                orderBy: orderBy,
                tenancyType: isNullOrUndefined(props.tenancyType) ? '' : props.tenancyType,
                uprn: ""
            };

            getCustomerData(queryParams);
        })();
    }

    const handleChangeSortOrder = (e: { target: { value: string } }) => {
        props.handleSort(e.target.value);
        setDefaultSortOrder(e.target.value);
        updateCustomerData(props.searchText, resultsPerPage, skip, props.customerStatus, e.target.value);
    }

    const getValidationMessage = () => {
        if (isNullOrUndefined(props.searchText))
            return "blank_search_criteria";

        if ((/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1))))
            return "invalid_search_criteria";

        if (props.searchText?.includes('*')
            && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue)
            return t("minimum_number_of_characters1") + searchTextCountValue + t("minimum_number_of_characters2");

        if (searchTextValue?.length < searchTextCountValue)
            return t("minimum_number_of_characters");

        return "customers_search_no_results";
    }

    const checkSearchCriteria = () => {
        if (isNullOrUndefined(props.searchText) || (/[^0-9a-zA-Z]/.test(searchTextValue.substring(0, 1)))
            || (props.searchText?.includes('*') && props.searchText.substring(0, props.searchText.indexOf('*')).length < searchTextCountValue) || searchTextValue?.length < searchTextCountValue)
            return false;

        return true;
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setIsLoading(true);
        setcurrentPage(page);
        setskip(resultsPerPage * page);
        updateCustomerData(props.searchText, resultsPerPage, resultsPerPage * page, props.customerStatus, defaultSortOrder);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setIsLoading(true);
        setresultsPerPage((e.target as any).value);
        setskip(0);
        setcurrentPage(0);
        updateCustomerData(props.searchText, (e.target as any).value, 0, props.customerStatus, defaultSortOrder);
    };

    const fetchData = () => {
        let skipResult = 0;
        let totalValue = 0;
        if (totalCount > resultsPerPage) {
            if (skip === 0 && resultsPerPage < 998) {
                skipResult = 0
                totalValue = 999
                setskip(0);
            }
            else {
                skipResult = resultsPerPage
                totalValue = 999 + skipResult
                setskip(skipResult);
            }
            setresultsPerPage(totalValue);
            updateCustomerData(props.searchText, totalValue, skipResult, props.customerStatus, defaultSortOrder);
        }
    };
    const goToCustomer360Page = (id: string | undefined) => {
        dispatch(getCustomerId(id));
        dispatch(userChosenCustomerID(id));
        props.history.push('/Customer360View');
    }

    const createLink = (customer: CustomerData) => {
        const customerData = {
            customerId: customer.id, emailId: customer.email, phone: customer.mobilePhone
            , searchText: props.searchText, sortOrder: defaultSortOrder, customerType: props.customerStatus
            , tenancyType: props.tenancyType, searchType: SearchType.Customer, currentPage: currentPage
            , resultsPerPage: resultsPerPage, skip: skip
        };
        dispatch(customersData(customerData));
        props.history.push('/CustomerRegistration');
    }

    return (
        <div>
            <WebPagination>
                <div className="w-full global-txt">
                    <br></br>
                    <div className="padd-lr" role="main">
                        {!isLoading ? (
                            <div className="container mx-auto global-txt">
                                {/* < -- customer details start web view --> */}
                                {!customers?.length ? (
                                    <div className="w-full mt-4">
                                        <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                            <div className="px-4 pr-8">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                    {t(getValidationMessage())}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                    (
                                        <div>
                                            <div className="flex flex-col md:flex-row justify-between items-center">
                                                {/* ====== <!-- count --> ==== */}
                                                <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left ml-1"><strong>{totalCount}</strong> {t("Customer(s)")}</div>
                                                </div>

                                                {/* ======= Sort by dropdown ========= */}
                                                <div className="w-full md:w-5/12 lg:w-1/3 flex items-center mb-4">
                                                    <div className="w-1/2 md:w-auto pr-4 md:text-left">{" "}{t("customers_search_sort_label")}{" "}
                                                    </div>

                                                    <select data-testid="webCustomerSearchSort"
                                                        value={defaultSortOrder}
                                                        id="customers-search-sort1"
                                                        className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                        onChange={handleChangeSortOrder}
                                                        aria-label="Customer Search"
                                                    >
                                                        {staticSortableFields.map((e, key) => {
                                                            return (
                                                                <option key={key} value={e.sortableKey}>
                                                                    {e.displayName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            {customers.map((customer) => (
                                                <div className="w-full">
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-peach px-4 py-2 rounded-t-lg ">
                                                            <div className="flex flex-row justify-between items-center">
                                                                <a className="my-0 text-lg text-black cursor-pointer" data-testid={customer.id} onClick={() => goToCustomer360Page(customer?.id)}><strong>{`${customer?.name}`}</strong></a>
                                                            </div>
                                                        </div>

                                                        <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                            <div className="mb-2">
                                                                <strong>{t("address_txt")}</strong>{" "}
                                                                <br></br>
                                                                {addressToString((customer.tenancyModel?.premisesAddress as PremisesAddress))}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("tenancy_txt")}</strong>
                                                                <br></br>
                                                                {`${customer?.tenancyModel?.customerTenancyTypeGroup}`}
                                                            </div>

                                                            <div className="mb-2">
                                                                <strong>{t("DOB_txt")}</strong>
                                                                <br></br>
                                                                {formatDateWithFromCalendar(customer.dateOfBirth)}
                                                            </div>

                                                            <div className="mb-2 md:ml-4">
                                                                <strong>{t("status_txt")}</strong>
                                                                <br></br>
                                                                {customer?.tenancyModel?.isTenancyActive ? `${t("active_txt")}` : `${t("former_txt")}`}
                                                            </div>
                                                        </div>



                                                        {customer?.tenancyCount && customer?.tenancyCount > 1 ? (
                                                            <div className="px-4">

                                                                <CustomerResultDetails
                                                                    TenancyModelsChild={customer.tenancyModelsChild}
                                                                    tenancyCount={customer.tenancyCount}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        ) : null}
                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            {/* < -- Pagination view --> */}
                                            <div className="page-mar">
                                                <Paginationbox>
                                                    <label>
                                                        <Pagination
                                                            className=" ml-4 "
                                                            colSpan={4}
                                                            count={totalCount}
                                                            page={currentPage}
                                                            rowsPerPage={resultsPerPage}
                                                            rowsPerPageOptions={[10, 20, 100]}
                                                            onPageChange={onChangePage}
                                                            data-testid="customerPaginationChange"
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                        />
                                                    </label>
                                                </Paginationbox>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <div className="div-loader">
                                <div className="SpinnerWrapper">
                                    <LoadingSpinner />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </WebPagination>
            <MobilePagination>
                <div
                    id="scrollableDiv"
                    data-testid="scrollableDiv"
                    style={{
                        height: 800,
                        overflowY: "scroll",
                        overflow: "auto",
                        flexDirection: "column",
                    }}
                >
                    <InfiniteScroll
                        dataLength={customers?.length}
                        next={fetchData}
                        style={{ display: "flex", flexDirection: "column" }}
                        hasMore={true}
                        loader={<p></p>}
                        scrollableTarget="scrollableDiv"
                    >
                        <div className="w-full global-txt">
                            <br></br>
                            <div className="padd-lr global-txt" role="main">
                                {!isLoading ? (
                                    <div className="container mx-auto px-3">
                                        {/* < -- customer details start web view --> */}
                                        {!customers?.length ? (
                                            <div className="w-full mt-4">
                                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                                    <div className="px-4 pr-8">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className="text-brick-70 w-6 h-6"><path d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"></path></svg>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                                            {t(getValidationMessage())}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                            (
                                                <div>
                                                    <div className="flex flex-col md:flex-row justify-between items-center">
                                                        {/* ====== <!-- count --> ==== */}
                                                        <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                            <div className="w-1/2 md:w-auto pr-4 md:text-left"><strong>{totalCount}</strong> {t("Customer(s)")}</div>
                                                        </div>

                                                        {/* ======= Sort by dropdown ========= */}
                                                        <div className="w-full md:w-6/12 lg:w-1/3 flex items-center mb-4">
                                                            <div className="md:w-auto pr-4 md:text-left">{" "}{t("customers_search_sort_label")}{" "}
                                                            </div>

                                                            <select data-testid="mobileCustomerSearchSort"
                                                                value={defaultSortOrder}
                                                                id="customer-search-sort1"
                                                                className="block w-2/3 mt-1 rounded-lg border border-grey"
                                                                onChange={handleChangeSortOrder}
                                                                aria-label="Customer Search"
                                                            >
                                                                {staticSortableFields.map((e, key) => {
                                                                    return (
                                                                        <option key={key} value={e.sortableKey}>
                                                                            {e.displayName}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {customers.map((customer) => (
                                                        <div className="w-full">
                                                            <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                                <div className="bg-peach px-4 py-2 rounded-t-lg">
                                                                    <div className="flex flex-row justify-between items-center">
                                                                        <a className="my-0 text-lg text-black cursor-pointer" data-testid="custView1" onClick={() => goToCustomer360Page(customer?.id)}><strong>{`${customer?.name}`}</strong></a>
                                                                    </div>
                                                                </div>

                                                                <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                                    <div className="mb-2">
                                                                        <strong>{t("address_txt")}</strong>{" "}
                                                                        <br></br>
                                                                        {addressToString((customer.tenancyModel?.premisesAddress as PremisesAddress))}
                                                                    </div>

                                                                    <div className="mb-2 md:ml-4">
                                                                        <strong>{t("tenancy_txt")}</strong>
                                                                        <br></br>
                                                                        {`${customer?.tenancyModel?.customerTenancyTypeGroup}`}
                                                                    </div>

                                                                    <div className="mb-2">
                                                                        <strong>{t("DOB_txt")}</strong>
                                                                        <br></br>
                                                                        {formatDateWithFromCalendar(customer.dateOfBirth)}
                                                                    </div>

                                                                    <div className="mb-2 md:ml-4">
                                                                        <strong>{t("status_txt")}</strong>
                                                                        <br></br>
                                                                        {customer?.tenancyModel?.isTenancyActive ? `${t("active_txt")}` : `${t("former_txt")}`}
                                                                    </div>
                                                                </div>




                                                                {customer?.tenancyCount && customer?.tenancyCount > 1 ? (
                                                                    <div className="px-4">

                                                                        <CustomerResultDetails
                                                                            TenancyModelsChild={customer.tenancyModelsChild}
                                                                            tenancyCount={customer.tenancyCount}
                                                                            {...props}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                    </div>
                                ) : (
                                    <div className="div-loader">
                                        <div className="SpinnerWrapper">
                                            <LoadingSpinner />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            </MobilePagination>
        </div>
    )
}

export default CustomerResultComponent;