import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import {
  clearMediaFiles,
  deleteMediaItem,
  resumeDraftRepair,
  userChosenDeletedMedia,
  userChosenUploadedMedia,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  hasMediaAttached,
  isEditState,
  userChosenRelationDetails,
  fromMediaUpload,
  isRepairMediaResume,
  workOrderData,
} from "../../ducks/redux/actions/userChoices";
import Button from "../../Button/Button";
import RepairUploadMediaRow from "./RepairUploadMediaRow";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import classes from "./styles.module.css";
import styles from "./spinner.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeMediaFilesId } from "../../ducks/redux/actions/userChoices";
import { validateMediaSize } from "./mediaConfigure";
import { TableCell, TableRow, TableHead } from "@material-ui/core";
import * as ReactBootstrap from "react-bootstrap";

import Compress from "browser-image-compression";
import { isNullOrUndefined } from "../../utils/formatting";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { PermissionsList } from "../../models/Permissions";

const allowedExtensions = process.env.REACT_APP_ALLOW_EXTENSION as string;
const videoSize = process.env.REACT_APP_VIDEO_SIZE as string;

interface mediaFilesType {
  name: string;
  size: number;
  fileType: string;
}
interface RepairUploadMediaProps extends RouteComponentProps<any> {}

const RepairUploadMedia: React.FC<RepairUploadMediaProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<mediaFilesType[]>([]);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const [wrongFileType, setWrongFileType] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");
  let mediaLength = 0;

  let maxFileSize = +videoSize;
  
  
  const allowedConfigExtensions = allowedExtensions?.replace("[", "");
  const allowedExtensionsReplace =allowedConfigExtensions? allowedConfigExtensions.split("'").join("") : "";
  const allowFileType = allowedExtensionsReplace?.replace("]", "");

  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const assetByIdDataObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );


 
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );

  const resumeDraftRepairVal = useSelector(
    (state: any) => state?.userChoicesReducer?.resumeDraftRepair
  );


  const workOrderObject = useSelector(
    (state: any) => state?.addresses?.workOrderRepair
  );

  const userChosenUploadedMediaValue = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenUploadedMedia
  );

  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );

  const hideVulnerablePagePermission = getPermissionsData?.indexOf(PermissionsList.workoder_vulnerable_hide_permission) >= 0;

  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );


  const mediaIds = useSelector(
    (state: any) => state.userChoicesReducer?.mediaItems
  );
  const isRepairMediaResumed = useSelector(
    (state: any) => state.userChoicesReducer?.isRepairMediaResume
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.dashboardReducer.formState?.priorityCode
  );
  
  useEffect(() => {
    userChosenUploadedMediaValue?.map((mediaFile: any) => {
      if (mediaFile) {
        setMediaFiles(userChosenUploadedMediaValue);
      }
    });
    mediaLength = mediaIds?.length;
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    mediaLength = mediaIds?.length;
    if (mediaFiles?.length == 5) {
      setDisableUploadButton(true);
    }
  }, [mediaFiles]);

  // === Displaying media while user is coming from resume work order ===
  useEffect(() => {
    
    if (isRepairMediaResumed) {
      dispatch(isRepairMediaResume(false));
      resumeWorkOrderMedia();
    }
  }, []);

  const resumeWorkOrderMedia = async () => {
    try {
      setIsLoading(true);
      const { documents } = await api.getRepairMediaData(workOrderObject?.id);
      documents?.map((data: any) => {
        dispatch(storeMediaFilesId(data.id));
        data?.metadata?.map((res: any) => {
          if (res.key === "content-type") {
            const name = data.media.fileName;
            const fileType = res.value;
            dispatch(userChosenUploadedMedia({ name, fileType }));
            setMediaFiles((currentMediaFiles: any) => [
              ...currentMediaFiles,
              { name, fileType },
            ]);
          }
        });
      });
    } catch (e) {
      setError("Media_SSR011c");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      UpdateWorkOrder();
    }, 1000);
  }, [mediaIds?.length]);

  const backButtonHandler = () => {
    dispatch(fromMediaUpload(false));
    props.history.push("/repairadditionaldetails");
  };

  const exitButtonHandler = () => {
    dispatch(clearMediaFiles([]));
    props.history.push("/LandingView");
  };

  const UpdateWorkOrder = async () => {
    workOrderObject.priority = priorityCodeValue;
    const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
    let woRequestParameters =
      _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
    woRequestParameters.documents = {
      documentIdentifiers: mediaIds,
    };
    workOrderObject.documents = {   documentIdentifiers: mediaIds,
    };
    try {
      await api.updateWorkOrderRepairMedia(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      props.history.push("/genericerror");
    }
  };

  const saveDraftAndExit = () => {
    // === update store for resume draft ===
    dispatch(fromMediaUpload(true));
    setCloseAfterSaving(true);
    props.history.push(`/WorkOrder360View/${workOrderObject.id}`)

  };

  const onContinueHandler = () => {
    dispatch(fromMediaUpload(false));
    // === Validating media has attached ===
    if (mediaIds?.length > 0) {
      dispatch(hasMediaAttached("Yes"));
    } else {
      dispatch(hasMediaAttached("No"));
    }

    if (EditState === true && mediaLength !== mediaIds.length) {
      dispatch(resumeDraftRepair(false));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    if (resumeDraftRepairVal) {
      dispatch(resumeDraftRepair(false));
      dispatch(userChosenVandalismQuestion("No"));
      dispatch(userChosenVandalismAdditionalInformation(""));
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(isEditState(false));
    }

    if (
      assetByIdDataObject?.statuses?.isInDefects === true &&
      vandalismConfigValue === true
    ) {
      props.history.push("/vandalismquestion");
    } else {
      var hideVulnerablePage = hideVulnerablePagePermission || workOrderObject.isEmergency
      if(hideVulnerablePage){ 
        if(covidConfigValue){
          props.history.push("/CovidQuestion");
        }
        else {
          props.history.push("/CustomerDetails");
        }
      }
      else
      {
        props.history.push("/vulnerabilityquestion");
      }
    
    }
  };

  function encodeImageFileAsURL(element: any) {
    setError("");
     
    let file = element[0];
    const reader = new FileReader();
    const imgExt = file.type.split("/");
    if (
      (imgExt[1] === "jpeg" || imgExt[1] === "png" || imgExt[1] === "jpg") &&
      imgExt[0] === "image"
    ) {
      // Use webworker for faster compression with
      const options = {
        maxSizeMB: file.size / 2,
        useWebWorker: true,
      };
      Compress(file, options)
        .then((compressedBlob) => {
          // Convert the blob to file
          setIsLoading(true);
          reader.readAsDataURL(compressedBlob);
          reader.onloadend = () => {
            processMediaFile(element, file.name, reader.result, file.type);
          };
        })
        .catch((e) => {});
    } else {
      reader.onloadend = () => {
        processMediaFile(element, file.name, reader.result, file.type);
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  const processMediaFile = async (
    uploadMediaArray: any,
    name: any,
    image: any,
    fileType: any
  ) => {
    const size = validateMediaSize(fileType);

    const validExt = fileType.split("/");
    if (validExt[1] === "quicktime") {
      validExt[1] = "mov";
    } else if (validExt[1] === "x-matroska") {
      validExt[1] = "mkv";
    } else if (validExt[1] === "jpeg") {
      validExt[1] = "jpg";
    }

    

    if (!allowedExtensions?.includes(validExt[1])) {
      setFileTooLarge(false);
      setWrongFileType(true);
    }else if (uploadMediaArray[0].size > size) {
      setWrongFileType(false);
      setFileTooLarge(true);
    } 
    else {
      setFileTooLarge(false);
      setWrongFileType(false);
     let mediaParam = {};
      // === Upload media files on server ===
    if(isNullOrUndefined(customerIdentifier)){
        mediaParam = {
            DocumentType: "WORKORDER",
            Media: image?.substr(image?.indexOf(",") + 1),
            Metadata: [
              {
                key: "asset-identifier",
                value: assetByIdDataObject.id,
              },
              {
                key: "works-order-identifier",
                value: workOrderObject.id,
              },
              {
                key: "filename",
                value: uploadMediaArray[0].name,
              },
    
              {
                key: "content-type",
                value: fileType,
              },
            ],
          };
    }
    else{
        mediaParam = {
            DocumentType: "WORKORDER",
            Media: image?.substr(image?.indexOf(",") + 1),
            Metadata: [
              {
                key: "customer-identifier",
                value: customerIdentifier,
              },
              {
                key: "asset-identifier",
                value: assetByIdDataObject.id,
              },
              {
                key: "works-order-identifier",
                value: workOrderObject.id,
              },
              {
                key: "filename",
                value: uploadMediaArray[0].name,
              },
    
              {
                key: "content-type",
                value: fileType,
              },
            ],
          };
    }
      
     
      try {
        setIsLoading(true);
        const response = await api.postRepairMediaFile(
          workOrderObject.id,
          mediaParam
        );

        // ===== Updating patch work work when media uploaded successfully ====
        dispatch(storeMediaFilesId(response.documentIdentifier));
        dispatch(userChosenUploadedMedia({ name, fileType }));
        setMediaFiles((currentMediaFiles: any) => [
          ...currentMediaFiles,
          { name, fileType },
        ]);
      } catch (e) {
        setError("Media_SSR011c");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleMediaFilesChange = (event: any) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      encodeImageFileAsURL(event.target.files);
    }
  };

  const deleteMediaFile = async (id: number) => {
    setFileTooLarge(false);
    setWrongFileType(false);
    setError("");

    try {
      setIsLoading(true);
      await api.deleteRepairMediaFiles(workOrderObject.id, mediaIds[id]);
      dispatch(deleteMediaItem(mediaIds[id]));
      // === Delete media files from database ===
      const updatedMedia = mediaFiles.filter(
        (mediaFile: any, index: number) => {
          return index != id;
        }
      );
      setMediaFiles(updatedMedia);
      dispatch(userChosenDeletedMedia(updatedMedia));
      setDisableUploadButton(false);
    } catch (e) {
      setError("Media_SSR011b");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrag = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragover" || e.type === "dragenter") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      if (mediaFiles.length < 5) {
        encodeImageFileAsURL(event.dataTransfer.files);
      }
    } else {
      console.log("File was not uploaded.");
    }
  };

  const onButtonClick = () => {
    inputRef.current!.click();
  };

  return (
    <Container id="supp-main-container" role="main">
      
      <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>

      
      {isLoading && (
        <div className={classes.overlay}>
          <div id="UploadingOverlay">
            <div className={styles.overlayContainer}>
              <div className={styles.overlayContent}>
                <div className={styles.spinner}></div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="question ml-sm-5 pl-sm-5 repair-text">
        <div className="py-2 h5 mar-repadd">
          <h1
            data-testid="Upload_Media_Title"
            className="text-purple-100 text-2xl mb-2 rep-mar global-txt"
          >
            {t("Repair_Upload_Media_Title")}
          </h1>
        </div>
        <div>
          <p className="text-gray-700 text-black global-txt fw-light">
            {t("Reapir_Upload_Media_SubHeading_Attachment")}
          </p>
          <p className="text-gray-700 text-black global-txt fw-light">
            {`${t("Upload_Media_SubHeading2")} ${allowFileType}`}
          </p>
        </div>
        <div className="w-full">
          <form
            className={classes["form-file-upload"]}
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
            data-testid="form-file-upload"
          >
            <input
              className={classes["input-file-upload"]}
              ref={inputRef}
              type="file"
              id="input-file-upload"
              onChange={handleMediaFilesChange}
              disabled={disableUploadButton}
              data-testid="input-file-upload"
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={
                dragActive
                  ? classes["label-file-upload-drag-active"]
                  : classes["label-file-upload"]
              }
            >
              <div>
                <div
                  className={`${classes.hideMobViewText} global-txt fw-light`}
                >
                  {t("Upload_Media_Initial_Text1")}
                </div>
                <div
                  className={`${classes.hideMobViewText} global-txt fw-light`}
                >
                  {t("Upload_Media_Initial_Text2")}
                </div>
                <div
                  className=" mt-2 "
                  onClick={onButtonClick}
                  data-testid="browse-button"
                >
                  <input
                    type="button"
                    name="mediaFiles"
                    value="Add Attachment"
                    className={disableUploadButton ? classes.disabled : ""}
                    disabled={disableUploadButton}
                  />
                  <i className="fas fa-upload relative -left-8 text-white pointer-events-none"></i>
                </div>
              </div>
            </label>
            {dragActive && (
              <div
                className={classes["drag-file-element"]}
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                data-testid="drag-file-element"
              ></div>
            )}
          </form>

          <div className=" flex-col md:flex-row md:justify-between">
            <div className="text-gray-700 text-black global-txt fw-light">
              {`${t("Upload_Media_Initial_Text3")} ${maxFileSize / 1000000}MB`}
            </div>
            <div
              className={`${classes.uploadMediaNo} text-gray-700 text-black global-txt fw-light text-right`}
            >
              {mediaFiles.length}/5
            </div>
          </div>

          <div className="error mt-4 pb-2">
            <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
              <div className="px-4 pr-8">
                <i className="fas fa-bullhorn text-purple-100 w-6 h-6"></i>
              </div>
              <div className="flex items-center text-black">
                <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                  {t("Upload_Media_Initial_Alert_1")} <br/><br/>
                  {t("Upload_Media_Initial_Alert_2")}
                </p>
              </div>
            </div>
          </div>

          {fileTooLarge && (
            <>
              <div className="error mt-4 pb-2">
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center text-black">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("Repair_Upload_Media_Attachments_Too_Large")}
                    </p>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}

          {error && (
            <div className="error mt-4 pb-2">
              <div className="w-full px-2 py-2 bg-yellow flex items-center">
                <div className="px-4 pr-8">
                  <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                </div>
                <div className="flex items-center text-black">
                  <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                    {t(error)}
                  </p>
                </div>
              </div>
            </div>
          )}

          {wrongFileType && (
            <>
              <div className="error mt-4 pb-2">
                <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center text-black">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("Upload_Media_Wrong_File_Type")}
                    </p>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}

          {mediaFiles.length >= 5 && (
            <div className="error mt-4 pb-2">
              <div className="w-full px-2 py-2 bg-yellow flex items-center">
                <div className="px-4 pr-8">
                  <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                </div>
                <div className="flex items-center text-black">
                  <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                    {t("Upload_Media_Max_Attachments")}
                  </p>
                </div>
              </div>
            </div>
          )}

          {mediaFiles.length >= 1 && (
            <div className="mb-4 mt-4 text-black">
              <div className="w-full border border-grey">
                <ReactBootstrap.Table striped className="container-new">
                  <TableHead className={classes.tableRowColor}>
                    <TableRow>
                      <TableCell className="items-center">
                        <strong className={classes.tableHeader}>Name</strong>
                      </TableCell>

                      <TableCell align="right" className="items-center">
                        <strong className={classes.tableHeader}>Remove</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </ReactBootstrap.Table>

                {mediaFiles.map((mediaFile: any, key: number) => {
                  return (
                    <RepairUploadMediaRow
                      key={key}
                      mediaFileName={mediaFile.name}
                      type={mediaFile.fileType}
                      bgColour={key % 2 == 0 ? "bg-white" : "bg-white"}
                      deleteMediaFile={() => deleteMediaFile(key)}
                      {...props}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="flex justify-between items-center pb-4 md:pb-8"
        id="supp-main-btn"
      >
        <ExitModalAndSave
          id="supp-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
        <Button
          id="supp-btn1"
          onClick={onContinueHandler}
          className="button-class"
        >
          {t("continuen_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </Container>
  );
};

export default RepairUploadMedia;
