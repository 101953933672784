import React, { useState, useEffect, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import ExitModalContent from "../../Modal/ExitModalContent";
import "./index.css"
import Button from "../../../Button/Button";
import * as ReactBootstrap from "react-bootstrap";
import {  clearReferralInformation, comingFromReferralPage, saveReferralAccountBalance } from "../../../ducks/redux/actions/FinancialReferral";
import * as api from "../../../api";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Loader from "react-loader-spinner";
import { userChosenAccountNumber } from "../../../ducks/redux/actions/userChoices";
import { BalanceType } from "../../../models/Accounts/BalanceType";


interface ReviewrReferralProps extends RouteComponentProps<any> {}
const FinancialReferralReview: React.FC<ReviewrReferralProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(''); 
  const [borough, setBorough] = useState("")
  const [balance, setBalance] = useState("");
  const [contactType, setContactType] = useState("")
  const [continueButtonDisable, setContinueButtonDisable] = useState(false);
  const tableData = ['Full Name', 'Who is the referral For?', 'Does the tenant or household member consent to being referred to the financial inclusion team?','Property address','Borough', 'Reason for referral?', 'Additional referral details', "What is the customer's preferred contact method?", 'Account Balance', 'Payment Reference' ]
  const editableData = ['Read Only', 'editable', 'editable', 'Read Only','Read Only','Editable', 'Editable', 'Editable', 'Read Only', 'Read Only']
  const data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)

  const referralDetail = useSelector((state:any) => state.financialReferralReducer.referralDetails)
  const accBalance = useSelector((state:any) => state.financialReferralReducer.referralAccountBalance) 


  const assetId = useSelector(
    (state: any) => state?.userChoicesReducer?.defaultAssetId
  );


  const payRef = useSelector(
    (state: any) => state.userChoicesReducer.accountnumber 
  );

  const shareContactType = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber || []
  );

  useEffect(()=>{
    (async () => {
      try{
        const accountBalance = await api.getAccount(payRef);
        accountBalance["balance"].forEach((balance: any) => {
          if (balance["balance-type"] == BalanceType.Livebalanced) {
            setBalance("£"+ balance["monetary-amount"]["value"])
           
          }
        })
        
      }catch(e:any){
        console.log("Error:", e)
      }finally{
        setIsLoading(false)
      }
      
    })();

    shareContactType?.forEach((data: any) => {
      if (data?.number === contactNo) {
        setContactType(data?.telecomsAddressType)
      }
    })
  
  },[])
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    (async () => {
      try{
        const associatesdata = await api.getAssetsByid(assetId);
        setBorough(associatesdata?.borough)
      }catch(e:any){
        console.log("Error:", e)
      }finally{
        setIsLoading(false)
      }     
    })();
  },[assetId])

  
  const customerData = useSelector((state: any) => state?.userChoicesReducer?.customersData);


  const userChosenPropertyAddress = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenPropertyAddress
  );
  const contactNo = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone || null
  );

  const contactName = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName || null
  );

  const contactRelation = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails || null
  );

  
  const title = (customerData?.person?.name?.title)?? ''

  const referralReason = (data?.ReferralReason)?.join(', ')?? null;
  
  const contactInfo = (contactName && contactRelation) ? `${contactNo}, ${contactName}, ${contactRelation}`: contactNo

  const fullName = `${title} ${customerData?.person?.name?.givenName} ${customerData?.person?.name?.surname}`
  
  const ansValue = [fullName, data?.referralFor, data?.FinancialInclusionTeam, userChosenPropertyAddress, borough, referralReason, referralDetail, contactInfo,accBalance,payRef]

  const bodyData = {
    "additionalDetails": referralDetail,
    "referralReason": data?.ReferralReason,
    "referralFor": data?.referralFor,
    "assetIdentifier": assetId,
    "hasTenantConsent": data?.FinancialInclusionTeam === "Yes"? true: false,
    "serviceRequestType": "financialinclusionreferral",
    "preferredContact": {
      "personName": contactName,
      "relationship": contactRelation,
      "phoneNumber": contactNo,
      "phoneNumberType": contactType,
    }
  }

  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    dispatch(saveReferralAccountBalance(""))
    dispatch(userChosenAccountNumber(""));
    props.history.push('/Customer360View');
  }
  const onBackHandler = () => {
    dispatch(comingFromReferralPage(true))
    props.history.push('/ContactDetails');
  }
  const onContinueHandler = async() => {
    setContinueButtonDisable(true)
    // Call api to submit data
    try {
      setIsLoading(true)
      await api.submitFinancialInclusion(customerData['customer-identifier'],payRef,bodyData)
      props.history.push('/referralConfirmation')
    }catch(e:any){
      setError('Financial_FI013c')
      setContinueButtonDisable(false)
    }finally{
      setIsLoading(false)
    }
  }

  const onEditHandle = (index:number) => {
    if(index === 1 || index ===2){
      props.history.push('/CustomerConsent')
    }else if(index===5){
      props.history.push('/referralreason')
    }else if(index===6){
      props.history.push('/referraladditionaldetails')
    }else if(index===7){
      dispatch(comingFromReferralPage(true))
      props.history.push('/ContactDetails')
    }
  }

  return (
    <div className="container px-2 sm:mx-1 ctn-fht" id="case-container" data-testid="case-container" role="main">

      <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 px-2" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={onBackHandler} >
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>

      {isLoading &&<Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />}
      <div className="mb-16">
      <div id="confirmation-main-div" className="global-txt">
                <h1
                  data-testid="case-title"
                  className="text-purple-100 text-2xl mb-2"
                >
                  {t("Financial_FI013a")}
                </h1>
                <p data-testid="case-title-t1" className="mb-4 color_black"></p>
                <p data-testid="case-title-t2" className="mb-4 color_black">
                {t("Financial_FI014a")}
                </p>
              </div>
                <div className="repair-text " id="confirmation-div-table">
                <ReactBootstrap.Table striped className="container-new">
                    <TableBody role="presentation" id="confirmation-table" className="w-full border border-grey">
                      <th className="hidden">{t("table_heading")}</th> 
                      {tableData.map((data:string, index:number)=> {
                    return (
                        <TableRow
                          id={"confirmation-tr1"}
                          className="flex items-center bg-white"
                          key={index}
                        >
                        <TableCell
                          id={'confirmation-tr1-td4'}
                          className="w-5/12 p-3 firstcol"
                          >
                          <div className="text-base padding-new color_black global-txt">
                            {data}
                          </div>
                        </TableCell>
                        <TableCell
                        id={'confirmation-tr1-td4'}
                        className="w-5/12 p-3 firstcol"
                        >
                        <div className="text-base padding-new color_black global-txt">
                          {index==8? balance: ansValue[index]}
                        </div>
                      </TableCell>
                      <TableCell
                        id={'confirmation-tr1-td4'}
                        className="w-5/12 p-3 firstcol"
                        >
                        <div className="text-base padding-new color_black global-txt">
                          {editableData[index]==='Read Only'? '': 
                            <i
                            data-testid="question"
                            id={"confirmation-tr1-icon"}
                            className="fas fa-edit text-teal text-lg"
                            onClick={()=> onEditHandle(index)}
                          ></i>
                          }
                        </div>
                      </TableCell>
                      </TableRow>
                      )})}
                      
                    </TableBody>
                  </ReactBootstrap.Table>
                </div>
                </div>

             {/* Red Alert */}
              <div    
                data-testid="alert-warning"
                className="error px-2 py-2"
                role="alert"
                placeholder="alert-message"
              >
               {  error &&  <div className="w-full px-2 py-2 bg-red-20 flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                    </div>
                    <div className="flex items-center">
                      <p
                        data-testid="locatioAlert_0"
                        className="w-full text-left my-2 font-AvantGardeGothic-Md"
                      >
                        {t(error)}
                      </p>
                      
                    </div>
                  </div>}
             </div> 
      {/* Exit and Continue to raise inclusion */}
            <div
              className="flex justify-between items-center pb-4 md:pb-8 px-2"
              id="sms-btn"
            >
              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg="Return to my referral"
              />
              <Button
                disabled={continueButtonDisable}
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={onContinueHandler}
                placeholder="send-sms"
                value="Send"
                className="button-class"
              >
                {t("Continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
        </div>    
    </div>
  );
};

export default FinancialReferralReview;
