import React from "react";
import Modal from "./index";
import { ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as api from "../../api";
import { Asset, AssetType, Statuses, UpdateRepair } from "../../models/Repair/Statuses";
import { PremisesTypeId } from "../../models/Customer/Premises";


const CancelModelSurvey: React.FC<ModalProps> = ({ workOrderId, area, errorCode, contractor, props }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = React.useState(false);
	const [continueEnable, setContinueEnable] = React.useState(false);
	const isMPTContractor = contractor != null && contractor != '' && contractor.toLowerCase().indexOf("mpt") > - 1;
	const isMPTNotificationEligible = isMPTContractor && isMPTContractor == true && errorCode == "SSR073";

	const modelOpen = () => {
		setIsOpen(true)
	}
	const modelClose = () => {
		setIsOpen(false)
	}
	const cancelAndExit =async () => {
		await proceedForCancelRepair().then(()=>{
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		}).catch((e)=> {
				console.error(e);
				props?.history.push("/genericerror");
		});
	};

	const proceedForCancelRepair =async () => {
		setContinueEnable(true);
		const repair = new UpdateRepair();
				const statuses = new Statuses();
				const assetType = new AssetType();
				const asset = new Asset();
				assetType.assetTypeId =
					PremisesTypeId[area as keyof typeof PremisesTypeId];
				if (area === "Divided Block") {
					assetType.assetTypeId = 5;
				}
				asset.type = assetType;
				repair.asset = asset;
				statuses.Status = "Cancelled";
				repair.statuses = statuses;
				await api.repaireWorkOrderUpdate(
					repair,
					workOrderId
				)
	}
	return (
		<>
			<a id="exitmodal-btn1" data-testid="Cancel-button" 
				className="text-base no-underline text-black" onClick={modelOpen} onKeyDown={modelOpen}>
                <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                {t("Survey.CP077_CancelSurvey")}
                </div>
              </a>
			
			<Modal open={isOpen} data-testid="CancelModel" 
					cssClasses="modalPopCancel max-w-144 mx-auto text-left bg-white rounded-lg shadow-lg border border-grey">
				
					<div className="absolute right-2 top-2 flex items-center justify-center text-teal w-6 h-6 p-1 rounded-full border z-50 cursor-pointer" >
						<i data-testid="CancelModelClose" 
							className="far fa-times-circle py-1 text-teal w-6 h-6 font-icon brighten"
                            onKeyDown={modelClose}
						 onClick={modelClose}></i>
					</div>
					
					<div className="container">
					<div className="row">

						<div className="col-md-12">
							<div id="exitmodal-div2" className="py-lg-0 py-sm-0 desk-my-5 global-txt p-4 text-left">
								<h2 className="my-2 text-purple-100 text-md-left text-wrap">
								{t("Survey.CP109_CancelSurvey_Header")}
								</h2>
								<p className="mb-12 mt-8 text-wrap">{t("Survey.CP109_CancelSurvey_Text")}</p>
						
							</div>	
						</div>

						<div className="col-md-12">
							<div id="exitmodal-div3" className='px-4 md:flex md:justify-between'>						
								
								<div className="mb-4 md:mb-0 pl-6 mob-pad-x">
									<a id="exitmodal-btn2" 
										className="block  bg-teal text-white text-sm px-4 py-2 rounded no-underline cursor-pointer" 
										onClick={modelClose} onKeyDown={modelClose}>{t("Survey.CancelSurvey_return_button")}
										{" >"}
									</a>
								</div>

								<div className="mb-4 md:mb-0 pr-6 mob-pad-x">
									<a id="exitmodal-btn4"
										data-testid="exitmodal-btn4" 
										onClick={cancelAndExit}
                                        onKeyDown={cancelAndExit}
										className={`block bg-teal text-white text-sm px-4 py-2 rounded no-underline cursor-pointer${continueEnable? " cancel-disable " : ""}`} >
											{t("Continue_cancel")}
										{" >"}
									</a>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				
			</Modal>

		</>
	);
};

export default CancelModelSurvey;
