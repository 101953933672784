import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";

interface AlertDetailProps extends RouteComponentProps<any> {
    Alert: string;
}

const RedAlert: React.FC<AlertDetailProps> = (props) => {

const { t } = useTranslation();
  return (
    <div
    data-testid="alert-warning"
    className="warning mt-2 mb-2 unblockingMessage"
    role="alert"
    placeholder="alertNo"
  >
    <div className="w-full px-2 py-2 bg-red-20 flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-circle me-2 text-brick-100 w-6 h-6 bolder-font"></i>
      </div>
      <div className="flex items-center text-black">
        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
        {t(`${props.Alert}`)}
        </p>
      </div>
    </div>
  </div>
  )
};
export default RedAlert;