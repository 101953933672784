import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import electricImg from '../../assets/icons/electric.png';
import { useDispatch, useSelector } from "react-redux";
import { getserviceCardData } from "../GasBoilerCard/serviceCard";
import { formatDate } from "../../utils/formatting";
import { savingServiceCardData } from "../../ducks/redux/actions/FinancialReferral";


interface EICRCardProps extends RouteComponentProps<any> {
}
const EICRCard: React.FC<EICRCardProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [result, setResult] = useState<any>()
    let assetDataDetails = useSelector((state: any) => state?.addresses?.assetData);
    const getEICRCardData =  async() => {
        const complianceType = "EICR 5Y"
        try{
          const res =  await getserviceCardData(assetDataDetails?.id, complianceType);
          if(res?.totalCount > 0){
          setResult(res?.compliances[0])
            dispatch(savingServiceCardData(res?.compliances[0]));
          }
        }catch(e:any){
          console.log(e)
        }  
    }

    useEffect(()=>{
      getEICRCardData();
    },[])
    
    return (
    <div className=" relative border pb-16 border-grey bg-grey rounded-lg cust-card mt-4">
        <div className="px-4 py-2 border-b border-grey  bg-teal rounded-t-lg">
          <div className="flex flex-row d-flex bd-highlight items-center">
            <img src={electricImg} className=" text-2xl mr-6 sm:mr-4 bd-highlight logo logoLeft" alt="" />
            <h2 className="my-0 text-white bd-highlight">{t("EICR")}
            </h2>
           </div>
           </div>   
           {/* Binding data here */}
         <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Due_Date")}</b></div>
          <div className="cardEmail">{formatDate(result?.visitDue || "-")}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Contractor_CP114")}</b></div>
          <div className="cardEmail">{result?.contractor?.contractorName || "-"}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Contractor_Contact")}</b></div>
          <div className="cardEmail">{result?.contractor?.contractorNumber || "-"}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Last_Visit")}</b></div>
          <div className="cardEmail">{formatDate(result?.visitActual || "-")}</div>
        </div>
        <div className="px-4 topStyle">
          <div className="pr-4 mt-2"><b>{t("Visit_Outcome")}</b></div>
          <div className="cardEmail">{result?.visitOutcome || "-"}</div>
        </div>
    </div> 
    )
};
export default EICRCard;