import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import * as api from "../../api";
import "./style.css";
import classes from "../RepairUploadMedia/spinner.module.css";
import { imgData } from "../RepairUploadMedia/StaticIconName";
import { useDeviceSelectors } from "react-device-detect";
import { useSelector } from "react-redux";

interface RepairViewMediaProps extends RouteComponentProps<any> {}
const MpegAlertUserAgents = process.env.REACT_APP_MpegAlertSupportedBrowser;

const RepairViewMedia: React.FC<RepairViewMediaProps> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mediaFile, setMediaFile] = useState<{}[]>([]);
  const [isOverlay, setIsOverlay] = useState("none");
  const [base64Data, setBase64Data] = useState("");
  const [contentType, setContentType] = useState([]);
  const [isProccessing, setIsProccessing] = useState(false);
  const [isError, setIsError] = useState("");
  const [selectedId, setSelectedId] = useState();
  const videoRef: any = useRef();
  const location = useLocation();
  let inAppContent: boolean = false;
  const workOrderData = useSelector(
    (state: any) => state?.userChoicesReducer?.workOrderData
  );
  const [isMpegSupportedBrowser, setMpegSupportedBrowser]: any =
    useState(false);

  useEffect(() => {
    if (isError) {
      setIsOverlay("none");
    }
  }, [isError]);

  const detectedDeviceBrowser = useDeviceSelectors(window.navigator.userAgent);

  useEffect(() => {
    for (let [key, value] of Object.entries(detectedDeviceBrowser[0])) {
      if (key === MpegAlertUserAgents) {
        setMpegSupportedBrowser(value);
      }
    }
  }, []);

  const fetchMediaData = async (id: any) => {
    setIsLoading(true);
    try {
      const repairViewMedia = await api.getRepairMediaData(
        workOrderData.identifiers?.worksOrderIdentifier
      );
      setMediaFile(repairViewMedia.documents);
    } catch (e: any) {
      setIsError("View_Media_Error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMediaData(location?.state);
  }, [location]);

  const displayViewMediaDetails = async (mediaId: any, content: any) => {
    setIsError("");
    setIsProccessing(true);
    setIsOverlay("block");
    setContentType(content.split("/"));
    setSelectedId(mediaId);

    try {
      const response = await api.getRepairViewMediaDetail(workOrderData.identifiers?.worksOrderIdentifier, mediaId);
      
      const data1 = response.media.replace('"', "");

      let selectedMedia = `data:${content};base64,${data1}`;

      setBase64Data(selectedMedia);

      setIsProccessing(false);

      // istanbul ignore next Displaying pdf alert and closing overlay
      if (content.split("/")[1] === "pdf") {
        setIsOverlay("none");
        const byteCharacters = atob(data1);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: content });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      }
    } catch (e: any) {
      setIsProccessing(false);
      setIsError("View_Media_Error");
    }
  };

  const closeOverLay = () => {
    setIsOverlay("none");
    if (contentType[1] !== "mpeg" && contentType[0] === "video") {
      videoRef?.current?.pause();
    }
  };

  const displayErrorMessage = useCallback(() => {
    return (
      mediaFile.length === 0 &&
      isError && (
        <div className="error mt-2 mb-2" data-testid="apierror">
          <div className="w-full px-4 py-2 bg-yellow flex items-center">
            <div className="px-4 pr-8">
              <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
            </div>
            <div className="flex items-center text-black">
              <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                {t(isError)}
              </p>
            </div>
          </div>
        </div>
      )
    );
  }, [isError]);
  
  return (
    <Container id="supp-main-container " className = "px-0 ctn-fht1" role="main">
      {/* <BackModalContent
        id="supp-main-backmodal"
        back={backButtonHandler}
        aria-label="backmodal"
      /> */}
      {isLoading ? (
        <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
      ) : (
        <>
          {/* <div className="flex justify-between items-center pb-4 container">
            <h4>{t("View Media")}</h4>
          </div> */}
          <div className="">
              <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
          <div className="flex flex-row justify-between items-center">
          <div className="my-0 text-lg">
                      <b> {t("MediaAttached")}</b>
                      </div></div></div>
                      
          <div className="border border-grey rounded-lg">
            {mediaFile?.length>0?
              (mediaFile?.map((data: any) => {
                data?.metadata?.map((res: any) => {
                  inAppContent = false;
                   if(res.key === "IsMediaAppropriate" && res.value === "false") {
                    inAppContent = true;
                  }
                });
                return data?.metadata?.map((res: any) => {
                  if (res.key === "content-type" && inAppContent === false) {
                    const matchData = res.value.split("/");
                    return imgData.map((item: any, index: number) => {
                      if (
                        matchData[1] === item.id ||
                        matchData[1] === item.id.toUpperCase()
                      ) {
                        return (
                          <>  
                          <div className="py-2 flex justify-between border-b border-grey">
                    <div className="pr-4 imgClass mb-2">
                     
                              <img
                                src={item.src}
                                alt={`thumbnail icon${Math.floor(
                                  Math.random() * 20
                                )}`}
                              />
                              <span className="imgNameMedia">
                                {data.media.fileName}
                              </span>
                              
                             
                    </div>
                    <div className="text-capitalize text-right mr-4">
                         <a data-testid={data.media.fileName} className="back-btn" onClick={() =>displayViewMediaDetails(data.id, res.value) }>{t("view")}</a>
                    </div>
                </div>
                
                            {isError && selectedId === data.id && (
                              <div className="error mt-2 mb-2">
                                <div className="w-full px-4 py-2 bg-yellow flex items-center">
                                  <div className="px-4 pr-8">
                                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                                  </div>
                                  <div className="flex items-center text-black">
                                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                                      {t(isError)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    });
                  }
                 else if(inAppContent ===true && res.key === "IsMediaAppropriate" && res.value === "false") {
                  return (
                    <div className="my-2 py-2 mx-3">
                    {t("Inappropriate_Media_Error")}
                    </div>
                  )
                }
                });
              })):(
  <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
  <p className=" text-left my-2 ml-4 global-txt text-wrap">
       { t("No_WorkOrder_Media")}
   </p>
</div>
)}
            </div>
            {displayErrorMessage()}
          </div>
        </>
      )}

      <div className="overlay" style={{ display: isOverlay }}>
        {isProccessing ? (
          <div id="UploadingOverlay">
            <div className={classes.overlayContainer}>
              <div className={classes.overlayContent}>
                <div className={classes.spinner}></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mediaViewOverlay">
            <span
              className="deleteIcon"
              onClick={closeOverLay}
              data-testid="overlay"
            >
              X
            </span>
            <div className="mediaView">
              {contentType[0] === "video" && contentType[1] !== "mpeg" && (
                <video
                  controls
                  autoPlay
                  className="enlargeImgView"
                  ref={videoRef}
                >
                  <source src={base64Data} type="video/mp4"></source>
                </video>
              )}

              {!isMpegSupportedBrowser &&
                contentType[0] === "video" &&
                contentType[1] === "mpeg" && (
                  <>
                    <video
                      controls
                      autoPlay
                      className="enlargeImgView"
                      // style={{ backgroundColor: "#333333", height: "99%" }}
                    >
                      <source src={base64Data} type="video/mp4"></source>
                    </video>
                    <div className="error overlayMpeg">
                      <div className="w-full px-4 py-2 bg-yellow flex items-center">
                        <div className="px-1 pr-8">
                          <i className="fas fa-exclamation-triangle text-brick-70 w-8 h-6"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            Your browser doesn't support playing MPEG videos,
                            please click{" "}
                            <a download="video.mpeg" href={base64Data}>
                              here
                            </a>{" "}
                            to download the video instead.
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {contentType[0] === "video" &&
                contentType[1] === "mpeg" &&
                isMpegSupportedBrowser && (
                  <>
                    <video
                      controls
                      autoPlay
                      className="enlargeImgView"
                      style={{ backgroundColor: "#333333", height: "99%" }}
                    >
                      <source src={base64Data} type="video/mp4"></source>
                    </video>
                    <div className="error overlayMpeg">
                      <div className="w-full px-4 py-2 bg-yellow flex items-center">
                        <div className="px-1 pr-8">
                          <i className="fas fa-exclamation-triangle text-brick-70 w-8 h-6"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            {t("Media_MPEG_Apple")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {contentType[0] === "image" && (
                <img
                  src={base64Data}
                  alt={`thumbnail icon${Math.floor(Math.random() * 20)}`}
                  className="enlargeImgView"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
 
};

export default RepairViewMedia;