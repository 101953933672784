// TODO move shared types from Customer to shared
import Status from '../Customer/Status'
import TelecomsAddress from '../shared/Address/TelecomsAddress'

export class Contractor {
  public id: string = ''
  public name: string = ''
  public statuses: Status[] = []
  public identifiers: {
    sitesIdentifiers: string[]
    parentIdentifier?: string
  } = {
    sitesIdentifiers: [],
    parentIdentifier: '',
  }
  public addresses: {
    telecomsAddresses: TelecomsAddress[]
  } = {
    telecomsAddresses: [],
  }
}

export class ContractorPriority {
  public isWorkingHours: boolean = false
  public isHoliday: boolean = false
  public repairPriority: string = ''
}
export enum CatalistTriageContractorType {
  CATALYST_TRIAGE_DEFECT = 'CATALYST_TRIAGE_DEFECT',
  CATALYST_TRIAGE_TIER2 = 'CATALYST_TRIAGE_TIER2',
  CATALYST_TRIAGE_EMA = 'CATALYST_TRIAGE_EMA',
}
