export const LOGIN_STATUS = "LOGIN_STATUS";
export const Account_Identifier = "Account_Identifier";
export const User_Name = "User_Name";
export const CUSTOMER_DATA = "CUSTOMER_DATA";
export const QUERY_POSTCODE = "QUERY_POSTCODE";
export const SAVE_TENANCIES = "SAVE_TENANCIES";
export const SAVE_ASSOCIATED_ASSETS = "SAVE_ASSOCIATED_ASSETS";
export const SAVE_TENANCY_SUMMARY_LIST = "SAVE_TENANCY_SUMMARY_LIST";
export const SAVE_AVAILABILITY_RESPONSE = "SAVE_AVAILABILITY_RESPONSE";
export const UPDATE_FORM = "UPDATE_FORM";
export const CHANGE_REPAIR_PROPERTY_TRACK = "CHANGE_REPAIR_PROPERTY_TRACK";
export const SAVE_SELECTED_TENANCICY = "SAVE_SELECTED_TENANCICY";
export const SAVE_CUSTOMER_ID = "SAVE_CUSTOMER_ID";
export const APP_CONFIG_VALUE = "APP_CONFIG_VALUE";
export const APP_CONFIG_VANDALISM_VALUE = "APP_CONFIG_VANDALISM_VALUE";
export const SAVE_ASSET_ADDRESS = "SAVE_ASSET_ADDRESS";
export const SAVE_IS_ELEGIBILITY_REPAIR = "SAVE_IS_ELEGIBILITY_REPAIR";
export const SAVE_DEFAULT_ADDRESS = "SAVE_DEFAULT_ADDRESS";
export const FETCH_REPAIR_REASONS = "FETCH_REPAIR_REASONS";
export const FETCH_REPAIR_REASONS_IN_PROGRESS =
  "FETCH_REPAIR_REASONS_IN_PROGRESS";
export const USER_TYPED_REPAIR_REASONS = "USER_TYPED_REPAIR_REASONS";
export const FETCH_REPAIR_REASONS_SELECT = "FETCH_REPAIR_REASONS_SELECT";
export const SELECTED_REPAIR_REASON_OBJECT = "SELECTED_REPAIR_REASON_OBJECT";
export const SELECTED_REPAIR_REASON_DEFAULT_PRIORITY = "SELECTED_REPAIR_REASON_DEFAULT_PRIORITY";
export const WORK_ORDER_REPAIR = "WORK_ORDER_REPAIR";
export const ASSETBYID_RESPONSE = "ASSETBYID_RESPONSE";
export const CUSTOMER_DETAILS = "CUSTOMER_DETAILS";
export const SAVE_CUSTOMERS_PHONE_NUMBERS = "SAVE_CUSTOMERS_PHONE_NUMBERS";
export const SAVE_CUSTOMERID = "SAVE_CUSTOMERID";
export const SAVE_CONTRACTOR_NAME = "SAVE_CONTRACTOR_NAME";
export const SAVE_REFERENCE_NUMBER = "SAVE_REFERENCE_NUMBER";
export const SAVE_PREFERENCE_NUMBER = "SAVE_PREFERENCE_NUMBER";
export const SAVE_MAINTRESP = "SAVE_MAINTRESP";
export const SAVE_REPRESP = "SAVE_REPRESP";
export const SAVE_HEATINGCASE = "SAVE_HEATINGCASE";
export const USERCHOSEN_PROPERTY_ID = "USERCHOSEN_PROPERTY_ID";
export const USERCHOSEN_PROPERTY_TYPE = "USERCHOSEN_PROPERTY_TYPE";
export const USERCHOSEN_PROPERTY_ADDRESS = "USERCHOSEN_PROPERTY_ADDRESS";
export const USERCHOSEN_REPAIR_LOCATION = "USERCHOSEN_REPAIR_LOCATION";
export const USERCHOSEN_HIGH_LEVEL_DESCRIPTION =
  "USERCHOSEN_HIGH_LEVEL_DESCRIPTION";
export const USERCHOSEN_SUPPLEMENTARY_QUESTIONS =
  "USERCHOSEN_SUPPLEMENTARY_QUESTIONS";
export const USERCHOSEN_EMERGENCY_QUESTION = "USERCHOSEN_EMERGENCY_QUESTION";
export const USERCHOSEN_DUPLICATE_QUESTION = "USERCHOSEN_DUPLICATE_QUESTION";
export const USERCHOSEN_ADDITIONAL_DESCRIPTION =
  "USERCHOSEN_ADDITIONAL_DESCRIPTION";
export const USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT =
  "USERCHOSEN_ADDITIONAL_DESCRIPTION_COUNT";
export const USERCHOSEN_VANDALISM_QUESTION = "USERCHOSEN_VANDALISM_QUESTION";
export const USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION =
  "USERCHOSEN_VANDALISM_ADDITIONAL_INFORMATION";
export const USERCHOSEN_COVID_QUESTION = "USERCHOSEN_COVID_QUESTION";
export const USERCHOSEN_VULNERABILITY_QUESTION =
  "USERCHOSEN_VULNERABILITY_QUESTION";
export const USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION =
  "USERCHOSEN_VULNERABILITY_ADDITIONAL_INFORMATION";
export const USERCHOSEN_CONTACT_DETAILS = "USERCHOSEN_CONTACT_DETAILS";
export const USERCHOSEN_CONTACT_OPTION = "USERCHOSEN_CONTACT_OPTION";
export const USERCHOSEN_RELATION_DETAILS = "USERCHOSEN_RELATION_DETAILS";
export const USERCHOSEN_CONTACT_NAME = "USERCHOSEN_CONTACT_NAME";
export const USERCHOSEN_CONTACT_PHONE = "USERCHOSEN_CONTACT_PHONE";
export const USERCHOSEN_UPRN = "USERCHOSEN_UPRN";
export const USERCHOSEN_SOR = "USERCHOSEN_SOR";
export const USERCHOSEN_STATUS = "USERCHOSEN_STATUS";
export const USERCHOSEN_ELIGIBILITY_ERRORCODE =
  "USERCHOSEN_ELIGIBILITY_ERRORCODE";
export const REPAIRS_PROPERTY_LOAD = "REPAIRS_PROPERTY_LOAD";
export const REPAIRS_SEARCH = "REPAIRS_SEARCH";
export const REPAIRS_SEARCH_SUCCESS = "REPAIRS_SEARCH_SUCCESS";
export const PAGE_CHANGED = "PAGE_CHANGED";
export const RESULTS_PER_PAGE_CHANGED = "RESULTS_PER_PAGE_CHANGED";
export const REPAIR_STATUS_CHANGED = "REPAIR_STATUS_CHANGED";
export const GET_REPAIR_STATUS_OPTIONS_SUCCESS =
  "GET_REPAIR_STATUS_OPTIONS_SUCCESS";
export const DEFAULT_REPAIR_STATUS = "DEFAULT_REPAIR_STATUS";
export const GET_SORTABLE_FIELDS_SUCCESS = "GET_SORTABLE_FIELDS_SUCCESS";
export const SORT_ORDER_CHANGED = "SORT_ORDER_CHANGED";
export const GET_REPAIR_ID = "GET_REPAIR_ID";
export const REPAIR_SEARCH_RESET = "REPAIR_SEARCH_RESET";
export const SAVE_DETERMINED_CONTRCACTOR_DETAILS =
  "SAVE_DETERMINED_CONTRCACTOR_DETAILS";
export const SAVE_CONTRCACTOR_PAYLOAD = "SAVE_CONTRCACTOR_PAYLOAD";
export const USERCHOSEN_REPAIR_LOCATION_ID = "USERCHOSEN_REPAIR_LOCATION_ID";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGIN_TIME = "LOGIN_TIME";
export const INITIAL_LOGIN_TIME = "INITIAL_LOGIN_TIME";
export const REFRESS_TOKEN_EXPIRE_TIME = "REFRESS_TOKEN_EXPIRE_TIME";
export const ACCESS_TOKEN_EXPIRE_TIME = "ACCESS_TOKEN_EXPIRE_TIME";
export const SESSION_TIME_OUT_MESSAGE = "SESSION_TIME_OUT_MESSAGE";
export const UPDATE_PREV_CONFIRMED_STATE = "UPDATE_PREV_CONFIRMED_STATE";
export const UPDATE_ROUTE_HISTORY = "UPDATE_ROUTE_HISTORY";
export const UPDATE_USER_CHOICES_WITH_NEW_CHANGE =
  "UPDATE_USER_CHOICES_WITH_NEW_CHANGE";
export const REPAIR_HISTORY_DATA = "REPAIR_HISTORY_DATA";
export const Is_Edit_State = "Is_Edit_State";
export const USERCHOSEN_BANLANCE_TYPE = "USERCHOSEN_BANLANCE_TYPE";
export const USERCHOSEN_ACCOUNT_NUMBER = "USERCHOSEN_ACCOUNT_NUMBER";
export const USERCHOSEN_BALANCE = "USERCHOSEN_BALANCE";
export const USERCHOSEN_ACCOUNT_Type = "USERCHOSEN_ACCOUNT_Type";
export const USERCHOSEN_Other_BALANCE = "USERCHOSEN_Other_BALANCE";
export const USER_PROFILE_ETAG = "USER_PROFILE_ETAG";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const GET_CONTACTOR_DATA = "GET_CONTACTOR_DATA";
export const Allpay_Conformation_Message = "Allpay_Conformation_Message";
export const USERCHOSEN_ERROR_CODE = "USERCHOSEN_ERROR_CODE";
export const USERCHOSEN_ISELIGIBLE = "USERCHOSEN_ISELIGIBLE";
export const RESUME_DRAFT_REPAIR = "RESUME_DRAFT_REPAIR";
export const USER_REPAIR_WORKORDER = "USER_REPAIR_WORKORDER";
export const USER_RESUME_DATA = "USER_RESUME_DATA";
export const USER_RESUME_DRAFT_DATA_CACHE = "USER_RESUME_DRAFT_DATA_CACHE";
export const USERCHOSEN_ISREPORTER = "USERCHOSEN_ISREPORTER";
export const USERCHOSEN_ISERROR = "USERCHOSEN_ISERROR";
export const VANDALISM_RESPONSE = "VANDALISM_RESPONSE";
export const Is_Active_Tenancy = "Is_Active_Tenancy";
export const GET_ASSET_LIST = "GET_ASSET_LIST";
export const DEFAULT_ASSET_ID = "DEFAULT_ASSET_ID";
export const SEARCH_TEXT_COUNT_VALUE = "SEARCH_TEXT_COUNT_VALUE";
export const PREMISES_TYPE_LIST = "PREMISES_TYPE_LIST";
export const USERCHOSEN_TENANCY_GROUP = "USERCHOSEN_TENANCY_GROUP";
export const USERCHOSEN_ACCOUNT_List = "USERCHOSEN_ACCOUNT_List";
export const WORK_ORDER_Data = "WORK_ORDER_Data";
export const WORK_ORDER_Data_Original = "WORK_ORDER_Data_Original";
export const USER_PROFILE_PERMISSION = "USER_PROFILE_PERMISSION";
export const CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION =
  "CREATE_ENHANCED_HUB_REPAIR_JOURNEY_PERMISSION";
export const USER_PROFILE_CASE_CREATION_PERMISSION =
  "USER_PROFILE_CASE_CREATION_PERMISSION";
export const COMPONENT_INFO = "COMPONENT_INFO";
export const TRANSACTION_INFO = "TRANSACTION_INFO";
export const HIERARCHY_OF_PREMISES = "HIERARCHY_OF_PREMISES";
export const ASSET_DATA = "ASSET_DATA";
export const USERCHOSEN_SEARCHTYPE = "USERCHOSEN_SEARCHTYPE";
export const USERCHOSEN_SEARCHTEXT = "USERCHOSEN_SEARCHTEXT";
export const USERCHOSEN_BACKBUTTON = "USERCHOSEN_BACKBUTTON";
export const USERCHOSEN_RAISEDSINCE = "USERCHOSEN_RAISEDSINCE";
export const USERCHOSEN_RAISEDUPTO = "USERCHOSEN_RAISEDUPTO";
export const USERCHOSEN_CUSTOMER_ACCOUNT_List =
  "USERCHOSEN_CUSTOMER_ACCOUNT_List";
export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const ASSET_DATA_STATUS = "ASSET_DATA_STATUS";
export const WORK_ORDER_RESPONSE = "WORK_ORDER_RESPONSE";
export const ASSET_DATA_RESPONSE = "ASSET_DATA_RESPONSE";
export const LOCAL_AUTHORITY = "LOCAL_AUTHORITY";
export const KEY_CONTACT = "KEY_CONTACT";
export const CUSTOMER_ID = "CUSTOMER_ID";
export const USERCHOSEN_BACK_VALUE = "USERCHOSEN_BACK_VALUE";
export const USERCHOSEN_CURRENT_VALUE = "USERCHOSEN_CURRENT_VALUE";
export const USERCHOSEN_ALERT_VALUE = "USERCHOSEN_ALERT_VALUE";
export const CUSTOMER_PREFERENCE_DATA = "CUSTOMER_PREFERENCE_DATA";
export const ASSET_PROPERTY_ID = "ASSET_PROPERTY_ID";
export const SAVE_CONTRCACTOR_INTEGERATED = "SAVE_CONTRCACTOR_INTEGERATED";
export const SELECTED_SOR_CODE = "SELECTED_SOR_CODE";
export const SOR_ERROR_CODE = "SOR_ERROR_CODE";
export const SELECTED_SOR_VALUE = "SELECTED_SOR_VALUE";
export const USERCHOSEN_ASSET_HIERARCHY_360 = "USERCHOSEN_ASSET_HIERARCHY_360";
export const ASSET_REPAIR_CHECK = "ASSET_REPAIR_CHECK";
export const REPAIR_ADDRESS_DATA = "REPAIR_ADDRESS_DATA";
export const BACK_TO_REPAIR_ASSET = "BACK_TO_REPAIR_ASSET";
export const USERCHOSEN_ASSETVALUE = "USERCHOSEN_ASSETVALUE";
export const WORKORDER_LIST = "WORKORDER_LIST";
export const PROPERTY_LIST = "PROPERTY_LIST";
export const CASE_LIST = "CASE_LIST";
export const CASE_DATA = "CASE_DATA";
export const CASE_COUNTER = "CASE_COUNTER";
export const CASE_EDIT = "CASE_EDIT";
export const CASE_SELECTED_ANSWERS = "CASE_SELECTED_ANSWERS";
export const CLEAR_CASE_STATE = "CLEAR_CASE_STATE";
export const CASE_CREATION_QUESTIONS = "CASE_CREATION_QUESTIONS";
export const CASE_CREATION_MASTER_DATA = "CASE_CREATION_MASTER_DATA";
export const CASE_CREATION_ID = "CASE_CREATION_ID";
export const CASE_CREATION_ADDITINALINFO = "CASE_CREATION_ADDITINALINFO";
export const CASE_CREATION_DESCRIPTION = "CASE_CREATION_DESCRIPTION";
export const CASE_QUESTION_ANSWERS_DETAILS = "CASE_QUESTION_ANSWERS_DETAILS";
export const CASE_DESCRIPTION_DATA_INFO = "CASE_DESCRIPTION_DATA_INFO";
export const CASE_PROPERTY_TENANCY_GROUP = "CASE_PROPERTY_TENANCY_GROUP";
export const CASE_IDENTIFIER = "CASE_IDENTIFIER";
export const CASE_SUBTYPE = "CASE_SUBTYPE";
export const CASE_SUBTYPE_CATEGORY = "CASE_SUBTYPE_CATEGORY";
export const CASE_OWNING_TEAM = "CASE_OWNING_TEAM";
export const CASE_ELIGIBILITY_RESPONSE = "CASE_ELIGIBILITY_RESPONSE";
export const CASE_ADDITIONAL_CASE_FIELD = "CASE_ADDITIONAL_CASE_FIELD";
export const CASE_CATEGORY = "CASE_CATEGORY";
export const CASE_ASSET_TYPE = "CASE_ASSET_TYPE";
export const CASE_OWNER = "CASE_OWNER";
export const CASE_ASSET_IDENTIFIER = "CASE_ASSET_IDENTIFIER";
export const CASE_SELECTED_TENANCY = "CASE_SELECTED_TENANCY";
export const USERCHOSEN_ISSEARCH = "USERCHOSEN_ISSEARCH";
export const USERCHOSEN_ISRECALL = "USERCHOSEN_ISRECALL";
export const USERCHOSEN_RESUME_REPAIR = "USERCHOSEN_RESUME_REPAIR";
export const LOAD_DUPLICATE_REPAIR_PAGE = "LOAD_DUPLICATE_REPAIR_PAGE";
export const USERCHOSEN_DUPLICATE_ENTITY = "USERCHOSEN_DUPLICATE_ENTITY";
export const USERCHOSEN_SAVEEXIT = "USERCHOSEN_SAVEEXIT";
export const UCC_TENANCY_MODIFIED_DATA = "UCC_TENANCY_MODIFIED_DATA";
export const UCC_RENT_MODIFIED_DATA = "UCC_RENT_MODIFIED_DATA";
export const ASSETS_BY_ID_DATA = "ASSETS_BY_ID_DATA";
export const CREATE_ARREARS_RESPONSE = "CREATE_ARREARS_RESPONSE";
export const IS_CHECK_FOR_LANDLORD = "IS_CHECK_FOR_LANDLORD";
export const CATALYST_FLAG = "CATALYST_FLAG";
export const ASSET_CHECK_FOR_CATALYST = "ASSET_CHECK_FOR_CATALYST";
export const CUSTOMER_CHECK_FOR_CATALYST = "CUSTOMER_CHECK_FOR_CATALYST";
export const USERCHOSEN_REPORTER_ID = "USERCHOSEN_REPORTER_ID";
export const BACK_TO_CASE_ASSET = "BACK_TO_CASE_ASSET";
export const CASE_ASSET_ADDRESS = "CASE_ASSET_ADDRESS";
export const BACK_TO_CASE_SELECT_CUSTOMER = "BACK_TO_CASE_SELECT_CUSTOMER";
export const CONTACT_INFORMATION_VALUE = "CONTACT_INFORMATION_VALUE";
export const CASE_CUSTOMER_SEARCH = "CASE_CUSTOMER_SEARCH";
export const CASE_TENANCY_ID = "CASE_TENANCY_ID";
export const CASE_TENANCY_TYPE = "CASE_TENANCY_TYPE";
export const USERCHOSEN_TENANCYCOUNT = "USERCHOSEN_TENANCYCOUNT";
export const EDIT_BUTTON_CLICK = "EDIT_BUTTON_CLICK";
export const CASE_CREATED_BY = "CASE_CREATED_BY";
export const CASE_ASSET_CHECK = "CASE_ASSET_CHECK";
export const CASE_CUSTOMER_NAME = "CASE_CUSTOMER_NAME";
export const ELIGIBLE_SELECT_CUSTOMER = "ELIGIBLE_SELECT_CUSTOMER";
export const ASSOCIATED_ASSETS = "ASSOCIATED_ASSETS";
export const No_UNIT = "No_UNIT";
export const CUSTOMER_ADDITIONAL_ALERT_DATA = "CUSTOMER_ADDITIONAL_ALERT_DATA";
export const IS_CASE_ALLOWED = "IS_CASE_ALLOWED";
export const UPLOADED_MEDIA_ITEMS = "UPLOADED_MEDIA_ITEMS";
export const CLEAR_MEDIA_FILES = "CLEAR_MEDIA_FILES";
export const DELETE_MEDIA_ITEM = "DELETE_MEDIA_ITEM";
export const USERCHOSEN_DELETE_MEDIA = "USERCHOSEN_DELETE_MEDIA";
export const USERCHOSEN_UPLOADED_MEDIA = "USERCHOSEN_UPLOADED_MEDIA";
export const HAS_MEDIA_ATTACHED = "HAS_MEDIA_ATTACHED";
export const FROM_MEDIA_PAGE = "FROM_MEDIA_PAGE";
export const MEDIA_RESUME = "MEDIA_RESUME";
export const IS_CASE_MEDIA_ALLOWED = "IS_CASE_MEDIA_ALLOWED";
export const USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS =
  "USERCHOSEN_UPLOADED_MEDIA_IDENTIFIERS";
export const IS_CASE_MEDIA_CHECK = "IS_CASE_MEDIA_CHECK";
export const REFERRAL_FOR = "REFERRAL_FOR";
export const REFERRAl_REASON = "REFERRAl_REASON";
export const REFERRAL_INCLUSION_TEAM = "REFERRAL_INCLUSION_TEAM";
export const REFERRAL_OTHER_DETAILS = "REFERRAL_OTHER_DETAILS";
export const CLEAR_REFERRAL_INFO = "CLEAR_REFERRAL_INFO";
export const COMING_FROM_REFERRAL = "COMING_FROM_REFERRAL";
export const REFERRAL_ADDITIONAL_DETAILS = "REFERRAL_ADDITIONAL_DETAILS";
export const REFERRAL_ACCOUNT_BALANCE = "REFERRAL_ACCOUNT_BALANCE";
export const COMING_FROM_CASE = "COMING_FROM_CASE";
export const IS_REPAIR_MEDIA_ALLOWED = "IS_REPAIR_MEDIA_ALLOWED";
export const IS_REPAIR_COVID_ALLOWED = "IS_REPAIR_COVID_ALLOWED";
export const IS_REPAIR_MEDIA_RESUME = "IS_REPAIR_MEDIA_RESUME";
export const IS_CATALYST_CUSTOMER = "IS_CATALYST_CUSTOMER";
export const CATALYST_STATUS = "CATALYST_STATUS";
export const CASE_ACTION_DETAIL = "CASE_ACTION_DETAIL";
export const IS_CASEMANAGEMENT_TOGGLE = "IS_CASEMANAGEMENT_TOGGLE";
export const IS_UPDATEANACTION_TOGGLE = "IS_UPDATEANACTION_TOGGLE";
export const REPAIR_TYPE = "REPAIR_TYPE";
export const SURVEY_REASON = "SURVEY_REASON";
export const USERCHOSEN_ISSURVEY = "USERCHOSEN_ISSURVEY";
export const USERCHOSEN_NOTIFYALL = "USERCHOSEN_NOTIFYALL";
export const SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION =
  "SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION";
export const RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY =
  "RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY";
export const CASE_CREATION_FILTERED_QUESTION =
  "CASE_CREATION_FILTERED_QUESTION";
export const CASE_CREATION_QUESTIONIDS_ANSWERS =
  "CASE_CREATION_QUESTIONIDS_ANSWERS";
export const WORKORDER_CHRONOLOGY_TAB = "WORKORDER_CHRONOLOGY_TAB";
export const SAVE_EXTERNAL_WORKORDER_CONTRACTORS =
  "SAVE_EXTERNAL_WORKORDER_CONTRACTORS";
export const SAVE_AVAILABILITY_METADATA_RESPONSE =
  "SAVE_AVAILABILITY_METADATA_RESPONSE";
export const WO_REDIRECT_FROM_EMAIL = "WO_REDIRECT_FROM_EMAIL";
export const IS_P360_REPAIRS_MAINTAINENCE = "IS_P360_REPAIRS_MAINTAINENCE";
export const DEMOBILIZED_CONTRACTOR_SITES = "DEMOBILIZED_CONTRACTOR_SITES";
export const USER_SELECTED_TENANCY_GROUP = "USER_SELECTED_TENANCY_GROUP";
export const ASSET_BACK_COUNTER = "ASSET_BACK_COUNTER";
export const ASSET_ARRAY = "ASSET_ARRAY";
export const RELEASE_ASSET_ARRAY = "RELEASE_ASSET_ARRAY";
export const REMOVE_ASSET_ARRAY = "REMOVE_ASSET_ARRAY";
export const FINISH_JOURNEY = "FINISH_JOURNEY";
export const COMING_FROM_CASE_LIST = "COMING_FROM_CASE_LIST";
export const SERVICE_CARD_DATA = "SERVICE_CARD_DATA";
export const GAS_CARD_DATA = "GAS_CARD_DATA";
export const PRIORITY_CODE = "PRIORITY_CODE";
export const USER_EMAIL = "USER_EMAIL"