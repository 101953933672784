import { CHANGE_REPAIR_PROPERTY_TRACK, UPDATE_FORM, FETCH_REPAIR_REASONS, FETCH_REPAIR_REASONS_IN_PROGRESS, USER_TYPED_REPAIR_REASONS, FETCH_REPAIR_REASONS_SELECT, SELECTED_REPAIR_REASON_OBJECT, SELECTED_REPAIR_REASON_DEFAULT_PRIORITY, ASSETBYID_RESPONSE, SELECTED_SOR_CODE, SOR_ERROR_CODE, SELECTED_SOR_VALUE, SURVEY_REASON,SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION,RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY,PRIORITY_CODE} from "../actionTypes";

export const sorErrorCode = (payload: any) => ({
  type: SOR_ERROR_CODE,
  payload,
});
export const selectedSorCode = (payload: any) => ({
  type: SELECTED_SOR_CODE,
  payload,
});
export const updateDashboardForm = (payload: any) => ({
  type: UPDATE_FORM,
  payload,
});

export const changeComponentTrack = (payload: any) => ({
  type: CHANGE_REPAIR_PROPERTY_TRACK,
  payload,
});


export const fetchRepairReasons = (payload: any) => ({
  type: FETCH_REPAIR_REASONS,
  payload,
});

export const fetchRepairReasonsInProgress = (payload: any) => ({
  type: FETCH_REPAIR_REASONS_IN_PROGRESS,
  payload,
});

export const fetchRepairReasonsSelect = (payload: any) => ({
  type: FETCH_REPAIR_REASONS_SELECT,
  payload,
});


export const userTypedRepairReasons = (payload: any) => ({
  type: USER_TYPED_REPAIR_REASONS,
  payload,
});
export const userTypedSurveyReason = (payload: any) => ({
  type: SURVEY_REASON,
  payload,
});

/* export const userChosenRepairReason = (payload: any) => ({
  type: USER_CHOSEN_REPAIR_REASON,
  payload,
}); */

export const selectedRepairReasonObject = (payload: any) => ({
  type: SELECTED_REPAIR_REASON_OBJECT,
  payload,
});

export const selectedRepairReasonDefaultPriority = (payload: any) => ({
  type: SELECTED_REPAIR_REASON_DEFAULT_PRIORITY,
  payload,
});

export const assetByIdDataObject = (payload: any) => ({
  type: ASSETBYID_RESPONSE,
  payload,
});

export const selectedRepairReasonPriorityOverride = (payload: any) => ({
  type: SELECTED_REPAIR_REASON_PRIORITY_DETERMINATION,
  payload,
});
export const resumeRepairReasonPriorityUpdatedToEmergency = (payload: any) => ({
  type: RESUME_REPAIR_REASON_PRIORITY_UPDATED_T0_EMERGENCY,
  payload,
});

export const priorityCode = (payload: any) => ({
  type: PRIORITY_CODE,
  payload,
});
