import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { trackEvent } from "../../appInsights/TelemetryService";
import { useSelector, useDispatch } from "react-redux";
import Tenancies from '../../models/Customer/Tenancies';
import AccountDetails from '../../models/Accounts/AccountDetails';
import { BalanceType } from '../../models/Accounts/BalanceType';
import { AccountTypeList, getAccountType } from '../../models/Accounts/AccountType';
import { formatDateWithFromCalendar, isNullOrUndefined } from '../../utils/formatting';
import * as api from "../../api";
import { clearReferralInformation, saveReferralAccountBalance } from "../../ducks/redux/actions/FinancialReferral";
import { userChosenAccountNumber } from "../../ducks/redux/actions/userChoices";
import { PermissionsList } from "../../models/Permissions";

interface AccountCardProps extends RouteComponentProps<any> {
}
const AccountCard: React.FC<AccountCardProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerResponse = useSelector(
        (state: any) => state.addresses?.saveCustomerDetails
    );
    const customerAccountData = useSelector(
        (state: any) => state.userChoicesReducer?.customerAccountData
    );
   
    const assetDetails = useSelector(
        (state: any) =>
          state?.dashboardReducer?.formState?.assetByIdDataObject
      );
         
    const isActiveTenancy = useSelector(
        (state: any) => state?.userChoicesReducer?.isActiveTenancy
    );
    const chosentenancyGroup = (useSelector(
        (state: any) => state.userChoicesReducer?.tenancyGroup)
    );
    const customerData = useSelector(
        (state: any) => state?.userChoicesReducer?.customersData
    );
    const [accMsg, setAccMsg] = useState("");
    const array: AccountDetails[] = [];
    const [accLen, setAccLen] = useState(0);
    const [accdet, setaccountdetails] = useState(array);
    const [dropDown, setDropDown] = useState("none");
    const [referralToggle, setReferralToggle] = useState("true");
    const [referralButton, setReferralButton] = useState(false)
    const catalystCustomer = useSelector(
        (state: any) => state?.userChoicesReducer?.landlordCheck
    );

    const getPermissionsData = useSelector(
        (state: any) => state?.userChoicesReducer?.userProfilePermissions
      );

      useEffect(()=>{
        (async () => {
            try {
                const ReferralToggle = await api.GetAppConfigToggleValue("IsP360CatalystFinancialReferral");
                setReferralToggle(ReferralToggle.value)
            }catch(e:any){
                props.history.push("/GenericError");   
            }
                
        })()

        const showReferral = getPermissionsData?.indexOf(
            PermissionsList.create_customer360_financial_inclusion_referral
          );
          if (showReferral !== -1) {
            setReferralButton(true);
          } else {
            setReferralButton(false);
          }

    },[]);


    useEffect(() => {
        (async () => {
            trackEvent("AccountCard");
            callgetAccountByCustomerApi();
        })();
    }, [assetDetails]);

    const accountMsg = () => {
        let tempError;
        if (isActiveTenancy) {
            tempError = t("CP036b").replace("@address", "Active");
        }
        else {
            tempError = t("CP036b").replace("@address", "Former");
        }
        tempError = tempError.replace("@tenGroup", chosentenancyGroup);
        setAccMsg(tempError);
    }

    const callgetAccountByCustomerApi = () => {
        (async () => {
            try {
                const accountCustomerResponse = customerAccountData;
                const tenanSummaries = customerResponse?.tenanciesSummaries.map(
                    (ts: any) => (ts)
                );
                //only id from tenancySummary Array
                const tenanciesPremisesIds: Tenancies[] = tenanSummaries.map((ts: any) => ts.tenancySummary.premisesIds)
                    .flat();
                let objFinal: any;
                let payRef;
                tenanSummaries?.map(async (assetid: any) => {
                    assetid?.tenancySummary?.premisesIds?.map(async (id: any) => {
                        if (id === assetDetails?.id) {
                            objFinal = assetid?.tenancySummary;
                        }
                    })
                })

                payRef = objFinal?.paymentReference;
                if (payRef != undefined && payRef != null)
                    getAccountList(payRef, accountCustomerResponse);
            }
            catch (e) {
                console.error(e);
                const timerId = setTimeout(() => {
                    props.history.push("/GenericError");
                }, 1000);
            }
        })()
    }

    const getAccountList = (accountid: string, accountResponse: any) => {
        const accountDetailsList: AccountDetails[] = [];
        let orderAccountDetailsList: AccountDetails[] = [];

        accountResponse?.forEach((accountBalance: any) => {
            const accountDetails: AccountDetails = new AccountDetails();

            if (accountBalance.identifiers['pay-ref'].replace(/[^0-9]/g, '') == accountid) {
                accountDetails.LastArrearsAction = accountBalance["last-arrears-action"]?.desccription;
                if (accountBalance['account-type'] === AccountTypeList.REN) {
                    // Preference of showing type
                    accountDetails.DisplayNumber = 1;
                    accountDetails.AccountType = getAccountType(AccountTypeList.REN, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref'];
                }
                else if (accountBalance['account-type'] === AccountTypeList.FD5) {
                    accountDetails.DisplayNumber = 2;
                    accountDetails.AccountType = getAccountType(AccountTypeList.FD5, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                else if (accountBalance['account-type'] === AccountTypeList.MJW) {
                    accountDetails.DisplayNumber = 3;
                    accountDetails.AccountType = getAccountType(AccountTypeList.MJW, accountBalance['tenancy-type']);
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                else if (accountBalance['account-type'] === AccountTypeList.GRD) {
                    accountDetails.DisplayNumber = 4;
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                    accountDetails.AccountType = getAccountType(AccountTypeList.GRD, accountBalance['tenancy-type']);
                }
                else if (accountBalance['account-type'] === AccountTypeList.DEP) {
                    accountDetails.DisplayNumber = 5;
                    accountDetails.AccountType = getAccountType(AccountTypeList.DEP, accountBalance['tenancy-type']);;
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                } else if (accountBalance['account-type'] === AccountTypeList.HOM) {
                    accountDetails.DisplayNumber = 6;
                    accountDetails.AccountType = getAccountType(AccountTypeList.HOM, accountBalance['tenancy-type']);;
                    accountDetails.Payref = accountBalance.identifiers['pay-ref']
                }
                accountDetails.AccountNumber = accountBalance['account-identifier'];
                accountDetails.lastTransactionBalance = accountBalance['last-transaction']?.amount?.gross?.value != null ?
                    accountBalance['last-transaction']?.amount?.gross?.value : 0.00;
                accountDetails.lastTransactionDate = accountBalance['last-transaction']?.dates['created-date'];
                accountBalance['balance'].forEach((balance: any) => {
                    if (balance['balance-type'] == BalanceType.Livebalanced) {
                        let liveBalanced = balance['monetary-amount']['value'];
                        const accountBalanceValue = parseInt(liveBalanced);
                        accountDetails.LiveBalanceType = accountBalanceValue != null ? accountBalanceValue > 0 ? "In arrears" : "In credit" : "";
                        let number = liveBalanced != null ? Math.abs((liveBalanced.toString().replace(/,/g, ''))) : 0.00;
                        accountDetails.LiveBalance = number.toFixed(2);
                        dispatch(saveReferralAccountBalance(accountDetails.LiveBalance? "£" + accountDetails.LiveBalance: ''));
                        if (liveBalanced == 0) {
                            accountDetails.LiveBalanceType = "";
                        }
                        accountDetailsList.push(accountDetails);
                    }
                })
            }

        })
        orderAccountDetailsList = accountDetailsList.filter(x => x.DisplayNumber !== 0);
        setAccLen(orderAccountDetailsList?.length);
        setaccountdetails(orderAccountDetailsList.sort((a, b) => {
            return a.DisplayNumber - b.DisplayNumber;
        }).slice(0, 2));
        accountMsg();
    }

    const showAccountList = () => {
        props.history.push("/AccountResult");
    };

    const dropDownFunction = () => {
        if (dropDown === "none")
            setDropDown("block");
        else
            setDropDown("none");
    }

    const handleFinancialReferral = () => {
        if (accLen === 0){
            dispatch(saveReferralAccountBalance(''));
            dispatch(userChosenAccountNumber(''));
        }
        dispatch(clearReferralInformation({}))
        props.history.push("/CustomerConsent");
     }   

    return (
        <div className="relative pb-8 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4 global-txt">
            <div className="py-2 px-4 border-b border-grey">
                <div className="flex flex-row d-flex bd-highlight items-center mt-2">
                    <i className="fas fa-bullseye text-teal text-2xl mr-6 bd-highlight"></i>
                    <h2 className="my-0 text-purple-100 bd-highlight">{t("account")}</h2>
                    { ((!catalystCustomer && referralToggle === "true")|| referralToggle === "false") && referralButton && customerData?.statuses[0]?.value && <div className="menu-nav ms-auto bd-highlight">
                        <div className="dropdown-container">
                            <div className="three-dots" data-testid="dots_menu" onClick={dropDownFunction} ></div>
                            <div className="dropdown px-2 dropdown-margin py-2" style={{ display: dropDown }}>
                                <a data-testid="view_trans" onClick={handleFinancialReferral} className="text-black text-base no-underline global-txt"><div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">{t("Financial_FI001a")}</div></a>
                            </div>
                            </div>
                     </div>}

                </div>
            </div>

            <div className="mt-2 py-2 px-4">
                <div className="text-dark">
                    <div className="pr-4 mb-4"> {t("CP036a")} {accLen} {accMsg}
                    </div>
                    <div className="pr-4 mb-2"></div>
                </div>
            </div>

            {!accdet?.length ? (
                <></>
            ) : (
                accdet?.map(e => (
                    <div className="px-4">
                        <div className="border border-grey rounded-lg mb-4">
                            <div className="bg-peach px-4 py-2 rounded-t-lg">
                                <div className="flex flex-row justify-between items-center">
                                    <div className="my-0 text-lg">

                                        <strong>{t("pay_ref")}{":"}
                                            <span className="ml-1">{e?.Payref}</span>
                                        </strong></div>
                                </div>
                            </div>

                            <div className="px-4 py-2 flex justify-between border-b border-grey">
                                <div className="pr-4">{t("type")}
                                </div>
                                <div className="text-right">{e?.AccountType}
                                </div>
                            </div>

                            <div className="px-4 py-2 flex justify-between border-b border-grey">
                                <div className="pr-4">{t("balance")}
                                </div>
                                <div className="text-right"> £{e?.LiveBalance} {e?.LiveBalanceType}
                                </div>
                            </div>

                            <div className="px-4 py-2 flex justify-between border-b border-grey">
                                <div className="pr-4">{t("last_pay_date")}
                                </div>
                                <div className="text-right">
                                    {!(e?.lastTransactionDate) ? '-' : (formatDateWithFromCalendar(e?.lastTransactionDate))}{" "}
                                </div>
                            </div>

                            <div className="px-4 py-2 flex justify-between border-b border-grey">
                                <div className="pr-4">{t("last_arrears_action")}
                                </div>
                                <div className="text-right"> {isNullOrUndefined(e?.LastArrearsAction) ? '-' : e?.LastArrearsAction}
                                </div>
                            </div>

                        </div>
                    </div>
                ))
            )
            }
            {accdet?.length > 0 ? (
                <div className="w-full py-4 flex justify-between absolute bottom-0 px-4">
                    <div>
                        <a onClick={showAccountList} data-testId="work_order" className="text-teal no-underline hover:underline cursor-pointer" >{t("view_accounts")} &gt;</a>
                    </div>
                </div>
            ) : null}

        </div>
    )
};
export default AccountCard;