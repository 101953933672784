import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedSorCode,
  sorErrorCode,
  userTypedSurveyReason,
} from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  userChosenSurveyReason,
  userChosenAdditionalDescriptionCOUNT,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  userChosenAdditionalDescription,
  userChosenEmergencyQuestion
} from "../../ducks/redux/actions/userChoices";
import * as api from "../../api";
import { useTranslation } from "react-i18next";
import{SurveyTypes} from "../../models/shared/Enum";
import { RouteComponentProps } from "react-router-dom";
import { getDeterminedContractorDetails } from "../../ducks/redux/actions/getAddress";
import moment from "moment";
import Loader from "react-loader-spinner";

export interface SurveyReasonProps extends RouteComponentProps<any> {
  suggestions: string[];
  question:(a: boolean) => void;
  determinedContractor:string;
  sorCodeValue:string,
  setButtonDisabled: (a: boolean) => void;
  locatioAlert: string[];
  getMandatoryData: (args: any) => void;
  getAdditionalData: (args: any) => void;
  getSurveyReason:(args: any) => void;
}
const SurveyReason: React.FC<SurveyReasonProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let suggestionList;
  const maxLengthLimit = "188";
  let referenceDataresponsed;
  const minLengthLimit = "0";
  const ADDITIONAL_INFO_TEXT_LIMIT = 188;
  const format = /[$^*_\{};><*&!]+/;
  const array: any[] = [];
  const [surveyReasonInfo, setSurveyReason] = useState();
  const [additionalInfo, setAdditionData] = useState("");
  const [surveyReasonList, setSurveyReasonList] = useState(array);
  const [radiovalue, setRadioValue] = useState("No");
  const [characterCount, setCharacterCount] = useState(maxLengthLimit);
  const [count, setCount] = useState("0");
  const helpTextValue = count + "/" + characterCount;
  const priorityKey = ["","Mutual Exchange","Estate Management Survey","Disrepair","Keysafe Installation","Complaint","Technical Inspection","Right to Improve","Leaseholder Improvement Request"];
  const contractor=["PGM","CPS"];
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  enum RepairCauseIds {
    "Complaint"=3,
    "Technical Inspection"=5,
     "Disrepair"=6,
     "Right to Improve"=7,
     "Mutual Exchange"=8,
     "Leaseholder Improvement Request"=9,
     "Estate Management Survey"=10,
     "Keysafe Installation"=11,
     "Void Survey"=12
  }
 
  const extractedReasons: string[] = [];
  const [fetchedRepairReasonsData, setData] = useState(extractedReasons);
  let EmergencyQuestion;
  
  const assetStatusVal=useSelector(
    (state: any) => state?.userChoicesReducer.assetDataStatus 
  )

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const userMaintResp = useSelector((state: any) => state.addresses.maintresp);
    
  const userChosenEmergencyQuestionval = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
  const userChosenHighLevelAdditionalBackButton = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenAdditionalDescription
  );
  const TypeSurveyReason = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedSurveyReasons
  );

  const [isRecallValue, setIsRecallValue] = useState(false);

  const RepairLocationId = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const GetuserTypedSurveyReasons = useSelector(
    (state: any) => state.dashboardReducer.formState.userTypedRepairReasons
  );
  const getContractorname = useSelector(
    (state: any) => state.addresses.contractorname
  );
  const userChosenSurveyReasonVal = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  useEffect(() => {
    if (TypeSurveyReason) {
      setSurveyReason(TypeSurveyReason);
    }

    if (userChosenHighLevelAdditionalBackButton) {
      setAdditionData(userChosenHighLevelAdditionalBackButton);
    }
    if (TypeSurveyReason) {
      setSurveyReason(TypeSurveyReason);
    }
    if (userChosenEmergencyQuestionval === "Yes") {
      setRadioValue("Yes");
      dispatch(userChosenEmergencyQuestion("Yes"));
    }
    else{
      setRadioValue("No");
      dispatch(userChosenEmergencyQuestion("No"));   
    } 
  }, []);

  
  useEffect(() => {
    (async function () {
      try {
        const surveyReasons:string ='survey-reasons'
        referenceDataresponsed = await api.getServeyReferenceData(
          surveyReasons   
        ); 
        
       let keyValuePairs: any = "";
        if (referenceDataresponsed) {
          keyValuePairs=referenceDataresponsed[0]?.namespace?.keyValues;
          setSurveyReasonList(keyValuePairs);
        } 
      } catch (e) {
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  }, []);


  let fieldsArray: any[] = [];
  let arrayObject = Object.entries(surveyReasonList);
  arrayObject?.map((eachLocation, key) => {
    fieldsArray.push(
      <option
        key={eachLocation[0]}
        className="block mt-1 width-css mt-1 text-black global-txt"
        value={eachLocation[1]}
      >
        {eachLocation[1]}
      </option>
    );
  });

  const onChange = (e: any) => {
    if (format.test(e.target.value)) {
      props.setButtonDisabled(false);
      return true;
    } else {
      e.preventDefault();
    }    
    const textValue = e.target.value.trim()
    if (textValue.length > 0) {
      if (e.target.value.length < maxLengthLimit + 1) {
        e.target.value.length > ADDITIONAL_INFO_TEXT_LIMIT
          ? setCount(maxLengthLimit)
          : setCount(e.target.value.length);
      } else setCount(maxLengthLimit);
    }
    else {
      setCount("0")
    }
    setCharacterCount(maxLengthLimit);
    setAdditionData(e.target.value.substr(minLengthLimit, maxLengthLimit));
    dispatch(
      userChosenAdditionalDescription(
        e.target.value.substr(minLengthLimit, maxLengthLimit)
      )
    );
    dispatch(userChosenAdditionalDescriptionCOUNT(e.target.value.length));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
  };
  useEffect(() => {
    props.getMandatoryData(surveyReasonInfo);
    props.setButtonDisabled(false);
    props.getSurveyReason(surveyReasonInfo);
  }, [surveyReasonInfo]);

  useEffect(() => {
    props.setButtonDisabled(false);
    props.getAdditionalData(additionalInfo);
  }, [additionalInfo]);

  

  useEffect(() => {
    if (radiovalue == "Yes") {
      props.question(true)
    } else {
      props.question(false)
    }
  }, [radiovalue]);


  const updateSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try{
      const raisedDate = new Date();
      const repairTime = raisedDate.getHours().toString();
      let region = assetDetails?.region;
      let uprn = assetDetails.uprn;
      const raisedFormattedDate = moment(new Date()).format("YYYY-MM-DD");
      let maintenanceResponsibility: string = userMaintResp;
    let repaiResponsiblity:string = "survey" ;
      const borough = assetDetails?.borough ? assetDetails?.borough?.trim() : "";
      const determinedContractor = await api.getDeterminedContractor(
        '00000000-0000-0000-0000-000000000000',
      e.target.value === "No" ?'NA':'EME',
        region,
        borough,
        props.sorCodeValue === '' ? userChosenSOR : props.sorCodeValue,
        repairTime,
        uprn,
        raisedFormattedDate,
        RepairCauseIds[
        userChosenSurveyReasonVal as keyof typeof RepairCauseIds
        ],
        maintenanceResponsibility,
        repaiResponsiblity
      );
      dispatch(getDeterminedContractorDetails(determinedContractor));
    }
    catch(e){
      console.log(e);
    }
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    setRadioValue(e.target.value);
    dispatch(userChosenEmergencyQuestion(e.target.value));
    if (e.target.value === "No") {
      dispatch(userChosenEmergencyQuestion("No"));
    }
  };

  const Emergency=()=>{
    EmergencyQuestion = (

      <div id="vulQues-div1"> 
      <div className="h5" id="repreason-div1">
      <h2 className="text-purple-100 text-2xl mb-2 global-txt">
        {t("survey_emergency_heading")}
      </h2>
      </div>
        <RadioGroup
          id="vulQues-radiogroup"
          className="mb-2"
          key={radiovalue}
          onChange={updateSelection}
          defaultValue={radiovalue}
          value={radiovalue}
        >
          <fieldset><legend>
          <FormControlLabel
            id={"vulQues-label1" + 1}
            value="Yes"
            control={
              <Radio id="vulQues-radio2" key="radioYes" className="pl-0" />
            }
            label="Yes"
            placeholder="Yes"
            className="global-txt"
          />
          </legend></fieldset>
         
      
          <fieldset><legend>
          <FormControlLabel
            id="vulQues-label1"
            value="No" 
            data-testid="noRadio"
            control={<Radio id="vulQues-radio1" key="radioNo" className="pl-0" />}
            label="No"
            placeholder="No"
            className="global-txt"
          />
          </legend></fieldset>
        </RadioGroup>
      </div>
      )
  }
  
if(RepairLocationId !==SurveyTypes.Void_Survey && RepairLocationId !==SurveyTypes.Keysafe_Installation){
    Emergency();
}


  const suggestionClickHandler = (e: any) => {
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    props.setButtonDisabled(true);
    if (EditState === true) {

      dispatch(isEditState(false));
      
    }
    setSurveyReason(e.target.value);
    if (e.target.value != "") {
      setData(props.suggestions);
    } else {
      props.setButtonDisabled(true);
    }
    dispatch(userChosenSurveyReason(e.target.value));
    dispatch(userTypedSurveyReason(e.target.value));
  };
  
  if (props.locatioAlert.length > 0) {
    suggestionList = (
      <div
        data-testid="alert-warning"
        className="error mb-8"
        role="alert"
        placeholder="alert-message"
      >
       <div className="w-full px-2 py-2 bg-yellow flex items-center">
      <div className="px-4 pr-8">
        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
      </div>
      <div className="flex items-center">
        <p
          data-testid="locatioAlert_0"
          className="w-full text-left my-2 font-AvantGardeGothic-Md"
        >
          {props.locatioAlert}
        </p>
      </div>
    </div>
      </div>
    );
  }

  return (
    <>
      
      {RepairLocationId=='Technical Inspection' &&
      <>
      <div className="h5" id="repreason-div1">
        <h2 className="text-purple-100 text-2xl mb-2 my-4 global-txt">
          {t("survey_reason_heading")}
        </h2>
      </div>
      {fetchedRepairReasonsData && (
         <select
         id="reploc-sel"
         data-testid="dropDownLocation"
         name="dropDownLocation"
         placeholder="dropDownLocation"
         value={surveyReasonInfo}
         aria-label="reploc-sel"
         disabled={isRecallValue}
         onChange={suggestionClickHandler}
         className="block width-css mt-1 text-black global-txt rounded border-grey"
       >
         <option
           value="No option selected"
           className="block mt-1 width-css mt-8 text-black global-txt"
           disabled={isRecallValue}
         >
           Select
         </option>
         {fieldsArray}
       </select>
      )}
      </>
}
      <div
          id="repAddDet-div3"
          className="global-txt mt-1"
          data-testid="repairdetails"
        >
          <div className="h5" id="repreason-div1">
        <h2 className="text-purple-100 text-2xl mb-2 my-4 global-txt">
          {t("survey_additional_heading")}
        </h2>
      </div>
          <label className="w-full global-txt">
            <TextField aria-invalid="true"
              margin="normal"
              className="textarea-additional-details global-txt pb-4 border-grey"
              helperText={helpTextValue}
              data-testid="Repair_Additional_Details_Placeholder"
              onChange={(e) => onChange(e)}
              id="outlined-textarea"
              value={additionalInfo}
              placeholder={t("Additional_Details_CPR026a")}
              InputProps={{
                inputComponent: 'textarea', style: { padding: 0, height: 200 }
              }}
              size="medium"
              error={count >= maxLengthLimit} />
          </label>
        </div>
        {EmergencyQuestion}
        
      {suggestionList}  
      
    </>
  );
};



export default SurveyReason;
