import {
  Container,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../Button/Button";
import Loader from "react-loader-spinner";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import {
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  isEditState,
  resumeDraftRepair,
  backToRepairAsset,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { BackValue } from "../../models/shared/Enum";
interface VandalismQuestionProps extends RouteComponentProps<any> {}
const VandalismQuestion: React.FC<VandalismQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [eligible, setEligible] = useState("none");
  const [radioValue, setRadioValue] = useState("No");
  const [crimeRefValue, setCrimeRefValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttondisable, setButtonDisable] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const format = /[><*&!]+/;
  const userChosenVandalismQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const [isRecallValue, setIsRecallValue] = useState(false);
  const IsRecall = useSelector(
    (state: any) => state.userChoicesReducer?.IsRecall
  );
  
  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);
  const priorityCodeValue = useSelector(
    (state: any) => state.dashboardReducer.formState?.priorityCode
  );

  useEffect(() => {
    window.scrollTo(0,0);
    if(IsRecall)
    {
      setIsRecallValue(true)
    }
    else
    {
      setIsRecallValue(false)
    }
       
    if (props.history.action === "POP") {
      setRadioValue(userChosenVandalismQuestionBackButton);
      setCrimeRefValue(userChosenVandalismAdditionalInformationBackButton);

      if (
        userChosenVandalismQuestionBackButton === "Yes" &&
        userChosenVandalismAdditionalInformationBackButton
      ) {
        setEligible("block");
      }
    }
  }, []);

  useEffect(() => {
    if (userChosenVandalismQuestionBackButton) {
      setRadioValue(userChosenVandalismQuestionBackButton);
      setCrimeRefValue(userChosenVandalismAdditionalInformationBackButton);

      if (
        userChosenVandalismQuestionBackButton === "Yes" &&
        userChosenVandalismAdditionalInformationBackButton
      ) {
        setEligible("block");
      }
    }
  }, []);
  const onChange = (e: any) => {
  
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }
    setCrimeRefValue(e.target.value);
    dispatch(userChosenVandalismAdditionalInformation(e.target.value));
  };

  // update work order
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const submitForm = async () => {
    try {
      setIsLoading(true);
      setButtonDisable(true);
      workOrderObject.Priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      if (radioValue == "Yes") {
        woRequestParameters.isVandalism=true;
        workOrderObject.isVandalism=true;
        woRequestParameters.crimeReferenceNumber =
          userChosenVandalismAdditionalInformationBackButton;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else if (radioValue == "No") {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.isVandalism=false;
        workOrderObject.isVandalism=false;
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }
       await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
     
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setButtonDisable(false);
    }
  };
  const backButtonHandler = () => {
    dispatch(userChosenVandalismAdditionalInformation("No"));
    dispatch(userChosenVandalismQuestion("No"));
    if(isRepairMedia == "true") {
      props.history.push("/RepairUploadMedia");
    } 
    else
    props.history.push("/RepairAdditionalDetails");
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    //Implement Save Draft and Exit functionalities here...
    submitForm();
    setCloseAfterSaving(true);
    dispatch(backToRepairAsset(false))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`)

    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { vandalismQuestion, crimeReferenceNumber } = userResumeDraftDataCache;
      if (vandalismQuestion !== radioValue || crimeReferenceNumber !== crimeRefValue) {
        dispatch(resumeDraftRepair(false)); 
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    submitForm();
    const timerId = setTimeout(() => {
      // console.log("Will be called after 2 seconds");
      props.history.push("/VulnerabilityQuestion");
    }, 1000);
  };
  const handleRadioChange = (event: any) => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    setRadioValue(event.target.value);
    dispatch(userChosenVandalismQuestion(event.target.value));
    if (event.target.value === "Yes") {
      setEligible("block");
    } else {
      setEligible("none");
      setCrimeRefValue("");
      dispatch(userChosenVandalismAdditionalInformation("No"));
    }
  };

  return (
    <div id="vanQues-container" role="main" className="container sm:px-2 ctn-fht">
      <div id="vanQues-back-div" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >		
          <p id="vanQues-p" className="text-teal text-xl mt-4 mb-8"><a className="back-btn" onClick={backButtonHandler}>		
          <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>		
          </div> 
      <div
        id="vanQues-main-div"  
        className="container question ml-sm-5 pl-sm-5 repair-text"
      >
        <div id="vanQues-div1"   className="h5 my-2">
        <h2 id="vanQues-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("VandalismQuestion")}</h2> 

        <fieldset>
            <legend>
            </legend>
          <RadioGroup
            id="vanQues-radiogroup" 
            role="radiogroup"
            name="radio-buttons-group"
            defaultValue={radioValue}
            className="margin-radipgroup text-black mb-2 margin-lt2"
            data-testid="crimereradioValue"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              id="vanQues-label1"
              value="Yes"
              placeholder="Yes"
             disabled={isRecallValue}
              data-testid="yesRadio"
              control={<Radio id="vanQues-radio1" />}
              label="Yes"
              className="my-1 global-txt"
            />
            <div
              id="vanQues-div2"
              className="padding-10"
              style={{ display: eligible }}
            >
              <input
                id="vanQues-text"
                className="wid-input global-txt border-teal"
                type="text"
                value={crimeRefValue}
                data-testid="crimereferencebox"
                name="crimereferencebox"
                disabled={isRecallValue}
                maxLength={20}
                placeholder="Crime reference number (optional)"
                onChange={(e) => onChange(e)}
                aria-label="Crime reference number (optional)"
              ></input>
              <br />{" "}
            </div>
            <FormControlLabel
              id="vanQues-label2"
              value="No" 
              data-testid="noRadio"
              disabled={isRecallValue}
              control={<Radio id="vanQues-radio2" />}
              label="No"
              placeholder="No"
              className="global-txt"
            />
          </RadioGroup>
          </fieldset>
        </div>
      </div>
      <div
        id="vanQues-main-btn"
        className="container flex justify-between items-center pb-4 md:pb-8"
      >
        <ExitModalAndSave
          id="vanQues-exitmodal"
          exit={exitButtonHandler}
          saveDraftAndExit={saveDraftAndExit}
          closeAfterSaving={closeAfterSaving}
        />
        <Button
          id="btn_vandalism"
          disabled={buttondisable}
          onClick={callPageHandler}
          className="button-class"
        >
          {t("continue")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>  
      </div>
      <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>
    </div>
  );
};

export default VandalismQuestion;
