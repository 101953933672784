import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../../api";
import { formatDate, getUpdateOwnerName, isNullOrUndefined } from "../../../utils/formatting";
import { RouteComponentProps } from "react-router-dom";
import { PremisesType } from "../../../models/Customer/Premises";
import {
  backToCaseAsset,
  caseData,
  userChosentenancyGroup,
} from "../../../ducks/redux/actions/userChoices";
import { BackValue } from "../../../models/shared/Enum";
import { USERCHOSEN_PROPERTY_ID } from "../../../ducks/redux/actionTypes";
import { comingFromCase } from "../../../ducks/redux/actions/FinancialReferral";

const Loader = React.lazy(() => import("react-loader-spinner"));

interface CaseCardProps extends RouteComponentProps<any> {}

const CaseCard: React.FC<CaseCardProps> = (props) => {
  let status: any = {
    Active: 1,
    Resolved: 2,
    Cancelled: 3,
  };
  const caseCardData: any = [];
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [caseSearchData, setCaseSearchData] = useState(caseCardData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const isActiveTenancy = useSelector(
    (state: any) => state?.userChoicesReducer?.isActiveTenancy
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const customerId = useSelector(
    (state: any) => state?.userChoicesReducer?.custommerID
  );
  let assetStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataStatus
  );
  let selectedTenanacy = useSelector(
    (state: any) => state?.addresses?.selectedTenancy
  );
  const eligibleSelectCustomer = useSelector(
    (state: any) => state?.caseCreationReducer?.eligibleSelectCustomer
  );
  const assetDataResponse = useSelector(
    (state: any) => state?.userChoicesReducer?.assetDataResponse
  );
  const customerResponse = useSelector(
    (state: any) => state?.addresses?.saveCustomerDetails
  );
  
  const caseTenancyIdentifier = useSelector(
    (state: any) => state?.caseCreationReducer?.caseTenancyId
  );

  const associatedAssets = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenAssociatedAssets
  );
  const [titleMessage, setTitleMessage] = useState("");
  var arrayErrorCode: string[] = [];
  let [errorMsgArray, seterrorMsgArray] = useState(arrayErrorCode);

  const [dropDown, setDropDown] = useState("none");

  const callcase360 = (caseSelectedData: any) => {
    dispatch(caseData(caseSelectedData));
    props.history.push("/Case360View");
  };

  const GetTitleMsg = (count: any) => {
    if (backValue == BackValue.Customer360View) {
      let message = t("CP048a");
      setTitleMessage(message.replace("{0}", count));
    } else if (backValue == BackValue.Asset360View) {
      let message = t("CP048c");
      setTitleMessage(message.replace("{0}", count));
    }
  };

 

  const getSearchApi = async (assetIDsAll: string, tenancyIdentifier: string, customerIdentifier: string) => {
    try {
      setIsLoading(true);
      let customerIdValue;
      if (backValue == BackValue.Customer360View) {
        customerIdValue = customerId;
      } else if (backValue == BackValue.Asset360View) {
        customerIdValue = "";
      }
      const casequeryParams = {
        searchText: "",
        orderBy: "createdDate desc",
        status: "",
        limit: 2,
        skip: 0,
        defaultassocitaedAsset: assetIDsAll,
        CustomerIdentifiers: customerIdentifier,
        tenancyIdentifiers: tenancyIdentifier
      };
      const caseSearchResponse = await api.getCasesList(casequeryParams);

      // === Sorting cases according to status ===
      const sortedData = caseSearchResponse.cases;
      sortedData.sort((a, b) => status[a.status] - status[b.status]);

      setCaseSearchData(sortedData);
      GetTitleMsg(caseSearchResponse["total-count"]);
      noCaseDataMsg();
    } catch (e) {
      props.history.push("/genericerror");
    } finally {
      setIsLoading(false);
    }
  };
  const dropDownFunction = () => {
    if (dropDown === "none") setDropDown("block");
    else setDropDown("none");
  };
  const callCaseHandler = async () => {
    dispatch(comingFromCase(true))
    backToCaseAsset(false);
    if (backValue == BackValue.Customer360View) {
      if (associatedAssets?.length === 1) {
        props.history.push("/CaseAsset");
      } else if (associatedAssets?.length > 1) {
        props.history.push("/SelectTenancy");
      }
    } else if (backValue == BackValue.Asset360View) {
      if (selectedTenanacy == "Property" && eligibleSelectCustomer == true) {
        dispatch({
          type: USERCHOSEN_PROPERTY_ID,
          payload: assetDataResponse.id,
        });
        props.history.push("/SelectCustomer");
        //multiple customer
      } else {
        let tenancyGroup = "ALL";
        if (
          !isNullOrUndefined(customerResponse) &&
          assetDataResponse?.assetType == "Property"
        ) {
          let id = customerResponse["customer-identifier"];
          if (id != undefined) {
            const tenancyResponse = await api.getTenancies(id);
            tenancyGroup = tenancyResponse[0].tenancyGroup;
          }
        }
        if (assetDataResponse?.assetType === "Property") {
          dispatch(userChosentenancyGroup(tenancyGroup));
          props.history.push("/CaseType");
        } else if (assetDataResponse?.assetType !== "Property") {
          dispatch(userChosentenancyGroup("All"));
          props.history.push("/CaseType");
          //communal
        }
      }
    }
  };
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (backValue == BackValue.Asset360View) {
          if(assetDetails) {
            const assetIDsAll = "assetIdentifiers=" + assetDetails?.id;
            getSearchApi(assetIDsAll,"", "");
          }
        } else if (backValue == BackValue.Customer360View) {
          if(caseTenancyIdentifier) {
            const assetIDsAll = "";
            getSearchApi(assetIDsAll, caseTenancyIdentifier, customerId);
          }
        }
      } catch (e) {
        setIsLoading(false);
        history.push("/genericerror");
      }
    })();
  }, [assetDetails, caseTenancyIdentifier]);

  function noCaseDataMsg() {
    var tempArray = t("CP048b").split("?");
    seterrorMsgArray(tempArray);
  }
      
  const callCaseListPage = () => {
      props.history.push("/CaseList");
  };


  return (
    <div role="main">
      <div className="relative pb-16 border border-grey bg-white shadow-lg rounded-lg cust-card mt-4 px-4">
        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        ) : (
          <div data-testid="casesContainer">
            <div className="py-2 border-b border-grey mt-2 mb-3">
              <div className="flex flex-row d-flex bd-highlight items-center work-ht">
                <i className="fa fas fa-briefcase text-teal text-2xl mr-6 bd-highlight"></i>
                <h2 className="my-0 text-purple-100 bd-highlight">
                  {t("CP048")}
                </h2>
                {(isActiveTenancy && backValue == BackValue.Customer360View) ||
                (BackValue.Asset360View &&
                  ((selectedTenanacy === PremisesType.Property &&
                    (assetStatus === "OCCP" || assetStatus === "VOID")) ||
                    selectedTenanacy === PremisesType.Block ||
                    selectedTenanacy === PremisesType.DividedBlock||
                    selectedTenanacy === PremisesType.Estate)) ? (
                  <div className="menu-nav ms-auto bd-highlight">
                    <div className="dropdown-container">
                      <div
                        className="three-dots"
                        data-bs-toggle="dropdown"
                        data-testid="dropFunction"
                        onClick={dropDownFunction}
                      ></div>

                      <div
                        className="dropdown-ast px-2 dropdown-menu show mr-3"
                        style={{ display: dropDown }}
                      >
                        <a
                          data-testid="casepage"
                          onClick={callCaseHandler}
                          className="text-black text-base no-underline global-txt"
                        >
                          <div className="py-2 cursor-pointer text-black text-base no-underline dropdown-item">
                            {t("CP104")}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {caseSearchData?.length > 0 && (
              <div className="mb-4 global-txt mt-4">
                <div className="text-dark flex justify-start">
                  <div className="pr-4">
                    <strong>{titleMessage}</strong>{" "}
                  </div>
                </div>
              </div>
            )}
            {!caseSearchData.length ? (
              <div>
                <div className="w-full test-base my-2 global-txt mt-3">
                  {errorMsgArray[0]}
                  <ul className="disc-list">
                    <li> {errorMsgArray[1]}</li>
                    <li> {errorMsgArray[2]}</li>
                    <li> {errorMsgArray[3]}</li>
                  </ul>
                </div>
              </div>
            ) : (
              caseSearchData.slice(0, 2).map((el: any, key: any) => (
                <div key={key} className="border border-grey rounded-lg mt-4">
                  <div className="py-2 mb-1 bg-peach global-txt rounded-t-lg">
                    <div className="flex justify-between border-b border-grey py-1 px-4">
                      <div>
                        <a
                          data-testid={
                            "CaseNumberLink" + el?.caseReferenceNumber
                          }
                          className="text-dark cursor-pointer global-txt"
                          onClick={() => callcase360(el)}
                        >
                          <strong>{el.caseReferenceNumber}</strong>
                        </a>
                      </div>
                      <div className="text-right global-txt fw-bold">
                      {el.caseSubTypeCategory}
                      </div>
                    </div>
                  </div>

                  <div className="py-2 mb-1 border-tint global-txt">
                    <div className="flex justify-between border-b border-grey py-1 mb-2 px-4">
                      <div>{t("CP082")} </div>
                      <div>{formatDate(el.createdDate)}</div>
                    </div>                    
                    <div className="flex justify-between border-b border-grey py-1 mb-2 px-4">
                          <div>{t("request_category")}</div>
                          <div className="text-right global-txt">
                          {`${isNullOrUndefined(el.caseCategory) ? t("no_request_category") : el.caseCategory}`}
                          </div>
                      </div>
                    <div className="flex justify-between border-b border-grey py-1 mb-2 px-4">
                      <div>{t("CP085")}</div>
                      <div className="text-right global-txt">
                           {getUpdateOwnerName(el?.owner?.name)}
                      </div>
                    </div>
                    <div className="flex justify-between px-4">
                      <div>{t("CP086")}</div>
                      <div> {el.status}</div>
                    </div>
                  </div>
                </div>
              ))
            )}
            {caseSearchData?.length > 0 && (
                  <div className="w-full py-4 flex justify-between absolute bottom-0">
                          <div>
                              <a
                                  href=""
                                  data-testId="CaseListLink"
                                  className="global-txt text-teal no-underline hover:underline"
                                  onClick={callCaseListPage}
                              >
                                  {t("CP079")} &gt;
                    </a>
                          </div>
                      </div>
           )} 
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseCard;
