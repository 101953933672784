import React, { useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../../Button/Button";
import {
  CASE_SELECTED_ANSWERS,
  CLEAR_CASE_STATE,
} from "../../../ducks/redux/actionTypes";
import { CaseOwnerMatrix } from "../../../models/Case/CaseOwnerMatrix";
import * as api from "../../../api";
import { ComplexCaseTypes } from "../../../models/Case/ComplexCase";
import { ComplexCaseowningTeams } from "../../../models/Case/ComplexCaseowningTeams";
import { BackValue } from "../../../models/shared/Enum";
import { capitalizeName } from "../../../utils/formatting";
import { comingFromCase, finishJourney } from "../../../ducks/redux/actions/FinancialReferral";
const Loader = React.lazy(() => import("react-loader-spinner"));

interface CaseFinalConfirmationProps extends RouteComponentProps<any> {}
const CaseFinalConfirmation: React.FC<CaseFinalConfirmationProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = useState(false);
  const [assigneeDetails, setAssigneeDetails] = useState("");

  const caseOwnerName = useSelector(
    (state: any) => state.caseCreationReducer?.caseOwnerName
  );


  const caseTypeDescription = useSelector(
    (state: any) => state.caseCreationReducer.caseCreationDescription
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const caseSubType = useSelector(
    (state: any) => state.caseCreationReducer.caseSubType
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );

  enum AssigneeName {
    NeighbourhoodManager = "Your Neighbourhood Manager, ",
    IncomesOfficer = "Your Incomes officer, ",
    NeighbourhoodManagerTeam = "Your Neighbourhood Manager"
  };

  enum CaseOwnerName {
    NM = "NM",
    IncomesOfficer = "Incomes officer",
    MyPeabodyHubComplaints = "MyPeabody Hub Complaints"
  }

  enum OwnerTeamTitle {
    Title = "The "
  }


  const mappedOwnerName = new Map();
  mappedOwnerName.set('Estate Services Enquiries',CaseOwnerMatrix["Estate Services Enquiries"])
    .set('Homeowner Sales Services', CaseOwnerMatrix["Homeowner Sales Services"])
    .set('NM', CaseOwnerMatrix["NM"])
    .set('Homeownership Team',CaseOwnerMatrix["Homeownership Team"])
    .set('Incomes officer',CaseOwnerMatrix["Incomes officer"])
    .set('Leasehold Compliance Team',CaseOwnerMatrix["Leasehold Compliance Team"])
    .set('Leasehold Team', CaseOwnerMatrix["Leasehold Team"])
    .set('New Homes (Development)',CaseOwnerMatrix["New Homes (Development)"])
    .set('Private Rent Team',CaseOwnerMatrix["Private Rent Team"])
    .set('Property Accounts',CaseOwnerMatrix["Property Accounts"])
    .set('Rehousing Services',CaseOwnerMatrix["Rehousing Services"])
    .set('Rehousing and Lettings',CaseOwnerMatrix["Rehousing and Lettings"])
    .set('Resale And Staircasing Team',CaseOwnerMatrix["Resale And Staircasing Team"])
    .set('SAR Team',CaseOwnerMatrix["SAR Team"])
    .set('Specialist Case Management',CaseOwnerMatrix["Specialist Case Management"])
    .set('Allocations and Lettings',CaseOwnerMatrix["Allocations and Lettings"])
    .set('Business Support',CaseOwnerMatrix["Business Support"])
    .set('Property Accounts Team',CaseOwnerMatrix["Property Accounts Team"])
    .set('MyPeabody Hub Complaints',CaseOwnerMatrix["MyPeabody Hub Complaints"])
    .set('Env Services South',CaseOwnerMatrix["Env Services South"]);


    
  const callPageHandler = () => {
    dispatch(comingFromCase(false))
    dispatch(finishJourney(true));
    dispatch({ type: CLEAR_CASE_STATE });
    dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    if (backValue == BackValue.Customer360View) {
      props.history.push("/Customer360View");
    } else if (backValue == BackValue.Asset360View) {
      props.history.push("/Asset360View");
    } else {
      props.history.push("/Case360View");
    }
  };

  
  const fetchCaseAssetAssociates = async () => {
    try {
      setIsLoading(true);
      const response = await api.getCaseAssetAssociate(assetDetails?.id);
      
      if (caseOwnerName === CaseOwnerName.NM) {
        if (response) {
          if (response.associates.neighbourhoodManager === null) {
            let Nmdetails = AssigneeName.NeighbourhoodManagerTeam;
            setAssigneeDetails(Nmdetails);
          } else {
            
            let NmFirstName = response?.associates?.neighbourhoodManager?.person?.name?.givenName;
            let NeighbourhoodManagerFirstName = capitalizeName(NmFirstName);
            let NmSecondName = response?.associates?.neighbourhoodManager?.person?.name?.surname;
            let NeighbourhoodManagerSecondName = capitalizeName(NmSecondName);
            let Nmdetails = AssigneeName.NeighbourhoodManager + ' ' + NeighbourhoodManagerFirstName + ' ' + NeighbourhoodManagerSecondName;
            setAssigneeDetails(Nmdetails);
          }
        }
      } else {
        if (response) {
          let incoOfficerFirstName = response?.associates?.incomesOfficer?.person?.name?.givenName;
          let incomesOfficerFirstName = capitalizeName(incoOfficerFirstName);
          let incoOfficerSecondName = response?.associates?.incomesOfficer?.person?.name?.surname;
          let incomesOfficerSecondName = capitalizeName(incoOfficerSecondName);
          let incomesOfficerDetails = AssigneeName.IncomesOfficer + ' ' + incomesOfficerFirstName + ' ' + incomesOfficerSecondName;
          setAssigneeDetails(incomesOfficerDetails);
        }
      }
    } catch (e: any) {
      props.history.push("/genericerror");
      dispatch({ type: CLEAR_CASE_STATE });
      dispatch({ type: CASE_SELECTED_ANSWERS, payload: [] });
    } finally {
      setIsLoading(false);
    }

  };

  useEffect(() => {
    window.scrollTo(0,0);
    if (caseOwnerName === CaseOwnerName.NM || caseOwnerName === CaseOwnerName.IncomesOfficer) {
      fetchCaseAssetAssociates();
    } 
    else if (caseOwnerName === CaseOwnerName.MyPeabodyHubComplaints) {
        if(caseSubType === ComplexCaseTypes.Complaint){
          let complaintsTeam = ComplexCaseowningTeams.PeabodyComplaintsTeam;
          setAssigneeDetails(complaintsTeam);
        }else{
          let serviceRecoveryTeam =  ComplexCaseowningTeams.PeabodyServiceRecoveryTeam;
          setAssigneeDetails(serviceRecoveryTeam);
        }
    } 
    else {
      let teamDetails =  mappedOwnerName.get(caseOwnerName) ; 
      setAssigneeDetails(teamDetails);
    }
  }, []);

  return (
    <div className="container sm:px-2 ctn-fht">
      <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />
        }
      >
        {isLoading ? (
          <Loader type="ThreeDots" color="#00BFFF" height={300} width={50} />
        ) : (
          <>
            <div
              id="appCon-main-div"
              className="w-full  lg:px-0 py-2 h5 my-4 repair-text"
            >
              <h1 className="text-purple-100 text-2xl mb-2 global-txt">
                {t("OPC015a")}
              </h1>

              <div className="border border-grey rounded-lg px-6 mb-6 md:mb-8 section-brder global-txt pl-4">
                <p>
                  {t("OPC017")}
                  <br></br> <strong>{caseTypeDescription}</strong>
                </p>
                <p data-testid="assigneedetail">
                  {t("OPC018")}
                  <br></br> <strong>{assigneeDetails}</strong>
                </p>
              </div>
            </div>

            <div className="ml-4 global-txt">
              <h2>{t("OPC019")}</h2>              
              <p>{t("OPC020b")}</p>
              
            </div>

            <div
              id="custDetails-main-btn"
              className="flex justify-between items-center pb-4 md:pb-8"
            >
              <div></div>
              <Button
                id="btn_finishrepFinalConfirm"
                onClick={callPageHandler}
                data-testid="repFinalConfirm-btn2"
                className="button-class fin-btn-margin items-end"
              >
                {t("Case_finish_button")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
            </div>
          </>
        )}
      </Suspense>
    </div>
  );
};
export default CaseFinalConfirmation;
