import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import ExitModalContent from "../../Modal/ExitModalContent";
import Button from "../../../Button/Button";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { FinancialInclusionTeam, clearReferralInformation, comingFromReferralPage, financialReferralFor, finishJourney } from "../../../ducks/redux/actions/FinancialReferral";


interface FinancialInclusion extends RouteComponentProps<any> {
  
}

const CustomerConsent: React.FC<FinancialInclusion> = (props:any) => {
  const RadioOption1 = [{"option":"Resident","id":"Tenant"}, {"option":"Other member of household","id":"Other"} ]  
  const RadioOption2 = [{"option":"Yes","id":"Yes"}, {"option":"No","id":"No"}]
  
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [referralError, setReferralError] = useState("");
  const data = useSelector((state:any) => state.financialReferralReducer.financialReferralData)

  const [referralFor, setReferralFor] = useState(data?.referralFor || "");
  const [financialInclusionTeam, setFinancialInclusionTeam] = useState(data?.FinancialInclusionTeam || "");

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const exitButtonHandler = () => {
    dispatch(clearReferralInformation({}))
    props.history.push('/Customer360View');
  }
  const onBackHandler = () => {
    dispatch(finishJourney(true));
    props.history.push('/Customer360View');
  }

  const onContinueHandler = () => {
    if(!referralFor && !financialInclusionTeam){
      setError("Financial_FI017")
      setReferralError("Financial_FI018")
    }else if(!referralFor) {
      setError("Financial_FI017")
    }
    else if (!financialInclusionTeam){
      setReferralError("Financial_FI018")
    }else {
      dispatch(comingFromReferralPage(true))
      props.history.push('/referralreason')
    }
  }

  const handleRadioChange = (e:any) => {
    
    if(e.target.name === "Tenant" || e.target.name === "Other"){
      setError("")
      setReferralFor(e.target.value)
      dispatch(financialReferralFor(e.target.value))
    }else{
      setReferralError("")
      setFinancialInclusionTeam(e.target.value)
      dispatch(FinancialInclusionTeam(e.target.value))
    }
  }

  
  return (
    <div className="container sm:mx-1 ctn-fht">
        {/* Back Button */}
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0 px-2" >
        <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
          <a data-testid="custBackBtn" className="back-btn" onClick={onBackHandler} >
            <i className="fas fa-angle-left"></i>
            <span>{t("back")}</span>
          </a>
        </p>
      </div>

        {/* Main Page Content */}
        <div className="sm:mx-1 px-2">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
                 {t("Financial_FI001b")} 
              </h1>
            </div> 
        </div>
        <RadioGroup
            data-testid="radioGroup"
            className="question-btn mb-4"
            value={referralFor}
            onChange={handleRadioChange}
          >
            {RadioOption1?.map((data:any)=>{
            return(<FormControlLabel
                id={data.id}
                key={data.id}
                value={data.option}
                data-testid="formControl"
                className="mb-2 global-txt card-text margin-lt"
                
                control={<Radio id={`covidQues-radio1`} placeholder="radioLabel"
                name={data.id} />}
                label={data.option}
            />)})}
          </RadioGroup>

       
        <div className="sm:mx-1 px-2">
            <div className="h5" id="repreason-div1">
              <h1 className="mt-2 text-purple-100 text-2xl mb-2 global-txt">
                 {t("Financial_FI001c")} 
              </h1>
            </div> 
        </div>
        <RadioGroup
            data-testid="radioGroup"
            className="question-btn"
            value={financialInclusionTeam}
            onChange={handleRadioChange}
          >
            {RadioOption2?.map((data:any)=>{
            return(<FormControlLabel
                id={data.id}
                key={data.id}
                value={data.option}
                data-testid="formControl"
                className="mb-2 global-txt card-text margin-lt"
                control={<Radio  id={`covidQues-radio1`} placeholder="radioLabel"
                name={data.id}/>}
                label={data.option}
            />)})}
          </RadioGroup>
            
      
        {/* Yellow Alert */}
          <div    
                data-testid="alert-warning"
                className="error px-2 py-2"
                role="alert"
                placeholder="alert-message"
              >
               {  error && <div className="w-full px-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                       {t(error)}
                    </p>
                    
                  </div>
                </div>}

                {referralError && <div className="w-full px-2 mb-2 py-2 bg-yellow flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center">
                    <p
                      data-testid="locatioAlert_0"
                      className="w-full text-left my-2 font-AvantGardeGothic-Md"
                    >
                       {t(referralError)}
                    </p>
                    
                  </div>
                </div>}
         </div>

       
        {/* Exit and Continue SMS button */}
            <div
              className="flex justify-between items-center pb-4 md:pb-8 px-2"
              id="sms-btn"
            >
              <ExitModalContent
                id="sms-exitmodal"
                exit={exitButtonHandler}
                btn_msg="Return to my referral"
              />
              <Button
                id="SMS"
                type="button"
                data-testid="SMS-Button"
                name="Send"
                onClick={onContinueHandler}
                placeholder="send-sms"
                value="Send"
                className="button-class"
              >
                {t("Continue")}
                <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
              </Button>
        </div>
       
    </div>    

  )
};
export default CustomerConsent;