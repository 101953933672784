import React, { useState, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import {
  CASE_COUNTER,
} from "../../../ducks/redux/actionTypes";
import {
    clearMediaFiles,
    deleteMediaItem,
    userChosenDeletedMedia,
    userChosenUploadedMedia,
    hasMediaAttached,
    fromMediaUpload,
    isMediaResume,
    userChosenContactDetails,
    userChosenContactName,
    userChosenContactPhone,
    userChosenRelationDetails,
    userChosenUploadedMediaIdentifiers,
  } from "../../../ducks/redux/actions/userChoices";

import Button from "../../../Button/Button";
import UploadMediaRow  from "./UploadMediaRow";
import classes from "./styles.module.css";
import styles from "./spinner.module.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps, useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateMediaSize } from "./mediaConfigure";
import { TableCell, TableRow, TableHead } from "@material-ui/core";
import * as ReactBootstrap from "react-bootstrap";
import * as api from "../../../api";


import Compress from "browser-image-compression";
import { BackValue } from "../../../models/shared/Enum";
import { CONTACT_INFORMATION_VALUE } from "../../../ducks/redux/actionTypes";
import ExitModalContent from "../../Modal/ExitModalContent";
const Loader = React.lazy(() => import("react-loader-spinner"));


const allowedExtensions = process.env.REACT_APP_ALLOW_EXTENSION;
const videoSize = process.env.REACT_APP_VIDEO_SIZE as string;

interface mediaFilesType {
    name: string;
    size?: number;
    fileType: string;
    documentIdentifier: string;
}

interface UploadMediaProps extends RouteComponentProps<any> {}

const UploadMedia: React.FC<UploadMediaProps> = (props) => {
    const history = useHistory();
    const location :any = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const previousPageCounter = location?.state?.previousPageCounter;
  
    const [isLoading, setIsLoading] = useState(false);
  
    const [mediaFiles, setMediaFiles] = useState<mediaFilesType[]>([]);
    const [disableUploadButton, setDisableUploadButton] = useState(false);
    const [fileTooLarge, setFileTooLarge] = useState(false);
    const [wrongFileType, setWrongFileType] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [error, setError] = useState("");
    let mediaLength = 0;
  
    let maxFileSize = +videoSize;

  const allowedConfigExtensions = allowedExtensions?.replace("[", "");
  const allowedExtensionsReplace =allowedConfigExtensions? allowedConfigExtensions.split("'").join("") : "";
  const allowFileType = allowedExtensionsReplace?.replace("]", "");
    const inputRef = React.useRef<HTMLInputElement>(null);

      const userChosenUploadedMediaValue = useSelector(
        (state: any) => state?.userChoicesReducer?.userChosenUploadedMedia
      );

      const assetDetails = useSelector(
        (state: any) =>
          state?.dashboardReducer?.formState?.assetByIdDataObject
      );
    
      const customerIdentifier = useSelector(
        (state: any) => state.addresses?.customerid
      );
    
      const isResume = useSelector(
        (state: any) => state.userChoicesReducer?.isMediaResumed
      );
    
      const mediaIds = useSelector(
        (state: any) => state.userChoicesReducer?.mediaItems
    );

    let selectedTenacyType = useSelector(
      (state: any) => state?.addresses?.selectedTenancy
    );
    const backValue = useSelector(
      (state: any) => state?.userChoicesReducer?.backValue
    );
  
    const isEdit = useSelector(
      (state: any) => state?.caseCreationReducer?.isCaseEdit
    );  

    const questionInfoData = useSelector(
      (state: any) => state.caseCreationReducer?.caseCreationFilteredQuestion
    );

    useEffect(() => {
        userChosenUploadedMediaValue?.map((mediaFile: any) => {
          if (mediaFile) {
            setMediaFiles(userChosenUploadedMediaValue);
          }
        });
        mediaLength = mediaIds?.length;
      }, [mediaIds?.length]);
    
      useEffect(() => {
        mediaLength = mediaIds?.length;
        if (mediaFiles?.length == 5) {
          setDisableUploadButton(true);
        }
      }, [mediaFiles]); 

    useEffect(() => {
        if (isResume) {
          dispatch(isMediaResume(false));
        }
      }, []);
    
    const backButtonHandler = () => {
      let questionInfoDataCopy = structuredClone(questionInfoData);
      let count = questionInfoDataCopy.filter((x:any) => x["isEnabled"] ===  "true").length;
      dispatch({ type: CASE_COUNTER, payload: count-1 });
      props.history.push("/CaseQuestionnaire");
    };
    
    const exitButtonHandler = () => {
      dispatch(clearMediaFiles([]));
      props.history.push("/LandingView");
    }

    const onContinueHandler = () => {
      let documentIdentifierslist: any[] = [];
        mediaFiles.forEach((media: any) => {
          documentIdentifierslist.push(media.documentIdentifier)
        })
        dispatch(userChosenUploadedMediaIdentifiers(documentIdentifierslist))
        dispatch(fromMediaUpload(false));
        // === Validating media has attached ===
        if (mediaIds?.length > 0) {
          dispatch(hasMediaAttached("Yes"));
        } else {
          dispatch(hasMediaAttached("No"));
        }

        if (backValue == BackValue.Customer360View  ) {
          if(isEdit) {
          dispatch(userChosenContactDetails(""))
          dispatch(userChosenContactName(""))
          dispatch(userChosenContactPhone(""))
          dispatch(userChosenRelationDetails(""))
           }
          history.push("/ContactDetails");
        }
        else if ((backValue == BackValue.Asset360View || backValue == BackValue.Case360View) && selectedTenacyType == 'Property') {
          dispatch({ type: CONTACT_INFORMATION_VALUE })
          props.history.push("/ContactDetails");
        }
        else if ((backValue == BackValue.Asset360View || backValue == BackValue.Case360View) && selectedTenacyType != 'Property') {
          if(isEdit){
            dispatch({ type: CONTACT_INFORMATION_VALUE});
          }
          props.history.push("/CaseContactInformation");
        }

  
      };

    function encodeImageFileAsURL(element: any) {
        setError("");
    
        let file = element[0];
        const reader = new FileReader();
        const imgExt = file.type.split("/");
        if (
          (imgExt[1] === "jpeg" || imgExt[1] === "png" || imgExt[1] === "jpg") &&
          imgExt[0] === "image"
        ) {
          // Use webworker for faster compression with
          const options = {
            maxSizeMB: file.size / 2,
            useWebWorker: true,
          };
    
          /* istanbul ignore next */
          Compress(file, options)
            .then((compressedBlob) => {
              // Convert the blob to file
              setIsLoading(true);
              reader.readAsDataURL(compressedBlob);
              reader.onloadend = () => {
                processMediaFile(element, file.name, reader.result, file.type);
              };
            })
            .catch(() => {});
        } else {
          reader.onloadend = () => {
            processMediaFile(element, file.name, reader.result, file.type);
          };
          if (file) {
            reader.readAsDataURL(file);
          }
        }
    }
    

    /* istanbul ignore next */
  const processMediaFile = async (
    uploadMediaArray: any,
    name: any,
    image: any,
    fileType: any
  ) => {
    const size = validateMediaSize(fileType);

    const validExt = fileType.split("/");
    if (validExt[1] === "quicktime") {
      validExt[1] = "mov";
    } else if (validExt[1] === "x-matroska") {
      validExt[1] = "mkv";
    } else if (validExt[1] === "jpeg") {
      validExt[1] = "jpg";
    }



   

    if (!allowedExtensions?.includes(validExt[1])) {
      setFileTooLarge(false);
      setWrongFileType(true);
    } else {
      if (uploadMediaArray[0].size > size) {
        setFileTooLarge(true);
        setWrongFileType(false);
      }else{

      setFileTooLarge(false);
      setWrongFileType(false);

      // === Upload media files on server ===

      const metadata: { key: string, value: any }[] = [
        {
          key: "filename",
          value: uploadMediaArray[0].name,
        },
        {
          key: "content-type",
          value: fileType,
        }
      ];

      if (customerIdentifier && customerIdentifier !== "" && customerIdentifier !== null && customerIdentifier !== undefined) {
        metadata.push({
          key: "customer-identifier",
          value: customerIdentifier,
        });
      }

      if (assetDetails?.id && assetDetails?.id !== "" && assetDetails?.id !== undefined && assetDetails?.id !== null) {
        metadata.push({
          key: "asset-identifier",
          value: assetDetails?.id,
        });
      } 

      const mediaParam = {
        DocumentType: "CASES",
        Media: image?.substr(image?.indexOf(",") + 1),
        Metadata: metadata,
      };

      try {
        setIsLoading(true);
        const response = await api.postMediaFile(
          mediaParam
        );
        var documentIdentifier = '';
        if(response?.documentIdentifier){
          documentIdentifier = response?.documentIdentifier;
        }
        dispatch(userChosenUploadedMedia({ name, fileType, documentIdentifier }));
        setMediaFiles((currentMediaFiles: mediaFilesType[]) => {
          return currentMediaFiles.concat([{ name, fileType , documentIdentifier}]);
        });

      } catch (e) {
        setError("Media_SSR011c");
      } finally {
        setIsLoading(false);
      }
    }

    }
  };


    const handleMediaFilesChange = (event: any) => {
        event.preventDefault();
        if (event.target.files && event.target.files[0]) {
          encodeImageFileAsURL(event.target.files);
        }
    };

    const deleteMediaFile = async (id: number) => {
        setFileTooLarge(false);
        setWrongFileType(false);
        setError("");
    
        try {
          setIsLoading(true);
          await api.deleteMediaFiles(userChosenUploadedMediaValue[id]?.documentIdentifier);
          dispatch(deleteMediaItem(mediaIds[id]));
          // === Delete media files from database ===
          const updatedMedia = mediaFiles.filter(
            (mediaFile: any, index: number) => {
              return index != id;
            }
          );
          setMediaFiles(updatedMedia);
          dispatch(userChosenDeletedMedia(updatedMedia));
          setDisableUploadButton(false);
        } catch (e) {
          setError("Media_SSR011b");
        } finally {
          setIsLoading(false);
        }
    };

    const handleDrag = (e: React.FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragover" || e.type === "dragenter") {
          setDragActive(true);
        } else if (e.type === "dragleave") {
          setDragActive(false);
        }
    };

      
    /* istanbul ignore next */
  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      if (mediaFiles.length < 5) {
        encodeImageFileAsURL(event.dataTransfer.files);
      }
    } else {
      console.log("File was not uploaded.");
    }
  };

    const onButtonClick = () => {
        inputRef.current!.click();
    };
    

    return (
        <Container id="supp-main-container" role="main">

         <Suspense
        fallback={
          <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} />
        }
      >
        <div id="backmodal-div1" className="mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0" >
          <p id="backmodal-p" className="text-teal text-xl mt-4 mb-8 sm-pl-1">
            <a data-testid="custBackBtn" className="back-btn" onClick={backButtonHandler}>
              <i className="fas fa-angle-left"></i>
              <span>{t("back")}</span>
            </a>
          </p>
        </div>

          {isLoading && (
            <div className={classes.overlay}>
              <div id="UploadingOverlay">
                <div className={styles.overlayContainer}>
                  <div className={styles.overlayContent}>
                    <div className={styles.spinner}></div>
                  </div>
                </div>
              </div>
            </div>
          )}
    
          <div className="question ml-sm-5 pl-sm-5 cases-text">
            <div>
              <h1
                data-testid="Upload_Media_Title"
                className="text-purple-100 text-2xl mb-2 rep-mar global-txt"
              >
                {t("Upload_Media_Title")}
              </h1>
            </div>
            <div>
              <p className="text-gray-700 text-black global-txt fw-light">
                {t("Upload_Media_SubHeading1")}
              </p>
              <p className="text-gray-700 text-black global-txt fw-light">
                {`${t("Upload_Media_SubHeading2")} ${allowFileType}`}
              </p>
            </div>
            <div className="w-full">
              <form
                className={classes["form-file-upload"]}
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
                data-testid="form-file-upload"
              >
                <input
                  className={classes["input-file-upload"]}
                  ref={inputRef}
                  type="file"
                  id="input-file-upload"
                  onChange={handleMediaFilesChange}
                  disabled={disableUploadButton}
                  data-testid="input-file-upload"
                />
                <label
                  id="label-file-upload"
                  htmlFor="input-file-upload"
                  className={
                    dragActive
                      ? classes["label-file-upload-drag-active"]
                      : classes["label-file-upload"]
                  }
                >
                  <div>
                    <div
                      className={`${classes.hideMobViewText} global-txt fw-light`}
                    >
                      {t("Upload_Media_Initial_Text1")}
                    </div>
                    <div
                      className={`${classes.hideMobViewText} global-txt fw-light`}
                    >
                      {t("Upload_Media_Initial_Text2")}
                    </div>
                    <div
                      className=" mt-2 "
                      onClick={onButtonClick}
                      data-testid="browse-button"
                    >
                      <input
                        type="button"
                        name="mediaFiles"
                        value="Add Attachment"
                        className={disableUploadButton ? classes.disabled : ""}
                        disabled={disableUploadButton}
                      />
                      <i className="fas fa-upload relative -left-8 text-white pointer-events-none"></i>
                    </div>
                  </div>
                </label>
                {dragActive && (
                  <div
                    className={classes["drag-file-element"]}
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    data-testid="drag-file-element"
                  ></div>
                )}
              </form>
    
              <div className=" flex-col md:flex-row md:justify-between">
                <div className="text-gray-700 text-black global-txt fw-light">
                  {`${t("Upload_Media_Initial_Text3")} ${maxFileSize / 1000000}MB`}
                </div>
                <div
                  className={`${classes.uploadMediaNo} text-gray-700 text-black global-txt fw-light`}
                >
                  {mediaFiles.length}/5
                </div>
              </div>
    
              <div className="error mt-4 pb-2">
                <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                  <div className="px-4 pr-8">
                    <i className="fas fa-bullhorn text-purple-100 w-6 h-6"></i>
                  </div>
                  <div className="flex items-center text-black">
                    <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                      {t("Upload_Media_Initial_Alert")}
                    </p>
                  </div>
                </div>
              </div>
    
              {fileTooLarge && (
                <>
                  <div className="error mt-4 pb-2">
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Upload_Media_Attachments_Too_Large")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              )}
    
              {error && (
                <div className="error mt-4 pb-2">
                  <div className="w-full px-2 py-2 bg-yellow flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                    </div>
                    <div className="flex items-center text-black">
                      <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                        {t(error)}
                      </p>
                    </div>
                  </div>
                </div>
              )}
    
              {wrongFileType && (
                <>
                  <div className="error mt-4 pb-2">
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center text-black">
                        <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                          {t("Upload_Media_Wrong_File_Type")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              )}
    
              {mediaFiles.length >= 5 && (
                <div className="error mt-4 pb-2">
                  <div className="w-full px-2 py-2 bg-yellow flex items-center">
                    <div className="px-4 pr-8">
                      <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                    </div>
                    <div className="flex items-center text-black">
                      <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                        {t("Upload_Media_Max_Attachments")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
    
              {mediaFiles.length >= 1 && (
                <div className="mb-4 mt-4 text-black">
                  <div className="w-full border border-grey">
                    <ReactBootstrap.Table striped className="container-new">
                      <TableHead className={classes.tableRowColor}>
                        <TableRow>
                          <TableCell className="items-center">
                            <strong className={classes.tableHeader}>Name</strong>
                          </TableCell>
    
                          <TableCell align="right" className="items-center">
                            <strong className={classes.tableHeader}>Remove</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </ReactBootstrap.Table>
    
                    {mediaFiles.map((mediaFile: any, key: number) => {
                      return (
                        <UploadMediaRow
                          key={key}
                          mediaFileName={mediaFile.name}
                          type={mediaFile.fileType}
                          bgColour={key % 2 == 0 ? "bg-white" : "bg-white"}
                          deleteMediaFile={() => deleteMediaFile(key)}
                          {...props}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
    
          <div
            className="flex justify-between items-center pb-4 md:pb-8"
            id="supp-main-btn"
          >
            <ExitModalContent
                id="case-exitmodal"
                exit={exitButtonHandler}
                btn_msg="return_case_button"
            />

            <Button
              id="supp-btn1"
              onClick={onContinueHandler}
              className="button-class"
            >
              {t("continuen_button")}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
          </Suspense>
        </Container>
      );
    

};

export default UploadMedia;
