import {
    RadioGroup,
    FormControlLabel,
    Radio,
  } from "@material-ui/core";
  import React, { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { useDispatch, useSelector } from "react-redux";
  import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
  import { RouteComponentProps } from "react-router-dom";
  import Button from "../../Button/Button";
  import Loader from "react-loader-spinner";
  import ExitModalAndSave from "../Modal/ExitModalAndSave";
  import {
    backToRepairAsset,
    isEditState,
    userChosenNotifyAll,
    userChosenSaveExit,
    workOrderData
  } from "../../ducks/redux/actions/userChoices";
  import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
  import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
  import * as api from "../../api";
  
  interface BlockCommunicationProps extends RouteComponentProps<any> {}
  const BlockCommunication: React.FC<BlockCommunicationProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [radioValue, setRadioValue] = useState("No");
    const [isLoading, setIsLoading] = useState(false);
    const [buttondisable, setButtonDisable] = useState(false);
    const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  
    const workOrderObject = useSelector(
      (state: any) => state?.addresses?.workOrderRepair
    );
    const notifyAll = useSelector(
      (state: any) => state.userChoicesReducer.userChosenNotifyAll
    );
    
    const EditState = useSelector(
      (state: any) => state.userChoicesReducer?.isEditState
    );

    const priorityCodeValue = useSelector(
      (state: any) => state.dashboardReducer.formState?.priorityCode
    );
  
    useEffect(() => {
      window.scrollTo(0,0);
      if (EditState === true && props.history.action !== "POP") {     
        dispatch(isEditState(false));
      }
      if(notifyAll==="Yes" || notifyAll===true){
      setRadioValue("Yes");
      }
      else{
      setRadioValue("No");
      }
    }, []);
  
    const submitForm = async () => {
      try {
      setIsLoading(true);
      setButtonDisable(true);    
       if(radioValue==="Yes"){
        workOrderObject.notifyAll=true;
        dispatch(userChosenNotifyAll("Yes"));
       }
       else{
        workOrderObject.notifyAll=false;
        dispatch(userChosenNotifyAll("No"));
       }
       workOrderObject.Priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));
      }
      catch (e) {
        console.error(e);
        const timerId = setTimeout(() => {
          props.history.push("/GenericError");
        }, 1000);
      } 
    };
    const backButtonHandler = () => {
     dispatch(userChosenNotifyAll(false));
      props.history.push("/CustomerDetails");
      props.history.action = "POP";
    };
  
    const exitButtonHandler = () => {
      dispatch(userChosenNotifyAll("No"));
      dispatch(backToRepairAsset(false))
      props.history.push("/LandingView");
    };
  
    const saveDraftAndExit = () => {
      dispatch(userChosenSaveExit(true))
      const worksOrder = new WorksOrderRequest(workOrderObject);  
      try {
        setIsLoading(true);  
         if(radioValue==="Yes"){
          workOrderObject.notifyAll=true;
          dispatch(userChosenNotifyAll("Yes"));
         }
         else{
          workOrderObject.notifyAll=null;
          dispatch(userChosenNotifyAll(null));
         }
         workOrderObject.Priority = priorityCodeValue;
        const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
        const woRequestParameters =
          _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
        const apiResponse = api.updateWorkOrderRepair(
          woRequestParameters,
          workOrderObject.id,
          "eTag"
        );
      }
        catch (e) {
          console.error(e);
          const timerId = setTimeout(() => {
            props.history.push("/GenericError");
          }, 1000);
        } 
        dispatch(workOrderRepairObject(workOrderObject));
        setCloseAfterSaving(true);
      dispatch(workOrderData(worksOrder));
      setCloseAfterSaving(true);
      dispatch(backToRepairAsset(false))
      props.history.push("/WorkOrder360View");
      setTimeout(() => {
        dispatch(userChosenSaveExit(false))
      }, 6000);
    };
  
    const callPageHandler = () => {
      submitForm();
      props.history.push("/ConfirmationDetails");
    };
    const handleRadioChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      value: any
    ) => {    
      setRadioValue(value);
      dispatch(userChosenNotifyAll(value));
    };
  
    return (
      <div id="block-container" role="main" className="container sm:px-2 ctn-fht">
        <div id="block-back-div" className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0">		
            <p id="block-p" className="text-teal text-xl mt-4 mb-8"><a className="back-btn" onClick={backButtonHandler} aria-hidden="true">		
            <i className="fas fa-angle-left mr-2"></i>{t("back")}</a></p>		
            </div> 
        <div
          id="block-main-div"  
          className="container question ml-sm-5 pl-sm-5 repair-text"
        >
          <div id="block-div1" className="h5 my-2">
          <h2 id="block-h1" className="text-purple-100 text-2xl mb-2 global-txt">{t("CPR085")}</h2> 
  
          <fieldset>
              <legend>
              </legend>
            <RadioGroup
              id="block-radiogroup" 
              role="radiogroup"
              name="radio-buttons-group"
              defaultValue={radioValue}
              className="margin-radipgroup text-black mb-2 margin-lt2"
              data-testid="crimereradioValue"
              value={radioValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                id="block-label1"
                value="Yes"
                placeholder="Yes"
                data-testid="yesRadio"
                control={<Radio id="vanQues-radio1" />}
                label="Yes"
                className="my-1 global-txt"
              />
               <FormControlLabel
                id="block-label2"
                value="No" 
                data-testid="noRadio"
                control={<Radio id="vanQues-radio2" />}
                label="No"
                placeholder="No"
                className="global-txt"
              />
            </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div
          id="block-main-btn"
          className="container flex justify-between items-center pb-4 md:pb-8"
        >
          <ExitModalAndSave
          data-testid="block-exitmodal"
            id="block-exitmodal"
            exit={exitButtonHandler}
            saveDraftAndExit={saveDraftAndExit}
            closeAfterSaving={closeAfterSaving}
          />
          <Button
            id="btn_block"
            disabled={buttondisable}
            onClick={callPageHandler}
            className="button-class"
          >
            {t("continue")}
            <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
          </Button>  
        </div>
        <span>
            {isLoading ? (
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={70}
                width={50}
                timeout={5000}
              />
            ) : (
              <>
                <div></div>
              </>
            )}
          </span>
      </div>
    );
  };
  
  export default BlockCommunication;
  