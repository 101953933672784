

export class RepairReason {
    public id: string = ''
    public description: string = ''
    public tags: string[] = []
    public sors: SOR[] = []
    public assetType: string[] = []
    public priority: string = ''
    public element: string = ''
    public additionalData: AdditionalDataItem[] = []
  }

  export class CompletedStep {
    public value: number = 0
    public key: string = ''
    public displayName: string = ''
    public legacyKeys: any[] = []
    constructor(step?: number) {
      this.value = step || 0
    }
  }
  type addressField = {
    digitalAddresses: DigitalAddress[]
  }
  export type Person = {
    name: {
      title: string
      givenName: string
      surname: string
    }
  }
  export  class DigitalAddress {
    public address: string | null = ''
    public digitalAddressType: string | null = ''
    public isPreferred: boolean = false
  }
  export class RaisedBy {
    public person: Person
    public address: addressField
    constructor(name?: NameDetails, addresses?: DigitalAddress[]) {
      this.person = {
        name: {
          title: name?.title || '',
          givenName: name?.givenName || '',
          surname: name?.surname || '',
        },
      }
      this.address = {
        digitalAddresses: addresses || [],
      }
    }
  }
  export class CurrentStep {
    public value: number = 0
    public key: string = ''
    public displayName: string = ''
    public legacyKeys: any[] = []
  }
  export class SOR {
    public sorCode: string = ''
    public trade: string = ''
    public workProgramme: string = ''
    public recallWorkProgramme: string = ''
  }
  export class ReportContactDetails {
    public name: string | null = null
    public contactNumber: string | null = null
    public organisation: string | null = null
    public reporterToBeContacted: boolean = false
  }
  export class AdditionalDataItem {
    public 'assetType': 'Property' | 'Communal' = 'Property'
    public 'description': string = ''
    public 'isEligible': boolean = true
    public 'tenancyTypes': string[] = []
  }
  export class RepairReasonV1 {
    public id: string = ''
    public description: string = ''
    public tags: string[] = []
    public sorCode: string = ''
    public assetType: string[] = []
    public priority: string = ''
    public element: string = ''
    public additionalData: AdditionalDataItem[] = []
  }
  export interface NameDetails {
    title?: string | null
    givenName?: string | null
    surname?: string | null
  }

export enum RepairStatusText {
  Unappointed = 'Unappointed',
  Appointed = 'Appointed',
  InProgress = 'In progress',
  InDiagnosis = 'In Diagnosis',
  Completed = 'Completed',
  Closed = 'Closed',
  Cancelled = 'Cancelled',  
  ReportedToEMA = 'Reported to EMA',
}
export type NoteProps = {
  dataBdd?: string
  text: string
  type: string
  author: string
  creationDate: string
}

export class repairPriorityRequest {
  public contractorIdentifier?: string = ''
  public isEmergency?: boolean = false
  public repairType?: string = ''
  public isVulnerable?: boolean = false
  public isRecall?: boolean = false
  public isEMA?: string = ''
  public description: string = ''
  public channel?: string = ''
  public raisedDate?: string = ''
}