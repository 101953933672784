import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as api from "../../../api";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "react-loader-spinner";
import { trackEvent } from "../../../appInsights/TelemetryService";
import { CaseHistoryItem } from "../../../models/Case/CaseHistory";
import { formatContent, getUpdateOwnerName, isNullOrUndefined, removeTagsFromContent } from "../../../utils/formatting";
import { MobilePagination, Pagination, Paginationbox, WebPagination } from "./component";
import InfiniteScroll from "react-infinite-scroll-component"
import { StyledOverflowHidden, StyledOverflowNonHidden } from "../CaseDetails/component";
import { finishJourney } from "../../../ducks/redux/actions/FinancialReferral";


interface CaseChronologyListProps extends RouteComponentProps<any> {
}
const CaseChronologyListComponent: React.FC<CaseChronologyListProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [caseHistoryDetails, setCaseHistoryDetails] = React.useState<CaseHistoryItem[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [skip, setSkip] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [caseHistoryDetailsData, setCaseHistoryDetailsData] = React.useState<CaseHistoryItem[]>([]);
    const caseData = useSelector((state: any) => state?.userChoicesReducer?.caseData);
    const overflowingText = useRef<HTMLParagraphElement | null>(null);
    const [activeIndex, setActiveIndex] = React.useState(null);
    useEffect(() => {
        (async () => {
            trackEvent("CaseChronologyList");
            const CaseHistory = await api.getCaseHistories(
                caseData.identifiers?.caseIdentifier
            );
            setCaseHistoryDetailsData(CaseHistory?.historyItems);

        })();
    }, []);

    useEffect(() => {
        const queryParams = {
            limit: 10,
            skip: 0
        };
        getCaseHistoryData(queryParams);
     }, [caseHistoryDetailsData]);

     const getCaseHistoryData = async (queryParams: any) => {
        try {
            setIsLoading(true);
            const allItems: any = [];
            if (caseHistoryDetailsData.length > 0) {
                if (caseHistoryDetailsData.length <= 2) {
                    props.history.push("/Case360View");
                }
                else {
                    let filteredResults = [];
                    if (caseHistoryDetailsData.length > 1) {
                        const sortedResults = caseHistoryDetailsData.sort((a: any, b: any) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf());
                        const finalSortedResults = sortedResults.sort((a: any, b: any) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf());
                        filteredResults.push(finalSortedResults);
                    }

                    filteredResults.map((items: any) => {
                        items.map((eachItem: CaseHistoryItem) => {
                            allItems.push(eachItem);
                        });
                    });
                    if (allItems.length > 0) {
                        setTotalCount(allItems.length);
                        const filteredItems = allItems.filter(
                            (item: any) => item.type !== ""
                        );
                        const filteredHistorySummary = filteredItems.sort((a: any, b: any) => new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()).slice(queryParams.skip, (queryParams.skip + queryParams.limit));
                        setCaseHistoryDetails(filteredHistorySummary);
                        setIsLoading(false);
                    }
                }
            }
        } catch (e) {
            props.history.push("/genericerror");
        }
    }

    const backButtonHandler = () => {
        dispatch(finishJourney(true));
        props.history.push("/Case360View");
    };

    const updateCaseHistoryData = (limit: any, skip: any) => {
        (async () => {
            setIsLoading(true);
            const queryParams = {
                limit: limit,
                skip: skip
            };
            getCaseHistoryData(queryParams);
        })();
    }

    const onChangePage = (e: any, page: number) => handleChangePage(page);

    const handleChangePage = (page: number) => {
        setCurrentPage(page);
        setParamsAndUpdateHistoryData(resultsPerPage, resultsPerPage * page);
    };

    const handleChangeRowsPerPage = (e: React.ChangeEvent) => {
        setCurrentPage(0);
        setParamsAndUpdateHistoryData((e.target as any).value, 0);
    };

    const fetchData = () => {
        setParamsAndUpdateHistoryData(caseHistoryDetailsData.length, 0);
    }

    const setParamsAndUpdateHistoryData = (resultsPerPage: any, skipLimit: any) => {
        setResultsPerPage(resultsPerPage);
        setSkip(skipLimit);
        updateCaseHistoryData(resultsPerPage, skipLimit);
    };

    const viewDetailsCaseDescriptionHandler = (id: any, description: string) => {
        if(description === "close"){
            setActiveIndex(null);
        }
        else {
            setActiveIndex(id);
        }
        
    };

    const checkOverflow = (valueComment:any) => {
        if (valueComment?.length > 435) {      
            return true;
        }
        return false;
    };

    const checkOverflowMobile = (valueComment:any) => {
        if (valueComment?.length > 129) {      
            return true;
        }
        return false;
    };

    return (
        <div>

            <div className="border-t border-grey bg-brandmark-image bg-no-repeat bg-right-60% bg-50% md:bg-35% lg:bg-20% bg-fixed">
                <div className="container mx-auto pb-12 ctn-fht">
                    <div className="w-full px-2 lg:px-0 ">
                        <div className="mt-4 mb-8 ">
                            <span className="text-teal text-xl mt-4 mb-4 md:mb-12">
                                <a className="back-btn"   onKeyDown={ () => {}} data-testid="backButtonHandler" onClick={backButtonHandler}><i className="fas fa-angle-left mr-2"></i>{t("back")}</a></span>
                        </div>
                        <WebPagination>
                            <div className="w-full lg:px-0 global-txt">
                                <div className="border border-grey bg-white shadow-lg rounded-lg mb-8 mt-8">
                                    <div className="bg-brick-20 px-4 py-2 rounded-t-lg">
                                        <div className="flex flex-row items-center">
                                            <strong>{t("Case_Reference")} {formatContent(caseData?.caseReferenceNumber)}</strong>
                                        </div>
                                    </div>
                                    <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                        <div className="mb-2">
                                            <strong>{t("CaseTitle")}</strong><br />{formatContent(caseData?.caseSubTypeCategory)}
                                        </div>
                                        <div className="mb-2">
                                            <strong>{t("CP085")}</strong><br />
                                            {getUpdateOwnerName(caseData?.owner?.name)}                     
                                        </div>
                                    </div>

                                </div>
                                {isLoading ? (
                                    <Loader
                                        type="ThreeDots"
                                        color="#00BFFF"
                                        height={70}
                                        width={50}
                                        timeout={5000}
                                    />
                                ) : <>
                                    {caseHistoryDetails.map(
                                        (eachCaseUpdate: CaseHistoryItem, index:any) => {
                                            return (
                                                <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                    <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
                                                        <div className="flex flex-row justify-between items-center">
                                                            <strong>{eachCaseUpdate.type}</strong>
                                   {checkOverflow(eachCaseUpdate.comment)  && <div> {activeIndex === index  ? (<div
                                            id={"CaseAdditionalInfo-active" + index}
                                            data-testid="CaseAdditionalInfo-active-multiply"
                                            className="plus-sign mt-10 cursor-pointer"
                                            onKeyDown={ () => {}}
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(index, "close")}
                                        >
                                            {"⨯"}
                                        </div>) : (<div
                                            id={"CaseAdditionalInfo-active" + index}
                                            data-testid="CaseAdditionalInfo-active-plus"
                                            className="plus-sign mt-10 cursor-pointer"
                                            onKeyDown={ () => {}}
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(index, "open")}
                                        >
                                            {"+"}
                                        </div>)}
                                        </div>
                                        }
                                        
                                
                                                        </div>
                                                    </div>

                                                    <div className="py-2">
                                                        <div className="px-4 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                        <div className="mb-2">
                                                                    <strong>{t("Created_On")}</strong><br />  {moment(eachCaseUpdate.createdDate).format(
                                                                        "DD-MM-YYYY, h:mm:ss A"
                                                                    )}
                                                                </div>
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["owner"]) && <div className="mb-2">
                                                                    <strong>{t("CP085")}</strong><br />{eachCaseUpdate.metaData["owner"]}
                                                                </div>}
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["createdBy"]) && <div className="mb-2">
                                                                    <strong>{t("Created_By_CP115")}</strong><br />{eachCaseUpdate.metaData["createdBy"]}
                                                                </div>}
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["status"]) && <div className="mb-2">
                                                                    <strong>{t("CP086")}</strong>
                                                                    <br />
                                                                    {eachCaseUpdate.metaData["status"]}
                                                                </div>}
                                                            {(eachCaseUpdate.type === "Action" && eachCaseUpdate.metaData
                                                                && !isNullOrUndefined(eachCaseUpdate.metaData["actionType"])) && (
                                                                    <div className="mb-2">
                                                                        <strong>{t("actionType")}</strong>
                                                                        <br />
                                                                        <div>
                                                                            {eachCaseUpdate.metaData["actionType"]}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="px-4 grid grid-cols-1 global-txt">  
                                                            {eachCaseUpdate.type && eachCaseUpdate.type == "Appointment" && eachCaseUpdate.metaData && eachCaseUpdate.metaData["appointmentDate"] && <div className="mb-2">
                                                                <strong>{t("Appointment_Date")}</strong><br />  {moment(eachCaseUpdate.metaData["appointmentDate"]).format(
                                                                    "DD-MM-YYYY, h:mm:ss A"
                                                                )}
                                                            </div>}
                                                            
                                                            {eachCaseUpdate.type !== "Action" && (eachCaseUpdate.type !== "Attachment Added" || (eachCaseUpdate.type == "Attachment Added" && !isNullOrUndefined(eachCaseUpdate.comment))) && <div className="mb-4">
                                                                <strong>{t("comment")}</strong><br />{activeIndex === index ? <StyledOverflowNonHidden ref={overflowingText}>{removeTagsFromContent(eachCaseUpdate.comment)}</StyledOverflowNonHidden> : <StyledOverflowHidden ref={overflowingText}>{removeTagsFromContent(eachCaseUpdate.comment)}</StyledOverflowHidden>
                                                                
                                                                }
                                                            </div>}

                                                    </div>
                                                </div>
                                                </div>


                                            )
                                        }
                                    )
                                    }
                                    <div className="page-mar">
                                        <Paginationbox>
                                            <label>
                                                <Pagination
                                                    className=" ml-4 "
                                                    colSpan={7}
                                                    count={totalCount}
                                                    page={currentPage}
                                                    rowsPerPage={resultsPerPage}
                                                    rowsPerPageOptions={[10, 20, 100]}
                                                    onPageChange={onChangePage}
                                                    data-testid="WorkOrderPaginationChange"
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </label>
                                        </Paginationbox>
                                    </div>
                                </>
                                }
                            </div>
                        </WebPagination>
                        <MobilePagination>
                            <div
                                id="scrollableDiv"
                                data-testid="scrollableDiv" className="mob-view-only"
                                style={{
                                    height: 800,
                                    overflowY: "scroll",
                                    overflow: "auto",
                                    flexDirection: "column",
                                }}
                            >
                                <InfiniteScroll
                                    dataLength={caseHistoryDetails?.length}
                                    next={fetchData}
                                    style={{ display: "flex", flexDirection: "column" }}
                                    hasMore={true}
                                    loader={<p></p>}
                                    scrollableTarget="scrollableDiv"
                                >
                                    <div className="w-full lg:px-0 global-txt">
                                        <div className="border border-grey bg-white shadow-lg rounded-lg mb-8 mt-8">
                                            <div className="bg-brick-20 px-4 py-2 rounded-t-lg">
                                                <div className="flex flex-row items-center">
                                                    <strong>{t("Case_Reference")} {formatContent(caseData?.caseReferenceNumber)}</strong>
                                                </div>
                                            </div>
                                            <div className="px-4 py-2 grid grid-cols-1 md:grid-cols-2 gap-2 mt-3">
                                                <div className="mb-2">
                                                    <strong>{t("CaseTitle")}</strong><br />{formatContent(caseData?.caseSubTypeCategory)}
                                                </div>
                                                <div className="mb-2">
                                                    <strong>{t("CP085")}</strong><br />{getUpdateOwnerName(caseData?.owner?.name)} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isLoading ? (
                                        <Loader
                                            type="ThreeDots"
                                            color="#00BFFF"
                                            height={70}
                                            width={50}
                                            timeout={5000}
                                        />
                                    ) : <>
                                        {caseHistoryDetails.map(
                                            (eachCaseUpdate: CaseHistoryItem, index) => {
                                                return (
                                                    <div className="border border-grey bg-white shadow-lg rounded-lg mb-8">
                                                        <div className="bg-blue-20 px-4 py-2 rounded-t-lg">
                                                            <div className="flex flex-row justify-between items-center">
                                                                <p className="my-0 text-base"><strong>{eachCaseUpdate.type}</strong></p>
                                                                {checkOverflowMobile(eachCaseUpdate.comment)  && <div>                  {activeIndex === index ? (<div
                                            id={"CaseAdditionalInfo-active" + index}
                                            data-testid="CaseAdditionalInfo-active-multiply"
                                            className="plus-sign mt-10 cursor-pointer"
                                            onKeyDown={ () => {}}
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(index, "close")}
                                        >
                                            {"⨯"}
                                        </div>) : (<div
                                            id={"CaseAdditionalInfo-active" + index}
                                            data-testid="CaseAdditionalInfo-active-plus"
                                            className="plus-sign mt-10 cursor-pointer"
                                            onKeyDown={ () => {}}
                                            onClick={(event) =>
                                                viewDetailsCaseDescriptionHandler(index, "open")}
                                        >
                                            {"+"}
                                        </div>)}
                                        </div>}
                                                            </div>
                                                        </div>

                                                        <div className="py-2">
                                                            <div className="px-4 grid grid-cols-1 md:grid-cols-3 gap-2 mt-3">
                                                                <div className="mb-2">
                                                                    <strong>{t("Created_On")}</strong><br />  {moment(eachCaseUpdate.createdDate).format(
                                                                        "DD-MM-YYYY, h:mm:ss A"
                                                                    )}
                                                                </div>
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["owner"]) && <div className="mb-2">
                                                                    <strong>{t("CP085")}</strong><br />{eachCaseUpdate.metaData["owner"]}
                                                                </div>}
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["createdBy"]) && <div className="mb-2">
                                                                    <strong>{t("Created_By_CP115")}</strong><br />{eachCaseUpdate.metaData["createdBy"]}
                                                                </div>}
                                                                {eachCaseUpdate.metaData && !isNullOrUndefined(eachCaseUpdate.metaData["status"]) && <div className="mb-2">
                                                                    <strong>{t("CP086")}</strong>
                                                                    <br />
                                                                    {eachCaseUpdate.metaData["status"]}
                                                                </div>}
                                                                {(eachCaseUpdate.type === "Action" && eachCaseUpdate.metaData
                                                                && !isNullOrUndefined(eachCaseUpdate.metaData["actionType"])) && (
                                                                    <div className="mb-2">
                                                                        <strong>{t("actionType")}</strong>
                                                                        <br />
                                                                        <div>
                                                                            {eachCaseUpdate.metaData["actionType"]}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="px-4 grid grid-cols-1 global-txt">
                                                                {eachCaseUpdate.type && eachCaseUpdate.type == "Appointment" && eachCaseUpdate.metaData && eachCaseUpdate.metaData["appointmentDate"] && <div className="mb-2">
                                                                    <strong>{t("Appointment_Date")}</strong><br />  {moment(eachCaseUpdate.metaData["appointmentDate"]).format(
                                                                        "DD-MM-YYYY, h:mm:ss A"
                                                                    )}
                                                                </div>}
                                                                
                                                   {eachCaseUpdate.type !== "Action" && (eachCaseUpdate.type !== "Attachment Added" || (eachCaseUpdate.type == "Attachment Added" && !isNullOrUndefined(eachCaseUpdate.comment))) && <div className="mb-4">
                                                                <strong>{t("comment")}</strong><br />{activeIndex === index ? <StyledOverflowNonHidden ref={overflowingText}>{removeTagsFromContent(eachCaseUpdate.comment)}</StyledOverflowNonHidden>:<StyledOverflowHidden ref={overflowingText}>{removeTagsFromContent(eachCaseUpdate.comment)}</StyledOverflowHidden>
                                                                
                                                                }
                                                                </div>}

                                                        </div>
                                                    </div>
                                                    </div>


                                                )
                                            }
                                        )
                                        }
                                    </>
                                    }
                        </InfiniteScroll>
                    </div>
                </MobilePagination>
            </div>
        </div>
            </div >        
    </div >

        
    )
}

export default CaseChronologyListComponent;