import {
  REFERRAL_ACCOUNT_BALANCE,
  CLEAR_REFERRAL_INFO,
  COMING_FROM_REFERRAL,
  REFERRAL_ADDITIONAL_DETAILS,
  REFERRAL_FOR,
  REFERRAL_INCLUSION_TEAM,
  REFERRAL_OTHER_DETAILS,
  REFERRAl_REASON,
  COMING_FROM_CASE,
  WO_REDIRECT_FROM_EMAIL,
  ASSET_BACK_COUNTER,
  ASSET_ARRAY,
  RELEASE_ASSET_ARRAY,
  REMOVE_ASSET_ARRAY,
  FINISH_JOURNEY,
  COMING_FROM_CASE_LIST,
  SERVICE_CARD_DATA,
  GAS_CARD_DATA,
} from "../actionTypes";

export const financialReferralFor = (financialReferral: string): any => {
  return { type: REFERRAL_FOR, payload: financialReferral };
};

export const FinancialReferralReasonRes = (referralReason: any[]): any => {
  return { type: REFERRAl_REASON, payload: referralReason };
};

export const FinancialInclusionTeam = (inclusionTeam: boolean): any => {
  return { type: REFERRAL_INCLUSION_TEAM, payload: inclusionTeam };
};

export const FinancialOtherDetails = (otherDetails: string): any => {
  return { type: REFERRAL_OTHER_DETAILS, payload: otherDetails };
};

export const clearReferralInformation = (data: any): any => {
  return { type: CLEAR_REFERRAL_INFO, payload: data };
};

export const comingFromReferralPage = (data: boolean): any => {
  return { type: COMING_FROM_REFERRAL, payload: data };
};

export const storeReferralAdditionalDetails = (data: any): any => {
  return { type: REFERRAL_ADDITIONAL_DETAILS, payload: data };
};

export const saveReferralAccountBalance = (data: string): any => {
  return { type: REFERRAL_ACCOUNT_BALANCE, payload: data };
};

export const comingFromCase = (data: boolean): any => {
  return { type: COMING_FROM_CASE, payload: data };
};

export const assetBackCouterDispatch = (data: number): any => {
  return { type: ASSET_BACK_COUNTER, payload: data };
};

export const assetArrayDispatch = (data: any): any => {
  return { type: ASSET_ARRAY, payload: data };
};

export const releaseAssetArrayDispatch = (data: any): any => {
  return { type: RELEASE_ASSET_ARRAY, payload: data };
};

export const removeAssetArrayDispatch = (data: any): any => {
  return { type: REMOVE_ASSET_ARRAY, payload: data };
};

export const woRedirectFromEmailStatus = (data: boolean): any => {
  return { type: WO_REDIRECT_FROM_EMAIL, payload: data };
};

export const finishJourney = (data: boolean): any => {
  return { type: FINISH_JOURNEY, payload: data };
};

export const comingFromCaseListData = (data: any): any => {
  return { type: COMING_FROM_CASE_LIST, payload: data };
};
export const savingServiceCardData = (data:any):any => {
  return {type:SERVICE_CARD_DATA, payload:data}
}
export const gasBoilderData = (data:any):any => {
  return {type:GAS_CARD_DATA, payload:data}
}
