import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardForm } from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  resumeDraftRepair,
  backToRepairAsset,
  workOrderData,
  userChosenSaveExit,
  userChosenNotifyAll
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import "./style.css";
import Loader from "react-loader-spinner";
import Button from "../../Button/Button";
import * as api from "../../api";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { BackValue } from "../../models/shared/Enum";
import { PermissionsList } from "../../models/Permissions";
interface VulnerabilityQsProps extends RouteComponentProps<any> {}

const VulnerabilityQs: React.FC<VulnerabilityQsProps> = (props) => {
  const { t } = useTranslation();
  const format = /[><*&!]+/;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isError, setError] = useState("none");
  const [textvalue, setTextValue] = useState("");
  const [radiovalue, setRadioValue] = useState("No");
  const [isLoading, setIsLoading] = useState(false);

  const [buttondisable, setButtonDisable] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [showError, setShowError] = useState("");
  const [disableContinueforEnhancedEligiblityCheck, setDisableContinueforEnhancedEligiblityCheck] = useState(false);
  const [isPotentialDuplicateForEnhancedRepair,setIsPotentialDuplicateForEnhancedRepair] = useState(false);
  const [isEmergencyForEnhancedRepair,setIsEmergencyForEnhancedRepair] = useState(false);

  const priority = useSelector(
    (state: any) =>
    state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
    ?.priority
  );

  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
  

  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );


  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const userChosenVulnerabilityQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const userChosenVulnerabilityAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );
  
  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
     
  );
  const backValue = useSelector(
    (state: any) => state?.userChoicesReducer?.backValue
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.vandalismtoggleValue
  );
  const isRepairMedia = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairMediaAllowed
  );
  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );
  const getPermissionsData = useSelector(
    (state: any) => state?.userChoicesReducer?.userProfilePermissions
  );

  const hideVulnerablePagePermission = getPermissionsData?.indexOf(PermissionsList.workoder_vulnerable_hide_permission) >= 0;

  const covidConfigValue = useSelector(
    (state: any) => state?.userChoicesReducer?.isRepairCovidAllowed
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.dashboardReducer.formState?.priorityCode
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    var hideVulnerablePage = hideVulnerablePagePermission || priority === "Emergency" 
    if(hideVulnerablePage && covidConfigValue){ 
      props.history.replace("/Covidquestion");
    }
    else if (hideVulnerablePage && !covidConfigValue){
      props.history.replace("/CustomerDetails");
    }
    if (userChosenVulnerabilityQuestionBackButton) {
      setRadioValue(userChosenVulnerabilityQuestionBackButton);
    }
    setTextValue(userChosenVulnerabilityAdditionalInformationBackButton);

    if (assetDetails.assetType === "Property" && priority === "Emergency") {
      if (userChosenEmergencyQuestionVal === "Yes, access can be provided") {
        setIsEmergencyForEnhancedRepair(true);
      } else {
        setIsEmergencyForEnhancedRepair(false);
      }
    }
    if (userChosenEmergencyQuestion === "Yes, access can be provided") {
      props.history.replace("/covidquestion");
    }
    if(userChosenDuplicateQuestion === "I don't think this is a duplicate"){
      setIsPotentialDuplicateForEnhancedRepair(true);
    }
  }, []);

  useEffect(() => { 
    if ((radiovalue === "Yes" && textvalue != "") || radiovalue === "No") {
      setError("none");
    }
    dispatch(userChosenVulnerabilityQuestion(radiovalue));
  }, [textvalue, radiovalue]);

  const updateSelection = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    setRadioValue(e.target.value);
    dispatch(
      updateDashboardForm({
        selected: e.target.value,
        textArea: textvalue,
      })
    );
    const enhancedRepairEligibilityPayload = {
      isEmergency: isEmergencyForEnhancedRepair,
      sor: userChosenSOR,
      isPotentialDuplicate: isPotentialDuplicateForEnhancedRepair,
      isVulnerable: e.target.value === "Yes",
      channel: "ColleagueSelfService",
      userRoles: localStorage.getItem('user_role') || "",
    };

    setIsLoading(true);
    setButtonDisable(true);

    const repairEnhancedEligibility = await api.postEnhancedRepairEligibility(enhancedRepairEligibilityPayload);
    setIsLoading(false);
    if (repairEnhancedEligibility?.isEligible === false) {
      setShowError(t(repairEnhancedEligibility?.errorCode));
      setDisableContinueforEnhancedEligiblityCheck(true);
      setButtonDisable(true);
    } else {
      setShowError("");
      setDisableContinueforEnhancedEligiblityCheck(false);
      setButtonDisable(false);
    }

    if (e.target.value === "No") {
      setTextValue("");
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
    }
  };

  const onChange = (e: any) => {
    if (EditState === true) {
      dispatch(userChosenNotifyAll(null)); 
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }
    setTextValue(e.target.value);
    dispatch(userChosenVulnerabilityAdditionalInformation(e.target.value));
    dispatch(
      updateDashboardForm({
        textArea: e.target.value,
        selected: radiovalue,
      })
    );
  };

  const backButtonHandler = () => {
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenVulnerabilityQuestion("No"));
   
    if(assetDetails?.statuses.isInDefects === true && vandalismConfigValue === true)
    {
    props.history.push("/VandalismQuestion");
    }
    else if(isRepairMedia == "true") {
      props.history.push("/RepairUploadMedia");
    } 
    else
    props.history.push("/RepairAdditionalDetails")
  };

  const exitButtonHandler = () => {
    dispatch(backToRepairAsset(false))
    props.history.push("/LandingView");
  };

  const saveDraftAndExit = () => {
    dispatch(userChosenSaveExit(true))
    const worksOrder = new WorksOrderRequest(workOrderObject);
    dispatch(workOrderData(worksOrder))
    setError("none");
    submitForm();
    setCloseAfterSaving(true);
    dispatch(backToRepairAsset(false))
    props.history.push(`/WorkOrder360View/${worksOrder.identifiers?.worksOrderIdentifier}`)
    setTimeout(() => {
      dispatch(userChosenSaveExit(false))
    }, 6000);
  };

  // update work order
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const userChosenVandalismQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenVandalismAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const submitForm =  () => {
    try {
      setIsLoading(true);
      setButtonDisable(true);
      workOrderObject.Priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      if (radiovalue == "Yes") {
        woRequestParameters.isVulnerable = true;
        woRequestParameters.VulnerableResponseText = textvalue.trim().length>0?textvalue:null;
      } else {
        woRequestParameters.isVulnerable = false;
        woRequestParameters.VulnerableResponseText = null;
      }

      if (userChosenVandalismQuestion == "Yes") {
        woRequestParameters.crimeReferenceNumber =
          userChosenVandalismAdditionalInformation;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }
      const apiResponse =  api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
    
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
        setTimeout(() => {
            props.history.push("/GenericError");
        }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisable(false);
    }
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { vulnerabilityQuestion, vulnerableResponseText} = userResumeDraftDataCache;
      if (vulnerabilityQuestion !== radiovalue || vulnerableResponseText) {
        dispatch(resumeDraftRepair(false));       
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if ((textvalue !== "" || radiovalue === "No" || radiovalue == "Yes")) {
      setError("none");
      submitForm();
        props.history.push("/covidquestion");
    } else {
      setError("block");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={70}
          width={50}
        />
      ) : (
        <div id="vulQues-container" role="main" className="sm:px-2 ctn-fht">
          <div
            id="vulQues-back-div"
            className="container mx-auto mb-0 md:mb-14 lg:mb-10 px-0 xl:px-0"
          >
            <p id="vulQues-p" className="text-teal text-xl mt-4 mb-8">
              <a
                className="back-btn"
                data-testid="vulQuesBackBtn"
                onClick={backButtonHandler}
                onKeyDown={backButtonHandler}
              >
                <i className="fas fa-angle-left mr-2"></i>
                {t("back")}
              </a>
            </p>
          </div>

          <div className="mb-8 container">
            <div id="vulQues-main-div">
              <h2 className="rep-mar text-purple-100 text-2xl mb-4 pt-2 global-txt">
                {" "}
                {t("vulnerability")}{" "}
              </h2>
            </div>

            <div id="vulQues-div1">
              <RadioGroup
                id="vulQues-radiogroup"
                className="mb-2"
                key={radiovalue}
                onChange={updateSelection}
                defaultValue={radiovalue}
                value={radiovalue}
              >
                <fieldset>
                  <legend>
                    <FormControlLabel
                      id={"vulQues-label1" + 1}
                      value="Yes"
                      control={
                        <Radio
                          id="vulQues-radio2"
                          key="radioYes"
                          className="pl-0"
                        />
                      }
                      label="Yes"
                      placeholder="Yes"
                      className="global-txt"
                    />
                  </legend>
                </fieldset>
                {radiovalue === "Yes" && disableContinueforEnhancedEligiblityCheck === false && (
                  <>
                    <div id="vulQues-span1" className="alert mt-2 mb-8">
                      <div className="w-full px-2 py-2 bg-purple-20 flex items-center">
                        <div className="px-4 pr-8">
                          <i className="fa fa-bullhorn text-purple-100 w-6 h-6 purple-icon"></i>
                        </div>
                        <div className="flex items-center text-black">
                          <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                            {t("vulnerability_warning")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <textarea
                      aria-label="vulQuesAdd"
                      id="vulQues-textarea"
                      className="mt-2 global-txt vul-teal px-2 py-2 ml-4 container"
                      data-testid="textareaVulnerability"
                      placeholder={t("vulnerability_placeholder_txt")}
                      value={textvalue ?? ""}
                      onChange={(e) => onChange(e)}
                    />
                  </>
                )}

                {disableContinueforEnhancedEligiblityCheck && (
                  <div
                    data-testid="alert-warning"
                    className="error mb-8 sm:mx-1"
                    role="alert"
                    placeholder="alert-message"
                  >
                    <div className="w-full px-2 py-2 bg-yellow flex items-center">
                      <div className="px-4 pr-8">
                        <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                      </div>
                      <div className="flex items-center">
                        <p
                          data-testid="locatioAlert_0"
                          className="w-full text-left my-2 font-AvantGardeGothic-Md"
                        >
                          {" "}
                          {t(showError)}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <fieldset>
                  <legend>
                    <FormControlLabel
                      id="vulQues-label1"
                      value="No"
                      data-testid="noRadio"
                      control={
                        <Radio
                          id="vulQues-radio1"
                          key="radioNo"
                          className="pl-0"
                        />
                      }
                      label="No"
                      placeholder="No"
                      className="global-txt"
                    />
                  </legend>
                </fieldset>
              </RadioGroup>
            </div>
            <div
              className="error my-4"
              id="repAddDet-div6"
              style={{ display: isError }}
              role="alert"
              placeholder="alert"
            >
              <div className="w-full px-2 py-2 bg-yellow flex items-center">
                <div className="px-4 pr-8">
                  <i className="fas fa-exclamation-triangle text-brick-70 w-6 h-6"></i>
                </div>
                <div className="flex items-center text-black">
                  <p className="w-full text-left my-2 font-AvantGardeGothic-Md">
                    {t("SSR056")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="vulQues-div2"
            className="flex justify-between items-center pb-4 container"
          >
            <ExitModalAndSave
              id="vulQues-exitmodal"
              exit={exitButtonHandler}
              saveDraftAndExit={saveDraftAndExit}
              closeAfterSaving={closeAfterSaving}
              className="items-start"
            />
            <Button
              id="btn_vulnerability"
              disabled={buttondisable}
              onClick={callPageHandler}
              className="button-class"
            >
              {t("continue")}{" "}
              <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default VulnerabilityQs;
