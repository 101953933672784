import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio } from "@material-ui/core";
import * as api from "../../api";
import moment from "moment";
import {
  updateDashboardForm,
  fetchRepairReasons,
  fetchRepairReasonsSelect,
} from "../../ducks/redux/actions/dashboardReducer.actions";
import SurveyReason2 from "./index2";
import "../RepairReason/styles.css";

import { RouteComponentProps } from "react-router-dom";
import Button from "../../Button/Button";
import { CurrentRepair, ReportContactDetails } from "../../models/Repair/Repair";
import { PremisesTypeId } from "../../models/Customer/Premises";
import Address from "../../models/Customer/Address";
import CustomerName from "../../models/Customer/CustomerName";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import {
  userTypedRepairType,
  userChosenEmergencyQuestion,
  userChosenContactDetails,
  workOrderData,
  isEditState,
  userChosenContactPhone,
  userChosenRelationDetails,
  userChosenContactName,
} from "../../ducks/redux/actions/userChoices";
import {
  getDeterminedContractorDetails
} from "../../ducks/redux/actions/getAddress";
import{SurveyTypes} from "../../models/shared/Enum";
import { setPriority } from "os";

interface RepairReasonProps extends RouteComponentProps<any> {
  getSorcode:string;
 
}

const SurveyReason: React.FC<RepairReasonProps> = (props) => {
  const dispatch = useDispatch();
  //To track radio button selection for RepairProperty--Start here---
  //-----------Ends here-----------//
  enum surveyReportingMethods {
    "Telephone" = 1,
    "Email" = 2,
    "Social media" = 3,
    "Customer Self-Serve" = 4,
    "Colleague Self-Serve" = 5,
  }
  
  enum RepairCauseIds {
    "Complaint"=3,
    "Technical Inspection"=5,
     "Disrepair"=6,
     "Right to Improve"=7,
     "Mutual Exchange"=8,
     "Leaseholder Improvement Request"=9,
     "Estate Management Survey"=10,
     "Keysafe Installation"=11,
     "Void Survey"=12
  }
 
  const [errorMessage, setErrorMessage] = useState(false);
  const contractor=["PGM","CPS"]
  
  let priorityCode='VOI';
  const [selectedreportingMethod, setSelectedreportingMethod] = useState(
    "Colleague Self-Serve"
  );
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [isDisabled, setButtonDisabled] = useState(false);
  const [chosenHL, setChosenHL] = useState("");
  const [additionalInfo, SetadditionalInfo] = useState("");
  const [surveyReasonval, SetSurveyReason] = useState("");
  const [locatioAlert, setLocatioAlert] = useState<string[]>([]);
  const [valQuestion, setValQuestion] = useState<boolean>(false);
  const [contractorName, setContractorName] = useState<any>("");
  
  let alertMstArray: any[] = [];
  const addresses = useSelector(
    (state: any) => state.addresses?.customerassociatedassets
  );

  const fetchedSurveyReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.fetchedRepairReasons
  );

  const userTypedRepairReasons = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  );
  const userChosenSurveyReason = useSelector(
    (state: any) => state.dashboardReducer.formState?.userTypedRepairReasons
  ); 
 
  const tenanciesData = useSelector(
    (state: any) => state.addresses.customertenancies
  );
  const customerDetails = useSelector(
    (state: any) => state.addresses.saveCustomerDetails
  );

  const assetId = useSelector(
    (state: any) => state?.dashboardReducer?.formState?.customerID?.assetId
  );
  const assetStatusVal=useSelector(
    (state: any) => state?.userChoicesReducer.assetDataStatus    
  )

  const userChosenEmergencyQuestionVal = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );
 
  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonPriorityOverride ?? 
       state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]?.priority
  );

  const customerID = useSelector((state: any) => state.addresses?.customerid);

  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const userChosenResumeRepairValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenResumeRepair
  );

  const loadDuplicateRepairPageValue = useSelector(
    (state: any) => state.userChoicesReducer.loadDuplicateRepairPage
  );
  const getContractorname = useSelector(
    (state: any) => state.addresses.contractorname
  );
  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystProperty
  );
  const userChosenSOR = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );

  const assetDetails = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.assetByIdDataObject
  );
  const workOrderObjects = useSelector(  
    (state: any) => state?.addresses?.workOrderRepair
  );

  
  const userMaintResp = useSelector((state: any) => state.addresses.maintresp);
  const priorityKey = ["Void Survey", "Keysafe Installation"];
  if(userChosenSurveyReason ==="Keysafe Installation"){
    priorityCode ="KSF";
 }
 if(userChosenSurveyReason === "Void Survey"){
    priorityCode ="VOI";
 }  
 if(userChosenSurveyReason && !priorityKey.includes(userChosenSurveyReason)){
    priorityCode =valQuestion ? "EME" : "NA";
 }
 

  let emergencyState = false;
  if (assetDetails.assetType === "Property" && priority === "Emergency") {
    if (userChosenEmergencyQuestionVal === "Yes, access can be provided") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  } else {
    if (priority === "Emergency" || priority === "Emergency (OOH)") {
      emergencyState = true;
    } else {
      emergencyState = false;
    }
  }

  const workOrderSurvey = new CurrentRepair();
  
  const _premisesTypeId: any =
    PremisesTypeId[assetDetails.assetType as keyof typeof PremisesTypeId];
    workOrderSurvey.repairReason = userChosenSurveyReason;
    if(surveyReasonval){
      workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
    } else {
      workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
    }
    workOrderSurvey.premisesId = assetId;
  if (assetDetails.assetType === "Divided Block") {
    workOrderSurvey.premisesTypeId = "5";
  } else {
    workOrderSurvey.premisesTypeId = _premisesTypeId;
  }
  workOrderSurvey.customerIdentifier = customerID;
   workOrderSurvey.isEmergency = emergencyState

  workOrderSurvey.premisesUniqueReference = assetDetails.uprn;
  const _address = new Address();
  _address.addressLine1 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
  _address.addressLine2 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
  _address.addressLine3 =
    customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
    workOrderSurvey.premisesAddress = _address;
    workOrderSurvey.sorCode = userChosenSOR;
    workOrderSurvey.customerName = new CustomerName({
    title: customerDetails?.person?.name?.title,
    givenName: customerDetails?.person?.name?.givenName,
    surname: customerDetails?.person?.name?.surname,
  });
  workOrderSurvey.reportingMethodId =
  surveyReportingMethods[
      selectedreportingMethod as keyof typeof surveyReportingMethods
    ];
    workOrderSurvey.reportingMethod = selectedreportingMethod;

    workOrderSurvey.notifyAll = false;
    workOrderSurvey.eTag = customerDetails?.eTag; // Need to verify
    workOrderSurvey.eligibilityOutcomes = [];
    workOrderSurvey.assetIdentifier = tenanciesData?.premisesIds;
    workOrderSurvey.repairReason= userChosenSurveyReason;
    workOrderSurvey.repairLocation="Throughout";
    workOrderSurvey.repairLocationId="15";
    workOrderSurvey.repairCause = userChosenSurveyReason; 
    workOrderSurvey.isCatalyst = isCatalyst;

   workOrderSurvey.isEmergency = valQuestion

    workOrderSurvey.additionalInformation=additionalInfo;
    workOrderSurvey.surveyReason=surveyReasonval;
    workOrderSurvey.priority=priorityCode;
    workOrderSurvey.repairType="survey";
    workOrderSurvey.repairCauseId =
    RepairCauseIds[userChosenSurveyReason as keyof typeof RepairCauseIds];
  const _worksOrderRequest = new WorksOrderRequest(workOrderSurvey);
  const woRequestParameters = _worksOrderRequest.createWorkOrderParameter(
    workOrderSurvey
  );

  const CreateWorkOrder = async (result:boolean) => {   
    try {
      dispatch(isEditState(false));
      setIsLoading(true);
      if (!chosenHL) {
        woRequestParameters.repairReason = "";
        woRequestParameters.description = "";
      }
      woRequestParameters.sorCode = userChosenSOR
      const apiResponse = await api.createWorkOrderRepair({
        ...woRequestParameters,
      });
      if (apiResponse?.location.length > 0 ) {
        const workorderLocation = apiResponse?.location.split("/");
        workOrderSurvey.id = workorderLocation[4];
        workOrderSurvey.additionalInformation=additionalInfo;
        workOrderSurvey.surveyReason=surveyReasonval;
        workOrderSurvey.priority=priorityCode;
        workOrderSurvey.repairType="survey";
       workOrderSurvey.repairReason = userChosenSurveyReason;
       workOrderSurvey.repairLocation="Throughout";
       workOrderSurvey.repairCauseId =
    RepairCauseIds[userChosenSurveyReason as keyof typeof RepairCauseIds];
       workOrderSurvey.repairLocationId="15";
       workOrderSurvey.worksOrderIdentifier = workorderLocation[4];
       workOrderSurvey.reporterContactDetails = new ReportContactDetails();
       workOrderSurvey.reporterContactDetails.contactNumber = "00000000000";
        dispatch(workOrderRepairObject(workOrderSurvey));
        const worksOrder = new WorksOrderRequest(workOrderSurvey);
        dispatch(workOrderData(worksOrder))
        if(userChosenSurveyReason === "Void Survey" && additionalInfo !==""){
          dispatch(userChosenContactDetails("00000000000@0"));
          dispatch(userChosenRelationDetails(""));
          dispatch(userChosenContactPhone(""));
          dispatch(userChosenContactName(""));
          props.history.push("/SurveyConfirmationDetails");
        }
        else if(!result)
        { 
        props.history.push("/CustomerDetails");
        }
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }

  };

  const UpdateWorkOrder = async (result:boolean) => {
    try {
      dispatch(isEditState(false));
      setIsLoading(true);
      if (workOrderObject.id.trim().length > 0) {
        const workOrderSurvey = new CurrentRepair();
        enum RepairCauseId {
          "General Wear and Tear" = 1,
          "Vandalism or Damage" = 4,
          "Complaint" = 3,
          "Technical Inspection" = 5,
          "Disrepair" = 6,
          "Right to Improve" = 7,
          "Mutual Exchange" = 8,
          "Leaseholder Improvement Request" = 9,
          "Estate Management Survey" = 10,
        }
        const _premisesTypeId: any =
          PremisesTypeId[assetDetails.assetType as keyof typeof PremisesTypeId];
            workOrderSurvey.repairReason =userChosenSurveyReason;  
            if(surveyReasonval){
          workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
        } else {
          workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
        }
        workOrderSurvey.additionalInformation = additionalInfo;
            workOrderSurvey.surveyReason=surveyReasonval;
        workOrderSurvey.repairCauseId =
          RepairCauseIds[userChosenSurveyReason as keyof typeof RepairCauseIds];
            workOrderSurvey.priority=priorityCode;
            workOrderSurvey.repairLocation="Throughout";
            workOrderSurvey.repairLocationId="15";
            workOrderSurvey.repairType="survey";
        workOrderSurvey.repairReason = userChosenSurveyReason;
            workOrderSurvey.repairLocation="Throughout";
            workOrderSurvey.repairLocationId="15";
        workOrderSurvey.premisesId = assetId;
        if (assetDetails.assetType === "Divided Block") {
          workOrderSurvey.premisesTypeId = "5";
        } else {
          workOrderSurvey.premisesTypeId = _premisesTypeId;
        }
        workOrderSurvey.customerIdentifier = customerID;
        workOrderSurvey.isEmergency = emergencyState

        workOrderSurvey.premisesUniqueReference = assetDetails.uprn;
        const _address = new Address();
        _address.addressLine1 =
          customerDetails?.addresses?.postalAddresses[0]?.addressLine1;
        _address.addressLine2 =
          customerDetails?.addresses?.postalAddresses[0]?.addressLine2;
        _address.addressLine3 =
          customerDetails?.addresses?.postalAddresses[0]?.addressLine3;
        workOrderSurvey.premisesAddress = _address;
        workOrderSurvey.sorCode = userChosenSOR;
        workOrderSurvey.customerName = new CustomerName({
          title: customerDetails?.person?.name?.title,
          givenName: customerDetails?.person?.name?.givenName,
          surname: customerDetails?.person?.name?.surname,
        });
        workOrderSurvey.reportingMethodId =
          surveyReportingMethods[
          selectedreportingMethod as keyof typeof surveyReportingMethods
          ];
        workOrderSurvey.reportingMethod = selectedreportingMethod;
        workOrderSurvey.notifyAll = false;
        workOrderSurvey.eTag = customerDetails?.eTag; // Need to verify
        workOrderSurvey.eligibilityOutcomes = [];
        workOrderSurvey.assetIdentifier = tenanciesData?.premisesIds;
        workOrderSurvey.repairReason = userChosenSurveyReason;
            workOrderSurvey.repairLocation="Throughout";
            workOrderSurvey.repairLocationId="15";
        workOrderSurvey.repairCause = userChosenSurveyReason;
        workOrderSurvey.isCatalyst = isCatalyst;
        workOrderSurvey.repairCauseId =
          RepairCauseId[
          userChosenSurveyReason as keyof typeof RepairCauseId
          ];
        const _worksOrderRequest = new WorksOrderRequest(workOrderSurvey);
        const woRequestParameters = _worksOrderRequest.createWorkOrderParameter(
          workOrderSurvey
        );
        woRequestParameters.repairReason = userChosenSurveyReason;
          if(surveyReasonval){
          workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
        } else {
          workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
        }
        const apiResponse = await api.createWorkOrderRepair({
          ...woRequestParameters,
        });

        if (apiResponse?.location.length > 0) {
          const workorderLocation = apiResponse?.location.split("/");
          workOrderSurvey.id = workorderLocation[4];
          workOrderSurvey.repairReason = userChosenSurveyReason;
            workOrderSurvey.repairLocation="Throughout";
            workOrderSurvey.repairLocationId="15";
          workOrderSurvey.worksOrderIdentifier = workorderLocation[4];
            workOrderSurvey.repairType="survey";
            if(surveyReasonval){
            workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
          } else {
            workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
          }
          dispatch(workOrderRepairObject(workOrderSurvey));
          workOrderSurvey.reporterContactDetails = new ReportContactDetails();
          workOrderSurvey.reporterContactDetails.contactNumber = "00000000000";
          const worksOrder = new WorksOrderRequest(workOrderSurvey);
          dispatch(workOrderData(worksOrder))
            if(userChosenSurveyReason === "Void Survey" && additionalInfo !==""){
            dispatch(userChosenContactDetails("00000000000@0"));
            props.history.push("/SurveyConfirmationDetails");
          }
            else if(!result)
            {
            props.history.push("/CustomerDetails");
          }
        }
      } else {
        const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
        const woRequestParameters = _worksOrderRequest.updateWorkOrderParameter(
          workOrderObject
        );
        woRequestParameters.repairReason =
          userChosenSurveyReason;
        if(surveyReasonval){
          workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
        } else {
          workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
        }
        await api.updateWorkOrderRepair(
          woRequestParameters,
          workOrderObject.id,
          "eTag"
        );

        workOrderObject.repairReason =
          userChosenSurveyReason;
        workOrderSurvey.repairType="survey";
        workOrderSurvey.repairLocation="Throughout";
        workOrderSurvey.repairLocationId="15";
        if(surveyReasonval){
          workOrderSurvey.description = userChosenSurveyReason + ". " + surveyReasonval;
        } else {
          workOrderSurvey.description = userChosenSurveyReason + ". " + additionalInfo;
        }
        workOrderObject.additionalInformation=workOrderObject[0].additionalInformation;
        dispatch(workOrderRepairObject(workOrderObject));
      }
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/GenericError");
      }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async function () {
      try {
        const premiseType =
        assetDetails.assetType === "Block" ||
        assetDetails.assetType === "Estate" ||
        assetDetails.assetType === "Divided Block" ||
        assetDetails.assetType === "Divided block" ||
        assetDetails.assetType === "Estate Area"
            ? "Communal"
            : assetDetails.assetType;
            let isCatalyst= false;
        const responsedata = await api.searchRepairReasons(
          userTypedRepairReasons,
          premiseType,
          isCatalyst
        );
        dispatch(fetchRepairReasonsSelect(responsedata));

        const extractedReasons: string[] = [];

        responsedata.map((eachData, key) => {
          const { description } = eachData;
          extractedReasons.push(description);
        });
        dispatch(fetchRepairReasons(extractedReasons));
      } catch (e) {
        setIsLoading(false);
        console.error(e);
        props.history.push("/GenericError");
      }
    })();
  }, [addresses, userTypedRepairReasons]);

  useEffect(() => {
    alertMstArray = [];
    setLocatioAlert([]);
  }, [userChosenSurveyReason]);

  //redux data to check radio button selection
  const updateSelection = (e: any) => {
    dispatch(
      updateDashboardForm({
        selected: e.target.value,
      })
    );
  };
  //ends here

  let fieldsArray: any[] = [];
  addresses?.forEach((address: any) => {
    const addressValue =
      address?.addresses?.postalAddresses[0]?.formattedAddress;

    fieldsArray.push(
      <FormControlLabel
        value={addressValue}
        control={<Radio onChange={updateSelection} />}
        label={addressValue}
      />
    );
  });


  const getMandatoryData = (data: any) => {
    setChosenHL(data==undefined ? 'No option selected':data);
  };

  const getAdditionalData = (data: any) => {
    SetadditionalInfo(data);
  };

 const getSurveyReason = (data: any) => {
    SetSurveyReason(data);
  };

  useEffect(()=>{
    alertMstArray = [];
    setLocatioAlert([]);
  },[chosenHL])

  const getDeterminedContractorCall= async()=>{
    let determinedContractor: any[] = [];
    let region = assetDetails?.region;
    const repairId = workOrderObjects?.id;
    const raisedDate = new Date();
    const repairTime = raisedDate.getHours().toString();
    const raisedFormattedDate = moment(new Date()).format("YYYY-MM-DD");
    let uprn = assetDetails.uprn;
    let maintenanceResponsibility: string = userMaintResp;
    let repaiResponsiblity:string = "survey" ;     
    const borough = assetDetails?.borough ? assetDetails?.borough?.trim() : "";

    try{
    if(userChosenSurveyReason){
        determinedContractor =
          await api.getDeterminedContractor(
            '00000000-0000-0000-0000-000000000000',
            priorityCode,
            region,
            borough,
            props.getSorcode === '' ? userChosenSOR : props.getSorcode,
            repairTime,
            uprn,
            raisedFormattedDate,
            RepairCauseIds[
            userChosenSurveyReason as keyof typeof RepairCauseIds
            ],
            maintenanceResponsibility,
            repaiResponsiblity
          );
        setContractorName(determinedContractor[0]?.name)
      }
      dispatch(getDeterminedContractorDetails(determinedContractor));
      
    }
      catch(e){

        if(userChosenSurveyReason==="Keysafe Installation"){
        setErrorMessage(true);
      }
    }
  }


  useEffect(()=>{
    if(userChosenSurveyReason != 0 ||
      userChosenSurveyReason !== "No option selected"){
        setButtonDisabled(false);
       }
    alertMstArray = [];
    setLocatioAlert([]);
    getDeterminedContractorCall();
  },[userChosenSurveyReason])

  
  useEffect(()=>{
    alertMstArray = [];
    setLocatioAlert([]);
  },[additionalInfo])

  const question = (data: any) => {
    setValQuestion(data);
  };
  
  const callPageHandler = async () => {
    setButtonDisabled(true);
    if(contractorName.length>0){
      setErrorMessage(false)
    }

    dispatch(userTypedRepairType('survey'));
  
      if((userChosenSurveyReason === "" || userChosenSurveyReason === "No option selected") || (userChosenSurveyReason==="Technical Inspection" ?chosenHL==="No option selected":'') || additionalInfo == ""){       
        alertMstArray.push(t("CPR0011"));
        setLocatioAlert(alertMstArray);  
         return ;
      }
      if(userChosenSurveyReason==="Keysafe Installation"){
      if((contractorName.length > 1) && (!contractor.includes(contractorName))){ 
        alertMstArray.push(t("KEY_VALIDATION"));
        setLocatioAlert(alertMstArray);  
         return;
      } 
      if(errorMessage){
        if(!contractor.includes(contractorName?contractorName:getContractorname)){
          alertMstArray.push(t("KEY_VALIDATION"));
          setLocatioAlert(alertMstArray);  
           return;
        }
        
      }
    }


      workOrderSurvey.description=userChosenSurveyReason;
    if(!userChosenResumeRepairValue && !loadDuplicateRepairPageValue)
    {
        workOrderObject == null ? CreateWorkOrder(false) : UpdateWorkOrder(false);
    
        }
    else if(userChosenSurveyReason === "Void Survey" && additionalInfo !==""){
      workOrderSurvey.reporterContactDetails = new ReportContactDetails();
      workOrderSurvey.reporterContactDetails.contactNumber = "00000000000";
      dispatch(userChosenContactDetails('00000000000@0'));
      props.history.push("/SurveyConfirmationDetails");
    }
    else
    {
      props.history.push("/CustomerDetails");
    }
    if(userChosenSurveyReason==="Keysafe Installation"){
      if((contractorName.length>1) && (contractor.includes(contractorName))){ 
        dispatch(userChosenEmergencyQuestion(''));
      }
    }
    if(userChosenSurveyReason===SurveyTypes.Void_Survey && assetStatusVal==SurveyTypes.VOID){
      dispatch(userChosenEmergencyQuestion(''));
  }
  };

  return (
    <>
      <div
        className="ml-sm-5 pl-sm-5 pt-2 repair-text container"
        id="repreason-main-div"
      >
        <SurveyReason2
          suggestions={fetchedSurveyReasons}
          setButtonDisabled={setButtonDisabled}
          locatioAlert={locatioAlert}
          getMandatoryData={getMandatoryData}
          question={question}
          determinedContractor={contractorName}
          getAdditionalData={getAdditionalData}
          getSurveyReason={getSurveyReason}
          sorCodeValue={ props.getSorcode}
          {...props}
        />
      </div>
    
      <div
        className="flex justify-between items-center pb-4 md:pb-8 container"
        id="repreason-main-btn"
      >
      <div><a id="exitmodal-btn1" className="text-teal exit-btn a-style cursor-pointer"></a></div>
        <Button
          id="btn_repairreason"
          type="button"
          data-testid="btncontinue"
          name="btncontinue"
          disabled={isDisabled}
          onClick={callPageHandler}
          placeholder="btncontinue"
          value="Continue"
          className="button-class"
        >
          {t("continuen_button")}
          <i className="fas fa-angle-right relativetext-white pointer-events-none arrow"></i>
        </Button>
      </div>
    </>
  );
};

export default SurveyReason;
