export const PriorityFilter= [
    {
        key: 'Select',
        value: ''
    },
    {
        key: 'Damp and Mould -14 days',
        value: 'Damp and Mould -14 days'
    },
    {
        key: 'Emergency',
        value: 'Emergency'
    },
    {
        key: 'Next available',
        value: 'Next Available',
    },
    
]