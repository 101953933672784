import * as api from "../../api";


export const getserviceCardData =  async(assetId:string, serviceType:string) => {
    try{
     return await api.getServiceCard(assetId, serviceType)
    }catch(e:any){
      return (e)
    }
  }

  